import { combineReducers } from 'redux';

import searchReducer, { initialState as searchState } from './search/reducers';
import projectsReducer, { initialState as projectsState } from './projectsList/reducers';

export const rootReducer = combineReducers({
  search: searchReducer,
  projects: projectsReducer,
});

const State = {
  search: searchState,
  projects: projectsState,
};

export type RootState = typeof State;
