import { Flex } from '@tq/ui-components';
import { Geometry } from '@tq/web-maps';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { ProjectsDto } from '../../../../api/LRPProxy';
import useDownloadProjectDocuments from '../../../../hooks/useDownloadProjectDocuments';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getLandParcelUrl, getProjectOverviewUrl } from '../../../../utils/router';
import { getUserUuid } from '../../../../utils/gaUtils';
import { useTagManager } from '../../../../components/analytics';
import {
  getMapBackEvent,
  getMapOpenPanelEvent,
  getMapZoomResetEvent,
  getMapZoomInEvent,
  getMapZoomOutEvent,
  getMapProjectDocumentsEvent,
  getMapParcelEvent,
} from '../../../../components/analytics/constants';
import { ProjectMapDetailContext, ProjectMapDetailContextResult } from '../../context/project-map-detail-context';
import ProjectMapInformationPanel from '../../map/InformationPanel';
import LandParcelInformationPanel from '../../map/land-parcel/LandParcel';
import PartyInformationPanel from '../../map/party/Party';
import { TopActions, Viewport } from '../../map/ProjectMap.styles';
import Search from '../../map/SearchResults';
import SupportingEvidenceInformationPanel from '../../map/supporting-evidence/SupportingEvidence';
import {
  AREA_TO_BE_REFERENCED_LAYER_ID,
  atbrGeometryStyles,
  landParcelGeometryStyles,
  LAND_PARCEL_LAYER_ID,
} from '../fragments';
import { useLandParcelDetailLogic } from '../hooks/useLandParcelDetailLogic';
import { useProjectMapDetail } from '../hooks/useProjectMapDetail';
import { BaseMap } from './BaseMap';
import { LandParcelDetailHeader } from './LandParcelDetailHeader';

type LandParcelDetailProps = {
  projectDetail: ProjectsDto;
};

export const LandParcelDetail: React.FunctionComponent<LandParcelDetailProps> = ({ projectDetail }) => {
  const history = useHistory();

  const landParcelDetailLogicResult = useLandParcelDetailLogic();
  const projectMapDetailResult = useProjectMapDetail();
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  const projectMapDetailContext: ProjectMapDetailContextResult = useMemo(
    () => ({ projectDetail, ...landParcelDetailLogicResult, ...projectMapDetailResult }),
    [landParcelDetailLogicResult, projectDetail, projectMapDetailResult]
  );

  const {
    isInfoPanelOpen,
    openInfoPanel,
    projectAtbrDetail,
    projectLandParcelDetail,
    projectLandParcelParams,
  } = landParcelDetailLogicResult;
  const { projectId, landParcelId, partyId, supportingEvidenceId } = projectLandParcelParams;

  // Info panel is available only when parcel is selected (i.e when any of below id are present in the url)
  const isInfoPanelAvailable = !!(landParcelId ?? partyId ?? supportingEvidenceId);

  const isMobile = useIsMobile();
  const { download, hasDocuments: hasDownloadableDocuments, isDownloading } = useDownloadProjectDocuments({
    projectId,
  });

  const resetPositionPadding = useMemo(() => {
    if (!isMobile && isInfoPanelOpen && isInfoPanelAvailable) {
      return [0, 0, 0, 453];
    }
    return [0, 0, 0, 0];
  }, [isInfoPanelOpen, isInfoPanelAvailable, isMobile]);

  const handleParcelSelect = useCallback(
    (idList: string[]) => {
      if (idList.length) {
        sendCustomEvent(getMapParcelEvent(getUserUuid(user), projectDetail, idList[0]));
        openInfoPanel();
        history.push(getLandParcelUrl(projectId, idList[0]));
      }
    },
    [history, openInfoPanel, projectDetail, projectId, sendCustomEvent, user]
  );

  const layers = useMemo(() => {
    if (!projectAtbrDetail || !projectLandParcelDetail) return [];
    return [
      <Geometry
        id={AREA_TO_BE_REFERENCED_LAYER_ID}
        label='Area to be Referenced'
        data={projectAtbrDetail}
        canToggle
        defaultVisible
        canSelect={false}
        minZoom={1}
        maxZoom={13}
        zIndex={99}
        key='atbr geometry'
        style={atbrGeometryStyles}
      />,
      <Geometry
        id={LAND_PARCEL_LAYER_ID}
        label={'Land Parcels'}
        data={projectLandParcelDetail}
        canToggle
        defaultVisible
        canSelect
        minZoom={1}
        maxZoom={13}
        zIndex={100}
        onSelect={handleParcelSelect}
        key='land parcel geometry'
        style={landParcelGeometryStyles}
      />,
    ];
  }, [handleParcelSelect, projectAtbrDetail, projectLandParcelDetail]);

  const projectName = projectDetail.name;

  const layerSwitcherElement = document.getElementById('layers-select-button');
  const mapZoomResetElement = document.getElementById('ol-controls-reset-position-target');
  const mapZoomParent = document.getElementById('ol-controls-zoom-target');
  const mapZoomInElement = document.getElementsByClassName('ol-zoom-override-in')[0];
  const mapZoomOutElement = document.getElementsByClassName('ol-zoom-override-out')[0];

  // Adds event to send GA Event when the layer switcher menu is opened
  useEffect(() => {
    const handleLayerSwitcherClick = () => {
      const isExpanded = layerSwitcherElement?.getAttribute('aria-expanded');

      if (isExpanded === 'false') {
        sendCustomEvent(getMapOpenPanelEvent(getUserUuid(user), projectDetail));
      }
    };

    layerSwitcherElement?.addEventListener('click', handleLayerSwitcherClick);

    return () => {
      layerSwitcherElement?.removeEventListener('click', handleLayerSwitcherClick);
    };
  }, [layerSwitcherElement, projectDetail, sendCustomEvent, user]);

  // Adds event to send GA Event when the Map Zoom is reset
  useEffect(() => {
    const handleMapZoomResetClick = () => {
      sendCustomEvent(getMapZoomResetEvent(getUserUuid(user), projectDetail));
    };

    mapZoomResetElement?.addEventListener('click', handleMapZoomResetClick);

    return () => {
      mapZoomResetElement?.removeEventListener('click', handleMapZoomResetClick);
    };
  }, [mapZoomResetElement, projectDetail, sendCustomEvent, user]);

  // Adds event to send GA Event when the Map Zoom In is clicked
  useEffect(() => {
    const handleMapZoomInClick = () => {
      sendCustomEvent(getMapZoomInEvent(getUserUuid(user), projectDetail));
    };

    /**
     * Oddly, we can't attach this eventListener to the mapZoomInElement as it seems to unload when this useEffect is running.
     * Additionally mapZoomParent doesn't count as 'updating' by useEffect's standards at any point during the page loading,
     * so we still need the mapZoomInElement reference to trigger the useEffect in the first place.
     */
    mapZoomParent?.querySelector('.ol-zoom-override-in')?.addEventListener('click', handleMapZoomInClick);

    return () => {
      mapZoomParent?.querySelector('.ol-zoom-override-in')?.removeEventListener('click', handleMapZoomInClick);
    };
  }, [mapZoomInElement, projectDetail, sendCustomEvent, mapZoomParent, user]);

  // Adds event to send GA Event when the Map Zoom Out is clicked
  useEffect(() => {
    const handleMapZoomOutClick = () => {
      sendCustomEvent(getMapZoomOutEvent(getUserUuid(user), projectDetail));
    };

    mapZoomParent?.querySelector('.ol-zoom-override-out')?.addEventListener('click', handleMapZoomOutClick);

    return () => {
      mapZoomParent?.querySelector('.ol-zoom-override-out')?.removeEventListener('click', handleMapZoomOutClick);
    };
  }, [mapZoomOutElement, projectDetail, sendCustomEvent, mapZoomParent, user]);

  return (
    <ProjectMapDetailContext.Provider value={projectMapDetailContext}>
      <Helmet>
        <title>{`TerraQuest Land Referencing - ${projectName} Map`}</title>
      </Helmet>
      {isInfoPanelAvailable && (
        <ProjectMapInformationPanel>
          {landParcelId && (
            <div data-testid='land-parcel-detail'>
              <LandParcelInformationPanel />
            </div>
          )}
          {partyId && (
            <div data-testid='party-detail'>
              <PartyInformationPanel />
            </div>
          )}
          {supportingEvidenceId && (
            <div data-testid='supporting-evidence-detail'>
              <SupportingEvidenceInformationPanel />
            </div>
          )}
        </ProjectMapInformationPanel>
      )}
      <Viewport>
        <TopActions>
          {!isInfoPanelAvailable && <Search projectDetail={projectDetail} />}
          <LandParcelDetailHeader
            projectName={projectName}
            hasDownloadableDocuments={hasDownloadableDocuments}
            hasInfoPanel={isInfoPanelAvailable}
            isDownloading={isDownloading}
            onDownload={() => {
              sendCustomEvent(getMapProjectDocumentsEvent(getUserUuid(user), projectDetail));
              return download();
            }}
            onNavigate={() => {
              sendCustomEvent(getMapBackEvent(getUserUuid(user), projectDetail));
              history.push(getProjectOverviewUrl(projectId));
            }}
          />
        </TopActions>

        <Flex mt='auto'>
          <BaseMap layers={layers} resetPositionPadding={resetPositionPadding} />
        </Flex>
      </Viewport>
    </ProjectMapDetailContext.Provider>
  );
};
