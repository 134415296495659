import { IPartyDto } from '../api/LRPProxy';

export const partyNameFormat = (state: IPartyDto) => {
  const { isOrganisation, topo, principalName, title } = state;
  let name;

  if (isOrganisation && topo !== '' && topo !== ' ' && typeof topo !== 'undefined') {
    name = topo + ' ';
  } else {
    if (title !== '' && title !== ' ' && typeof title !== 'undefined') {
      name = title + ' ';
    } else {
      name = '';
    }
  }

  return name + principalName;
};
