// Screen-reader-friendly alert messages.
import React, { FC, useState } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';
import useTimeout from '../../hooks/useTimeout';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { isFunction } from '../../utils/assertionUtils';
import { ToastOptions } from './Toast.types';

export type ToastProps = Pick<ToastOptions, 'onRequestRemove' | 'id' | 'message' | 'requestClose' | 'duration'> & {
  transitionStatus: TransitionStatus;
};

const Li = styled.li<{ $transitionStatus: TransitionStatus }>`
  transition: opacity 0.15s ease-in-out;
  pointer-events: auto;
  max-width: 94.4rem;
  min-width: 30rem;
  margin: 0 auto;
  opacity: ${({ $transitionStatus }) => ($transitionStatus === 'entered' ? '1' : '0')};
`;

/**
 * Receives toast options and renders with interactions
 */
const Toast: FC<ToastProps> = (props) => {
  const { id, message, transitionStatus, onRequestRemove, requestClose = false, duration = 5000 } = props;
  const [delay, setDelay] = useState<number | null>(duration);

  const onMouseEnter = () => setDelay(null);
  const onMouseLeave = () => setDelay(duration);
  const close = () => onRequestRemove();

  useUpdateEffect(() => {
    setDelay(duration);
  }, [duration]);

  useUpdateEffect(() => {
    if (requestClose) {
      onRequestRemove();
    }
  }, [requestClose, onRequestRemove]);

  useTimeout(close, delay);

  return (
    <Li $transitionStatus={transitionStatus} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {isFunction(message) ? message({ id, onClose: close }) : message}
    </Li>
  );
};

export default Toast;
