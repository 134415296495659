import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavContext } from '../providers/NavProvider';
import { RootState } from '../store';
import { projectsState } from '../store/projectsList/types';
import RequestStatus from '../store/RequestStatus';
import { Dropdown } from '../components/navigation/Dropdown';
import LoadingSpinner from '../components/loading-spinner/LoadingSpinner';
import { clearSearch as emptySearch } from '../store/search/actions';
import { setSearchTerm } from '../store/search/actions';

export const DropdownContainer = () => {
  const { getProjectsStatus, projects } = useSelector<RootState, projectsState>((state) => state.projects);
  const { isOpen } = useContext(NavContext);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(emptySearch());
    dispatch(setSearchTerm(''));
  };

  if (getProjectsStatus === RequestStatus.Success && projects) {
    return <Dropdown projects={projects} onClick={onClick} />;
  } else if (getProjectsStatus === RequestStatus.Requested && isOpen) {
    return <LoadingSpinner />;
  }

  return <> </>;
};
