import React from 'react';
import Box from '../../../components/ui/Box';
import Heading from '../../../components/ui/Heading';
import Text from '../../../components/ui/Text';
import { HelpContentLayout } from '../Help.styles';

const glossaryData = [
  {
    head: 'Area to be referenced',
    description: 'Area of land, covered by the land referencing data provided.',
  },
  {
    head: 'Interest',
    description:
      'A relationship with a Land Parcel. The relationship includes, but is not limited to, freehold, leasehold, occupational interest, tenancies, mortgages, rights, and restrictions. ',
  },
  {
    head: 'Land Interest Questionnaire (LIQ)',
    description:
      'A questionnaire sent to landowners on which, they record details of Parties who have an Interest in the Land Parcel. ',
  },
  {
    head: 'Land Parcel',
    description: 'A unit of land that is defined by the ownership interests.',
  },
  {
    head: 'OS MasterMap',
    description: (
      <>
        OS MasterMap, otherwise referred to as “OS MasterMap Topography Layer<sup>®</sup>” is a mapping service provided
        by the Ordnance Survey and is the most detailed view of Great Britain’s landscape.
      </>
    ),
  },
  {
    head: 'OS Zoomstack',
    description:
      'OS Open Zoomstack is a service provided by Ordnance Survey which provides a map of Great Britain at numerous scales and enabling the user to move seamlessly between them.',
  },
  {
    head: 'Parties',
    description: 'A person, persons, or organisation, having an interest in a Land Parcel.',
  },
  {
    head: 'Supporting Evidence',
    description:
      'The documentation that is evidence that supports the inclusion of the Party as an Interest, can include, but is not limited to, Title Documents, Land Interest Questionnaire’s and Site Visit Forms.',
  },
  {
    head: 'Title Documents',
    description:
      'The documents (plans, covenants etc) obtained as official copies from HM Land Registry in respect to the Land Parcel.',
  },
];

const GlossaryRoute = () => {
  return (
    <HelpContentLayout>
      <HelpContentLayout.Header>
        <Heading as='h1' variant='h1'>
          Glossary of terms
        </Heading>
      </HelpContentLayout.Header>

      <Box pl='1.6rem' pr={2} pb={9}>
        {glossaryData.map((term, i) => (
          <Box key={term.head} mt={i !== 0 ? 3 : undefined}>
            <Heading as='h2' variant='body' color='text.heading' fontWeight='700'>
              {term.head}
            </Heading>
            <Text mt={1} ml='4'>
              {term.description}
            </Text>
          </Box>
        ))}
      </Box>
    </HelpContentLayout>
  );
};

export default GlossaryRoute;
