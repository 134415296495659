import React, { useMemo, useState } from 'react';

import { BsThreeDots } from 'react-icons/bs';
import { Card } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Project } from '../../routes/ProjectsList';
import { getUserUuid } from '../../utils/gaUtils';
import { useTagManager } from '../../components/analytics';
import { getMyProDownloadDocsEvent, getMyProViewMapEvent } from '../../components/analytics/constants';
import { dateFormatter } from '../../utils/DateFormatterUtils';
import { getProjectMapUrl, getProjectOverviewUrl } from '../../utils/router';
import mapBackground from '../../assets/mapBackground.jpg';
import styles from './ProjectCard.module.scss';
import useDownloadProjectDocuments from '../../hooks/useDownloadProjectDocuments';
import { useGetProjectThumbnailBlob } from '../../hooks/queries/useGetProjectThumbnailBlob';

type Props = {
  project: Project;
  onClick: () => void;
};

const ProjectCard: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();
  const { id: projectId, status, name, lastRefreshedDate, client: clientInfo } = props.project;
  const { download: downloadProjectDocuments, isDownloading, hasDocuments } = useDownloadProjectDocuments(
    {
      projectId,
    },
    {
      onSave: () => {
        setOpen(false);
      },
    }
  );

  const { data: projectThumbnailBlob } = useGetProjectThumbnailBlob({
    projectId,
  });

  const projectThumbnail = useMemo(() => {
    if (!projectThumbnailBlob) {
      return <img src={mapBackground} alt={`Project ${name} thumbnail`} className={styles.mapImage} />;
    }

    return (
      <img
        src={URL.createObjectURL(projectThumbnailBlob.result.data)}
        alt={`Project ${name} thumbnail`}
        className={styles.mapImage}
      />
    );
  }, [projectThumbnailBlob, name]);

  const handleToggle = (
    isOpen: boolean,
    ev: React.SyntheticEvent<Dropdown<'div'>, Event>,
    meta: {
      source: 'select' | 'click' | 'rootClose' | 'keydown';
    }
  ) => {
    setOpen(isOpen);
  };

  return (
    <div className={`position-relative h-100`}>
      <div className={styles.projectDropdown}>
        <Dropdown data-testid='project-card-dropdown-toggle' drop='down' show={open} onToggle={handleToggle}>
          <Dropdown.Toggle
            variant='success'
            id='dropdown-basic'
            className={styles.dropdownToggle}
            as='div'
            data-testid='project-card-dropdown-clickable'
          >
            <BsThreeDots />
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight={true} className={styles.dropdownMenu}>
            <Dropdown.Item
              disabled={isDownloading || !hasDocuments}
              title={!hasDocuments ? 'No documents available' : ''}
              className={styles.dropdownItem}
              onClick={() => {
                sendCustomEvent(getMyProDownloadDocsEvent(getUserUuid(user), name));
                downloadProjectDocuments();
              }}
              data-testid='download-docs-button'
            >
              {isDownloading && hasDocuments ? 'Downloading Documents...' : 'Download Project Documents'}
            </Dropdown.Item>
            <Dropdown.Item
              href={getProjectMapUrl(projectId)}
              className={styles.dropdownItem}
              onClick={() => sendCustomEvent(getMyProViewMapEvent(getUserUuid(user), name))}
              data-testid='project-map-button'
            >
              View Project Map
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Link to={getProjectOverviewUrl(projectId)} className={styles.projectLink} onClick={props.onClick}>
        <Card className={`${styles.projectCard} h-100`}>
          <Card.Body className={`${styles.projectBody} h-100`}>
            <div className={`d-flex justify-content-between mb-4`}>
              <div className={`d-flex align-items-center`}>
                <div
                  className={`${styles.ellipse} ${
                    status === 'Active' ? styles.active : status === 'Inactive' ? styles.inActive : styles.onHold
                  } mr-3`}
                ></div>
                {status}
              </div>
            </div>
            <h1 className={`mb-3 font-weight-bold ${styles.projectTitle}`} title={name}>
              {name}
            </h1>
            <p className={styles.projectClient}>{clientInfo?.name}</p>
            <div className={`mb-4 ${styles.map}`}>{projectThumbnail}</div>
            <div className={`d-flex justify-content-between`}>
              <p className={styles.lastUpdated}>Last updated: {dateFormatter(lastRefreshedDate)}</p>
            </div>
          </Card.Body>
        </Card>
      </Link>
    </div>
  );
};

export default ProjectCard;
