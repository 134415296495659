import {
  Card,
  CardImage,
  CardImageContainer,
  CardImageOverlay,
  CardImageOverlayIcon,
  HelpContentLayout,
} from './Help.styles';

import { AiOutlineMail } from 'react-icons/ai';
import Box from '../../components/ui/Box';
import { CopyTextToClipboard } from '../../components/CopyTextToClipboard';
import FAQCardIcon from '../../assets/help/faq-card-icon.svg';
import FAQCardImage from '../../assets/help/faq-card-image.jpg';
import Flex from '../../components/ui/Flex';
import GlossaryCardIcon from '../../assets/help/glossary-card-icon.svg';
import GlossaryCardImage from '../../assets/help/glossary-card-image.jpg';
import Heading from '../../components/ui/Heading';
import { Link } from 'react-router-dom';
import React from 'react';
import Text from '../../components/ui/Text';
import Tiles from '../../components/ui/Tiles';
import { Helmet } from 'react-helmet';

const HelpRoute = () => {
  return (
    <>
      <Helmet>
        <title>TerraQuest Land Referencing Help</title>
      </Helmet>
      <HelpContentLayout>
        <HelpContentLayout.Header>
          <Heading as='h1' variant='h1'>
            Welcome to the Help Centre
          </Heading>
        </HelpContentLayout.Header>
        <Box px='1.6rem' pb={9}>
          <Text mb={3}>
            The Land Referencing Portal provided by TerraQuest is a web-GIS tool, empowering users to analyse and
            interact with geospatial data and get actionable insights.
          </Text>
          <Text mb={3}>
            The portal is a powerful platform that can support multiple overlapping data sets and can be customised per
            project according to your requirements.
          </Text>
          <Text mb={3}>
            If you run into any issues while using the tool or wish to contact TerraQuest please use the contact details
            below.
          </Text>

          <Box as='hr' sx={{ borderColor: '#e5eef5' }} />

          <Box py={4}>
            <Flex>
              <Flex
                size={['5rem', '6.4rem']}
                bg='#0c2d57'
                sx={{
                  flex: ['0 0 5rem', '0 0 6.4rem'],
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '1.2rem',
                }}
              >
                <AiOutlineMail color='#fff' size='32px' />
              </Flex>
              <Box ml={4}>
                <Heading as='h2' mb={1} fontSize='1.7rem' color='#06203d' fontWeight='600'>
                  Contact the team?
                </Heading>
                <Text>
                  The team are available to answer support queries from 9:00 to 17:00 on weekdays (excluding public
                  holidays).
                  <br />
                  <br />
                  Please email{' '}
                  <CopyTextToClipboard title='Email service desk link'>
                    support@landreferencingportal.co.uk
                  </CopyTextToClipboard>
                  .
                </Text>
              </Box>
            </Flex>
          </Box>

          <Box as='hr' sx={{ borderColor: '#e5eef5' }} />

          <Text mt={5}>
            You can use these contact details to enquire about getting access to the product, adding your colleagues to
            the project, adding more projects to your view and any customisations or integrations you would like to
            explore with TerraQuest.
          </Text>

          <Tiles mt={7} columns={[1, 2]}>
            <Card as={Link} to='/help/faq' title='Navigate to Frequently asked questions'>
              <Heading as='h3' variant='h3' mb={2}>
                FAQ
              </Heading>
              <Text mb={3}>Frequent questions or concerns</Text>
              <CardImageContainer>
                <CardImage src={FAQCardImage} alt='Frequently asked questions' sx={{ borderRadius: '.4rem' }} />
                <CardImageOverlay />
                <CardImageOverlayIcon src={FAQCardIcon} role='presentation' />
              </CardImageContainer>
            </Card>
            <Card as={Link} to='/help/glossary' title='Navigate to Glossary of terms'>
              <Heading as='h3' variant='h3' mb={2}>
                Glossary of terms
              </Heading>
              <Text mb={3}>Knowledge base of definitions</Text>
              <CardImageContainer>
                <CardImage src={GlossaryCardImage} alt='Glossary of terms' sx={{ borderRadius: '.4rem' }} />
                <CardImageOverlay />
                <CardImageOverlayIcon src={GlossaryCardIcon} role='presentation' />
              </CardImageContainer>
            </Card>
          </Tiles>
        </Box>
      </HelpContentLayout>
    </>
  );
};

export default HelpRoute;
