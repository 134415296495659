import { useMediaQuery } from './useMediaQuery';

/**
 * React hook that determines whether the current screen size evaluates to our mobile breakpoint
 */
const useIsMobile = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return isMobile;
};

export default useIsMobile;
