import { SendCustomEventProps } from '../TagManagerProvider';

const GA_LOGIN_PAGE = {
  gaCategory: 'Login Page',
  gaLabel: 'Login Page',
};

export const GA_LOGIN_PAGE_LRP_USER_LOGIN: SendCustomEventProps = {
  ...GA_LOGIN_PAGE,
  gaAction: 'LRP - User Login',
};

export const GA_LOGIN_PAGE_LRP_USER_LOGOUT: SendCustomEventProps = {
  ...GA_LOGIN_PAGE,
  gaAction: 'LRP - User Logout',
};
