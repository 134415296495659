import React from 'react';
import Footer from './footer/Footer';
import Header from './header/Header';
import { LayoutContainer, Main } from './Layout.styles';
import Navigation from './navigation/Navigation';
import Routes from '../routes';
import ToastProvider from './toast/ToastProvider';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

const Layout: React.FC = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <LayoutContainer>
          <Header />
          <Navigation />
          <Main>
            <ToastProvider>
              <Routes />
            </ToastProvider>
          </Main>
          <Footer />
        </LayoutContainer>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Layout;
