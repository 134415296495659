export const isValidGuid = (stringToTest?: string): boolean => {
  if (stringToTest) {
    if (stringToTest[0] === '{') {
      stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
  } else {
    return false;
  }
};
