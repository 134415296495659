import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { client as api } from '../../api';
import { ProjectsDto, SwaggerResponse } from '../../api/LRPProxy';
import { UseToastOptions } from '../../components/toast/Toast.types';
import useToast from '../../components/toast/useToast';
import {
  getQueryError404ToastOptions,
  getFallbackQueryErrorToastOptions,
  getStatusCodeFromQueryError,
  getQueryError403ToastOptions,
} from '../../utils/error';
import { isValidGuid } from '../../utils/validGuid';
import { QueryError } from './types';

type TResult = SwaggerResponse<ProjectsDto>;
type Variables = { projectId: string };
type Options = UseQueryOptions<TResult, QueryError>;

export const getProject404ToastOptions = (overrides?: UseToastOptions) =>
  getQueryError404ToastOptions({
    title: `Project not found.`,
    description: `The project couldn't be found. Please contact our help desk.`,
    ...overrides,
  });

export const useGetProjectQueryOptions = () => {
  const toast = useToast();
  const history = useHistory();
  const { instance } = useMsal();

  return useCallback(
    ({ projectId }: Variables, options: Options = {}): Options => ({
      queryKey: ['project', { projectId }],
      queryFn: () =>
        api.getProject(projectId).catch((e) => {
          const fireFallbackToast = () => toast(getFallbackQueryErrorToastOptions());

          const statusCode = getStatusCodeFromQueryError(e);

          switch (statusCode) {
            case 401:
              instance.logout();
              break;

            case 403:
              toast(
                getQueryError403ToastOptions({
                  description:
                    "You don't have access to this project. Please contact our help desk if you feel you should.",
                })
              );
              history.replace('/');
              break;

            case 404:
              toast(getProject404ToastOptions());
              history.replace('/');
              break;

            default:
              fireFallbackToast();
              history.replace('/');
          }

          throw e;
        }),
      onError: () => {},
      retry: false,
      enabled: isValidGuid(projectId),
      ...options,
    }),
    [toast, history, instance]
  );
};

export const useGetProject = (variables: Variables, options?: Options) => {
  const getProjectQueryOptions = useGetProjectQueryOptions();
  return useQuery<TResult, QueryError>(getProjectQueryOptions(variables, options));
};
