import React from 'react';
import styles from './CardInfo.module.scss';

type CardInfo = {
  textColor?: string;
};

const CardBtn: React.FC<CardInfo> = (props) => {
  return <div className={styles.cardInfo}>{props.children}</div>;
};

export default CardBtn;
