export const dateFormatter1 = (date: Date | undefined) => {
  if (date) {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }
};
export const dateFormatter = (date: Date | undefined) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  if (date) {
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const monthName = months[monthIndex];
    const day = date.getDate();
    const formatted = `${day} ${monthName} ${year}`;
    return formatted;
  }
};

export const daysBetween = (from?: Date, to?: number) => {
  if (!from || !to) return undefined;

  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((+from - +to) / oneDay));
};

export const formatNumberOfDays = (numberOfDays?: number) => {
  if (!numberOfDays) return undefined;

  let f;

  const plural = (str: string, amount: number) => (amount > 1 ? `${amount} ${str}s` : `${amount} ${str}`);

  if (numberOfDays / 365 > 1) {
    if (numberOfDays % 365 !== 0) {
      const split = String(numberOfDays / 365).split('.');
      const numOfYears = Number(split[0]);
      const remainder = Number(`.${Number(split[1])}`);
      const numOfDays = Math.floor(remainder * 365.25);

      f = `${plural('year', numOfYears)} ${plural('day', numOfDays)}`;
    } else {
      const numOfYears = numberOfDays / 365;
      f = plural('year', numOfYears);
    }
  } else f = plural('day', numberOfDays);

  return f;
};
