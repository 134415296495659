import { UseToastOptions } from '../components/toast/Toast.types';
import { QueryError } from '../hooks/queries/types';

/**
 * Checks for status code against all possible query error types and returns it
 * @param error the query error to find the status code of
 */
export const getStatusCodeFromQueryError = (error: QueryError): number | undefined => {
  if ('status' in error) return error.status;
  if ('statusCode' in error) return error.statusCode;
  return undefined;
};

/**
 * Returns an object with toast options for an unrecognised query error
 * @param overrides toast options that, when supplied, will override the defaults
 */
export const getFallbackQueryErrorToastOptions = (overrides?: UseToastOptions): UseToastOptions => ({
  id: 'error-fallback-toast',
  title: 'Technical problem.',
  description: 'A technical problem has occurred. Please refresh, try again or contact our help desk.',
  ...overrides,
});

/**
 * Returns an object with toast options for a 401 error
 * @param overrides toast options that, when supplied, will override the defaults
 */
export const getQueryError401ToastOptions = (overrides?: UseToastOptions): UseToastOptions => ({
  title: "You don't currently have access.",
  description: "You don't have access to this content. Please contact our help desk if you feel you should.",
  ...overrides,
});

/**
 * Returns an object with toast options for a 403 error
 * @param overrides toast options that, when supplied, will override the defaults
 */

export const getQueryError403ToastOptions = (overrides?: UseToastOptions): UseToastOptions => ({
  title: "You don't currently have access.",
  description: "You don't have access to this content. Please contact our help desk if you feel you should.",
  ...overrides,
});

/**
 * Returns an object with toast options for a 404 error
 * @param overrides toast options that, when supplied, will override the defaults
 */
export const getQueryError404ToastOptions = (overrides?: UseToastOptions): UseToastOptions => ({
  title: 'Content not found.',
  description: "The content couldn't be found. Please check the URL is valid or contact our help desk.",
  ...overrides,
});
