import { useLayoutEffect, useMemo, useState } from 'react';

/**
 * React hook that tracks a media query
 *
 * @param query the media query to match
 */
export function useMediaQuery(query: string | string[]): boolean[] {
  const queries = useMemo(() => (Array.isArray(query) ? query : [query]), [query]);
  const isSupported = 'matchMedia' in window;

  const [matches, setMatches] = useState(
    queries.map((query) => (isSupported ? !!window.matchMedia(query).matches : false))
  );

  useLayoutEffect(() => {
    if (!isSupported) return undefined;

    const mediaQueryList = queries.map((query) => window.matchMedia(query));

    const listenerList = mediaQueryList.map((mediaQuery, index) => {
      const listener = () =>
        setMatches((prev) => prev.map((prevValue, idx) => (index === idx ? !!mediaQuery.matches : prevValue)));

      mediaQuery.addListener(listener);

      return listener;
    });

    return () => {
      mediaQueryList.forEach((mediaQuery, index) => {
        mediaQuery.removeListener(listenerList[index]);
      });
    };
  }, [query, isSupported, queries]);

  return matches;
}
