import React, { useContext } from 'react';
import { ProjectsDto } from '../../../api/LRPProxy';
import { UseLandParcelDetailLogicResult } from '../land-parcel-detail/hooks/useLandParcelDetailLogic';
import { UseProjectMapDetailResult } from '../land-parcel-detail/hooks/useProjectMapDetail';

export type ProjectMapDetailContextResult = UseLandParcelDetailLogicResult &
  UseProjectMapDetailResult & { projectDetail: ProjectsDto };
export const ProjectMapDetailContext = React.createContext<ProjectMapDetailContextResult | undefined>(undefined);

export function useProjectMapDetailContext(): ProjectMapDetailContextResult {
  const context = useContext(ProjectMapDetailContext);

  if (!context) throw new Error('useLandParcelDetailContext must be used within LandParcelDetailContext!');

  return context;
}
