import { useMemo } from 'react';
import mapBackground from '../assets/mapBackground.jpg';
import { useGetProjectThumbnailBlob } from './queries/useGetProjectThumbnailBlob';

type Props = { projectId: string };

export const useProjectThumbnailSrc = ({ projectId }: Props): string => {
  const { data: blob } = useGetProjectThumbnailBlob({ projectId });

  return useMemo(() => (blob ? URL.createObjectURL(blob.result.data) : mapBackground), [blob]);
};
