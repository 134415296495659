import { Flex, Spinner } from '@tq/ui-components';
import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { HiDownload } from 'react-icons/hi';
import { DownloadButton, Signpost } from '../../map/ProjectMap.styles';

type LandParcelDetailHeaderProps = {
  projectName: string | undefined;
  hasInfoPanel: boolean;
  hasDownloadableDocuments: boolean;
  isDownloading: boolean;
  onDownload: () => void;
  onNavigate: () => void;
};

export const LandParcelDetailHeader: React.FunctionComponent<LandParcelDetailHeaderProps> = (props) => {
  const { hasDownloadableDocuments, isDownloading, hasInfoPanel, onDownload, onNavigate, projectName } = props;
  return (
    <Flex flex='1'>
      <Signpost
        $currentRouteHasInformationPanel={hasInfoPanel}
        aria-label='Go to My Projects'
        title='Go to project overview'
        onClick={onNavigate}
      >
        <FiChevronLeft size='24px' />
        <span>
          <b>{projectName}</b> / Map
        </span>
      </Signpost>
      <DownloadButton
        $currentRouteHasInformationPanel={hasInfoPanel}
        title={
          hasDownloadableDocuments
            ? 'Download the latest draft documents and project deliverables prepared by the team'
            : 'No documents available'
        }
        onClick={onDownload}
        aria-label='Download project documents'
        disabled={!hasDownloadableDocuments || isDownloading}
      >
        {!isDownloading ? (
          <HiDownload data-testid='download-icon' />
        ) : (
          <Spinner data-testid='loading-icon' animation='border' role='status' variant='primary' />
        )}
        <span>Project documents</span>
      </DownloadButton>
    </Flex>
  );
};
