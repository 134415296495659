import { Box as Div, Divider, VStack } from '@tq/ui-components';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React, { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import { SupportingEvidenceListDto } from '../../api/LRPProxy';
import Box from '../../components/ui/Box';
import Flex from '../../components/ui/Flex';
import Heading from '../../components/ui/Heading';
import Text from '../../components/ui/Text';
import { chartColors, chartOptions } from '../../utils/charts';
import { Card, CardBody, CardHeader } from './ProjectOverview.styles';

type Props = {
  supportingEvidence: SupportingEvidenceListDto[] | undefined;
  count: number | undefined;
};
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SupportingEvidenceCard: React.FC<Props> = ({ supportingEvidence, count }: Props) => {
  const colors = useMemo(() => {
    return chartColors;
  }, []);

  const sortedSupportingEvidence = supportingEvidence?.sort((a, b) => {
    return b.count! - a.count!;
  });

  const data = useMemo(() => {
    return {
      labels:
        sortedSupportingEvidence &&
        sortedSupportingEvidence.map((se) => {
          return `${se.name} ${se.direction ? se.direction : ''}`;
        }),
      datasets: [
        {
          data: sortedSupportingEvidence && sortedSupportingEvidence.map((se) => se.count),
          backgroundColor: colors,
        },
      ],
    };
  }, [sortedSupportingEvidence, colors]);

  return (
    <Card sx={{ bg: 'background.main', gridColumn: ['span 6', 'span 2'], padding: '0 !important' }} padding={0}>
      <CardHeader>
        <Flex justifyContent='space-between'>
          <Heading color='white' fontWeight='600'>
            Supporting Evidences
          </Heading>
          <Heading color='white'>{count}</Heading>
        </Flex>
      </CardHeader>
      <CardBody height='100%'>
        {supportingEvidence && supportingEvidence.length > 0 ? (
          <>
            <Box px='1.6rem' width='80%' marginX='auto' paddingTop='1.6rem'>
              <Heading as='h3' color='#454545' textAlign='center' fontSize='1.3rem' fontWeight='600'>
                Total document by type
              </Heading>
              <Pie options={chartOptions} data={data} aria-label='Supporting Evidence Chart' role='img' />
            </Box>
            <Box my={4}>
              <Divider sx={{ border: '1px solid #d8e5ee' }} />

              <VStack
                spacing={8}
                width='100%'
                alignItems='stretch'
                divider={<Divider sx={{ border: '1px solid #d8e5ee' }} />}
              >
                {sortedSupportingEvidence &&
                  sortedSupportingEvidence.map((se, i) => (
                    <Flex justifyContent='space-between' px={4} key={se.name}>
                      <Flex alignItems='center'>
                        <Div borderRadius='50%' backgroundColor={colors[i]} width={8} height={8} mr={14}></Div>
                        <Text
                          fontSize='1.3rem'
                          color='#454545'
                          fontWeight='light'
                          width={['unset', '18rem']}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'noWrap',
                          }}
                          title={`${se.name} ${se.direction ? se.direction : ''}`}
                        >
                          {se.name} {se.direction ? se.direction : ''}
                        </Text>
                      </Flex>
                      <Text fontSize='1.3rem' color='#454545' fontWeight='600'>
                        {se.count}
                      </Text>
                    </Flex>
                  ))}
              </VStack>
            </Box>
          </>
        ) : (
          <Flex height='100%' justifyContent='center' alignItems='center'>
            <Heading>No Data Found</Heading>
          </Flex>
        )}
      </CardBody>
    </Card>
  );
};

export default SupportingEvidenceCard;
