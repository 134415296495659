import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useMsal } from '@azure/msal-react';
import { client as api } from '../../api';
import { LandParcelsDto, SwaggerResponse } from '../../api/LRPProxy';
import useToast from '../../components/toast/useToast';
import {
  getQueryError404ToastOptions,
  getFallbackQueryErrorToastOptions,
  getStatusCodeFromQueryError,
} from '../../utils/error';
import { isValidGuid } from '../../utils/validGuid';
import useRedirectToProjectMap from '../useRedirectToProjectMap';
import { QueryError } from './types';

type TResult = SwaggerResponse<LandParcelsDto>;
type Variables = { id: string; projectId: string };
type Options = UseQueryOptions<TResult, QueryError>;

export const useGetLandParcelQueryOptions = () => {
  const redirectToProjectMap = useRedirectToProjectMap();
  const toast = useToast();
  const { instance } = useMsal();

  return useCallback(
    ({ id, projectId }: Variables, options: Options = {}) => ({
      queryKey: ['landParcel', { id, projectId }],
      queryFn: () =>
        api.getLandParcel(id, projectId).catch((e: QueryError) => {
          const fireFallbackToast = () => toast(getFallbackQueryErrorToastOptions());

          const statusCode = getStatusCodeFromQueryError(e);

          switch (statusCode) {
            case 401:
              instance.logout();
              break;

            case 404:
              toast(getQueryError404ToastOptions());
              break;

            default:
              fireFallbackToast();
          }

          throw e;
        }),
      onError: () => {
        redirectToProjectMap();
      },
      getFetchMore: (lastGroup: TResult) => {
        const p = JSON.parse(lastGroup.headers['x-pagination']);
        return p.currentPage < p.totalPages;
      },
      retry: false,
      enabled: isValidGuid(id) && isValidGuid(projectId),
      ...options,
    }),
    [toast, redirectToProjectMap, instance]
  );
};

export const useGetLandParcel = (variables: Variables, options?: Options) => {
  const getLandParcelQueryOptions = useGetLandParcelQueryOptions();
  return useQuery<TResult, QueryError>(getLandParcelQueryOptions(variables, options));
};
