import React, { RefObject, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { useMsal } from '@azure/msal-react';
import { ProjectDto } from '../../api/LRPProxy';
import { getUserUuid } from '../../utils/gaUtils';
import { useTagManager } from '../../components/analytics';
import { getMapPanelPartyInfoEvent, getMapPanelParcelInfoEvent } from '../../components/analytics/constants';
import styles from './PopoverModal.module.scss';

type Props = {
  title: string;
  children?: React.ReactNode;
  partyId?: string;
  parcelId?: string;
  project?: ProjectDto;
  containerRef?: RefObject<HTMLDivElement> | null;
};

const PopoverComponent = (props: Props) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [entered, setEntered] = useState(false);
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  const handleClick = (event: any) => {
    const label = event.currentTarget.getAttribute('aria-label');

    if (!show) {
      label.includes('Party')
        ? sendCustomEvent(getMapPanelPartyInfoEvent(getUserUuid(user), props.partyId, props.project))
        : sendCustomEvent(getMapPanelParcelInfoEvent(getUserUuid(user), props.parcelId, props.project));
    }

    setShow((state) => !state);
    setTarget(event.currentTarget);
  };

  return (
    <>
      <button aria-label={props.title} title={props.title} onClick={handleClick} className={styles.infoButton}>
        Info
      </button>

      <Overlay
        show={show}
        target={target}
        placement='right'
        containerPadding={50}
        container={props.containerRef?.current}
        onEntered={() => setEntered(true)}
        rootClose
        onHide={() => {
          if (entered) {
            setShow(false);
            setEntered(false);
          }
        }}
      >
        <Popover id='popover-contained-info' className={styles.infoPanelContainer}>
          <Popover.Title className={styles.infoPanelHeader}>
            {props.title}
            <button onClick={handleClick} aria-label='close info panel' className={styles.closeButton}>
              <IoMdClose fontSize='2rem' />
            </button>
          </Popover.Title>
          <Popover.Content bsPrefix='popover-body' tabIndex={0} className={styles.infoPanelBody}>
            {props.children}
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

type EntryType = {
  label?: string;
  text?: string | number;
  children?: React.ReactNode;
  typeTitle?: string;
};

const PopoverEntry = (props: EntryType) => {
  return (
    <div className={styles.panelEntry}>
      <h2>{props.typeTitle}:</h2>
      <div className={styles.entryIndentation}>{props.children}</div>
    </div>
  );
};

PopoverComponent.Entry = PopoverEntry;
export default PopoverComponent;
