import {
  projectsState,
  ProjectsActionTypes,
  GET_PROJECTS_REQUESTED,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILED,
} from './types';
import RequestStatus from '../RequestStatus';

export const initialState: projectsState = {
  getProjectsStatus: RequestStatus.Initial,
};

const projectsReducer = (state = initialState, action: ProjectsActionTypes) => {
  switch (action.type) {
    case GET_PROJECTS_REQUESTED:
      return { ...state, getProjectsStatus: RequestStatus.Requested };
    case GET_PROJECTS_SUCCESS:
      return { ...state, projects: action.payload, getProjectsStatus: RequestStatus.Success };
    case GET_PROJECTS_FAILED:
      return { ...state, projectsErrorMessage: action.payload, getProjectsStatus: RequestStatus.Failed };
    default:
      return state;
  }
};

export default projectsReducer;
