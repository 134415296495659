import { SearchDto } from '../../api/LRPProxy';
import { CLEAR_SEARCH, SearchActionTypes, SET_SEARCH_SUCCESS, SET_SEARCH_TERM } from './types';
/**
 * Sets the search results.
 */
export function setSearch(search: SearchDto): SearchActionTypes {
  return {
    type: SET_SEARCH_SUCCESS,
    payload: search,
  };
}

export function setSearchTerm(searchTerm: string): SearchActionTypes {
  return {
    type: SET_SEARCH_TERM,
    payload: searchTerm,
  };
}

export function clearSearch(): SearchActionTypes {
  return {
    type: CLEAR_SEARCH,
  };
}
