import Flex from '../ui/Flex';
import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <p className={`${styles.copyright} d-none d-lg-block`}>
            {
              <>
                Copyright © {new Date().getFullYear()} <span>|</span> All Rights Reserved
              </>
            }
          </p>

          <p className={`${styles.copyright} d-block d-lg-none`}>{`Copyright © ${new Date().getFullYear()}`}</p>

          <Flex ml='auto'>
            <a
              className={`footer__link ${styles.footerLink}`}
              title='Go to Privacy Policy'
              aria-label='Go to Privacy Policy'
              href='https://www.terraquest.co.uk/privacy-policy'
              rel='noopener noreferrer'
              target='_blank'
            >
              Privacy Policy
            </a>
            <a
              className={`footer__link ${styles.footerLink}`}
              title='Go to Terms of Use'
              aria-label='Go to Terms of Use'
              href='https://www.terraquest.co.uk/terms-conditions'
              rel='noopener noreferrer'
              target='_blank'
            >
              Terms of Use
            </a>
          </Flex>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
