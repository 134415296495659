/**
 * Get all focusable elements inside of the specifed context.
 *
 * @param  {String} [context='document'] The DOM context you want to search in.
 * @return {Array}  Array of focusable elements
 */
export const getFocusable = (context: HTMLElement | Document = document) => {
  const focusable = Array.from(
    context.querySelectorAll(
      'button, [href], select, textarea, input:not([type="hidden"]), [tabindex]:not([tabindex="-1"])'
    )
  );
  return focusable;
};
