import React, { RefObject, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { ProjectDto } from '../../api/LRPProxy';
import { getUserUuid } from '../../utils/gaUtils';
import { useTagManager } from '../../components/analytics';
import { getMapPanelPartyInfoEvent, getMapPanelParcelInfoEvent } from '../../components/analytics/constants';
import styles from './PopoverModal.module.scss';

type Props = {
  title: string;
  children?: React.ReactNode;
  partyId?: string;
  parcelId?: string;
  project?: ProjectDto;
  containerRef?: RefObject<HTMLDivElement> | null;
};

const ModalComponent = (props: Props) => {
  const [show, setShow] = useState(false);
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  const handleClose = () => setShow(false);
  const handleClick = (event: any) => {
    const label = event.currentTarget.getAttribute('aria-label');

    if (!show) {
      label.includes('Party')
        ? sendCustomEvent(getMapPanelPartyInfoEvent(getUserUuid(user), props.partyId, props.project))
        : sendCustomEvent(getMapPanelParcelInfoEvent(getUserUuid(user), props.parcelId, props.project));
    }

    setShow(true);
  };

  return (
    <>
      <button aria-label={props.title} title={props.title} onClick={handleClick} className={styles.infoButton}>
        Info
      </button>

      <Modal show={show} onHide={handleClose} backdrop='static' centered dialogClassName={styles.infoModalContainer}>
        <Modal.Header closeButton className={styles.infoModalHeader}>
          {props.title}
        </Modal.Header>
        <Modal.Body className={styles.infoModalBody}>{props.children}</Modal.Body>
      </Modal>
    </>
  );
};

type EntryType = {
  label?: string;
  text?: string | number;
  children?: React.ReactNode;
  typeTitle?: string;
};

const ModalEntry = (props: EntryType) => {
  return (
    <div className={styles.panelEntry}>
      <h2>{props.typeTitle}:</h2>
      <div className={styles.entryIndentation}>{props.children}</div>
    </div>
  );
};

ModalComponent.Entry = ModalEntry;

export default ModalComponent;
