import React from 'react';
import styled from 'styled-components';
import { buttonStyle, fontSize, size, space } from 'styled-system';
import Box from './Box';
import themedCss from '@styled-system/css';

export const StyledButton = styled.button<{ $isFullWidth: boolean }>`
  appearance: none;
  outline: none;
  border: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : 'auto')};
  font-size: 15px;
  &:disabled {
    background-color: white;
    color: #d3d3d3;
    border: 1px solid #d3d3d3;
  }
  ${themedCss({
    height: ['42px', null, '36px'],
  })}
  ${buttonStyle};
  ${space}
  ${size}
  ${fontSize}
`;

export const StyledButtonIcon = styled((props) => <Box as='span' {...props} />)``;
