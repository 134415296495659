import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

type Props = RouteProps;

const AuthenticatedRoute: React.FC<Props> = (props) => {
  const isAuthenticated = useIsAuthenticated();

  // User is authenticated, return the standard route.
  if (isAuthenticated) {
    return <Route {...props} />;
  }

  return <div />;
};

export default AuthenticatedRoute;
