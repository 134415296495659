import { AppThunk } from '../store';
import { setSearch, setSearchTerm } from './actions';
import { SET_SEARCH_FAILED, SET_SEARCH_REQUESTED } from './types';

export function thunkSearch(searchString: string, projectId: string): AppThunk {
  return async (dispatch, getState, api) => {
    dispatch({ type: SET_SEARCH_REQUESTED });

    dispatch(setSearchTerm(searchString));

    await api
      .search(searchString, projectId)
      .then((data) => {
        dispatch(setSearch(data.result));
      })
      .catch(() => {
        dispatch({
          type: SET_SEARCH_FAILED,
        });
      });
  };
}
