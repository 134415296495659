import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ProjectDto } from '../../../api/LRPProxy';
import SearchResult from '../../../components/search/SearchResult';
import { SearchContainer } from '../../../containers/SearchContainer';
import { RootState } from '../../../store';
import RequestStatus from '../../../store/RequestStatus';
import { SearchState } from '../../../store/search/types';
import LoadingSpinner from '../../../components/loading-spinner/LoadingSpinner';
import styles from './SearchResults.module.scss';
import Alert from '../../../components/alert/Alert';

type Props = { projectDetail: ProjectDto };

const Search: React.FunctionComponent<Props> = ({ projectDetail: project }) => {
  const [hasActiveAccordion, setHasActiveAccordion] = useState(false);
  const { setSearchStatus: searchResultStatus, results: searchResults, searchTerm } = useSelector<
    RootState,
    SearchState
  >((state) => state.search);
  const isSearching = searchResultStatus !== RequestStatus.Initial;

  return (
    <div
      className={`${styles.Container} ${isSearching ? styles.isSearching : ``}  ${
        searchResults ? styles.hasResults : ''
      } ${hasActiveAccordion ? styles.fixedHeight : ''}`}
      data-testid='search-bar'
    >
      <SearchContainer projectDetail={project} />
      {searchResultStatus === RequestStatus.Requested && <LoadingSpinner />}
      {searchResultStatus === RequestStatus.Failed && (
        <Alert
          status='warning'
          title="Search couldn't be conducted."
          description='Search terms should be between 1 – 100 characters in length and cannot contain the following characters: < > * ^ ¬ ¦ + ~ . Please remove these and search again.'
        />
      )}
      {searchResults && project.id && (
        <SearchResult
          hasActiveAccordion={hasActiveAccordion}
          setHasActiveAccordion={setHasActiveAccordion}
          searchDetails={searchResults}
          project={project}
          searchTerm={searchTerm}
        />
      )}
    </div>
  );
};

export default Search;
