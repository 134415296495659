import { createBrowserHistory } from 'history';
import React from 'react';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { getDefaultConfiguration, RouterNavigationClient, TqAuthProvider } from '@tq/auth-components';
import { PublicClientApplication } from '@azure/msal-browser';
import { setPca } from './api/config';
import Layout from './components/Layout';
import { TagManagerProvider } from './components/analytics/TagManagerProvider';
import './css/main.scss';
import NavProvider from './providers/NavProvider';
import configureStore from './store/store';
import theme from './theme';
import { AuthWrapper } from './utils/AuthWrapper';
import { gaEventSender } from './utils/gaUtils';

const store = configureStore();
const history = createBrowserHistory();

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID!,
};

function initMsal() {
  const authority = process.env.REACT_APP_B2C_AUTHORITY;
  const signInPolicyPath = process.env.REACT_APP_B2C_POLICY_SIGN_IN!; //process.env.REACT_APP_B2C_POLICY_SIGN_IN;
  const clientId = process.env.REACT_APP_B2C_CLIENT_ID;

  const config = getDefaultConfiguration(
    authority!,
    signInPolicyPath!,
    clientId!,
    'login',
    new RouterNavigationClient(history.push)
  );

  const pca = new PublicClientApplication(config);

  pca.addEventCallback((e) => {
    const allAccounts = pca.getAllAccounts();
    const activeAccount = allAccounts[0];

    if (allAccounts.length > 0) {
      pca.setActiveAccount(activeAccount);
    }

    gaEventSender(e, activeAccount);
  });

  setPca(pca);

  return pca;
}

TagManager.initialize(tagManagerArgs);

const pca = initMsal();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <TqAuthProvider
          instance={pca}
          acquireTokenRequest={{
            scopes: [process.env.REACT_APP_B2C_SCOPE!],
            authority: process.env.REACT_APP_B2C_AUTHORITY! + '/' + process.env.REACT_APP_B2C_POLICY_SIGN_IN!,
          }}
        >
          <TagManagerProvider>
            <AuthWrapper>
              <NavProvider>
                <Router history={history}>
                  <ThemeProvider theme={theme}>
                    <Layout />
                  </ThemeProvider>
                </Router>
              </NavProvider>
            </AuthWrapper>
          </TagManagerProvider>
        </TqAuthProvider>
      </ReduxProvider>
    </QueryClientProvider>
  );
};
export default App;
