import { SearchDto } from '../../api/LRPProxy';
import RequestStatus from '../RequestStatus';

export const SET_SEARCH_REQUESTED = ' SET_SEARCH_REQUESTED';
export const SET_SEARCH_SUCCESS = ' SET_SEARCH_SUCCESS';
export const SET_SEARCH_FAILED = ' SET_SEARCH_FAILED';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const SET_SEARCH_TERM = ' SET_SEARCH_TERM';

export type SearchState = {
  results?: SearchDto;
  setSearchStatus: RequestStatus;
  searchErrorMessage?: string;
  searchTerm?: string;
};

type SetSearchRequestedAction = {
  type: typeof SET_SEARCH_REQUESTED;
};

type SetSearchSuccessAction = {
  type: typeof SET_SEARCH_SUCCESS;
  payload: SearchDto;
};

type SetSearchFailedAction = {
  type: typeof SET_SEARCH_FAILED;
  payload: string;
};
type SetSearchClearAction = {
  type: typeof CLEAR_SEARCH;
};

type SetSearchTermAction = {
  type: typeof SET_SEARCH_TERM;
  payload: string;
};

export type SearchActionTypes =
  | SetSearchRequestedAction
  | SetSearchSuccessAction
  | SetSearchFailedAction
  | SetSearchClearAction
  | SetSearchTermAction;
