import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { useMsal } from '@azure/msal-react';
import Box from '../../components/ui/Box';
import Flex from '../../components/ui/Flex';
import useIsMobile from '../../hooks/useIsMobile';
import { getFocusable } from '../../utils/accessibilityUtils';
import { getUserUuid } from '../../utils/gaUtils';
import { SendCustomEventProps, useTagManager } from '../../components/analytics';
import { getHelpPageHome, getHelpPageFaq, getHelpPageGlossary } from '../../components/analytics/constants';
import FAQ from './faq/FAQ';
import Glossary from './glossary/Glossary';
import {
  CaretDownIcon,
  ChevronRightIcon,
  Content,
  DesktopNav,
  MobileNav,
  MobileNavToggle,
  NavLink,
} from './Help.styles';
import HelpIndex from './HelpIndex';

type HelpRoute = {
  label: string;
  path: string;
  gaFunc: (userId: string) => SendCustomEventProps;
};

const routes: HelpRoute[] = [
  {
    label: 'Home',
    path: '/help',
    gaFunc: getHelpPageHome,
  },
  {
    label: 'FAQ',
    path: '/help/faq',
    gaFunc: getHelpPageFaq,
  },
  {
    label: 'Glossary of terms',
    path: '/help/glossary',
    gaFunc: getHelpPageGlossary,
  },
];

type Props = RouteComponentProps;

const HelpLayout: FC<Props> = ({ location }) => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const toggleMobileNav = () => setMobileNavIsOpen((prev) => !prev);
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  // Not great but will do for now, help center is subject to change as we start to use real content
  const currentRoute = useMemo(() => {
    const found = routes.find((route) => route.path === location.pathname);

    return found;
  }, [location.pathname]);

  const isMobile = useIsMobile();
  const layoutRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const HelpNavLink = ({ route }: { route: HelpRoute }) => (
    <>
      <NavLink
        title={`Navigate to ${route.label}`}
        $isActive={location.pathname === route.path}
        to={route.path}
        onClick={() => {
          sendCustomEvent(route.gaFunc(getUserUuid(user)));
          return isMobile ? setMobileNavIsOpen(false) : undefined;
        }}
      >
        <Box as='span' mr={2}>
          {route.label}
        </Box>
        <ChevronRightIcon />
      </NavLink>
    </>
  );

  useEffect(() => {
    if (layoutRef.current && contentRef.current) {
      const firstFocusableElement = getFocusable(contentRef.current)[0] as HTMLElement;
      firstFocusableElement ? firstFocusableElement.focus() : layoutRef.current.focus();
      layoutRef.current?.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <Flex ref={layoutRef} sx={{ flexDirection: ['column', 'row'], flexGrow: 1, overflow: 'auto', outline: 'none' }}>
      <DesktopNav>
        {routes.map((route) => (
          <HelpNavLink key={route.path} route={route} />
        ))}
      </DesktopNav>

      <MobileNav>
        <MobileNavToggle onClick={toggleMobileNav}>
          {currentRoute?.label}
          <CaretDownIcon />
        </MobileNavToggle>
      </MobileNav>

      {isMobile && mobileNavIsOpen && (
        <Box>
          {routes.map((route) => (
            <HelpNavLink key={route.path} route={route} />
          ))}
        </Box>
      )}

      <Content ref={contentRef} sx={{ display: [mobileNavIsOpen ? 'none' : 'flex', 'flex'] }}>
        <Switch>
          <Route exact path='/help' component={HelpIndex} />
          <Route path='/help/faq' component={FAQ} />
          <Route path='/help/glossary' component={Glossary} />
        </Switch>
      </Content>
    </Flex>
  );
};

export default HelpLayout;
