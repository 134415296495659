import React, { KeyboardEvent, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { ProjectDto } from '../../api/LRPProxy';
import { getProjectMapUrl } from '../../utils/router';
import { getUserUuid } from '../../utils/gaUtils';
import { useTagManager } from '../../components/analytics';
import { getMapSearchTermEvent } from '../../components/analytics/constants';
import styles from './Search.module.scss';

type Props = {
  projectDetail: ProjectDto;
  searchTerm: string | undefined;
  onSearch: (term: string) => void;
  onCancel: () => void;
};

const SearchInputField: React.FC<Props> = ({ projectDetail, onSearch, onCancel, searchTerm }) => {
  const history = useHistory();
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();
  const [search, setSearch] = useState(searchTerm || '');

  useEffect(() => {
    if (!searchTerm) {
      setSearch('');
    }
  }, [searchTerm]);

  const keySubmit = (event: KeyboardEvent<HTMLInputElement>, search: string) => {
    if (event.charCode === 13 && search !== '') {
      sendCustomEvent(getMapSearchTermEvent(getUserUuid(user), projectDetail));
      onSearch(search);
    }
  };

  const clearSearch = () => {
    setSearch('');
    onCancel();
    history.push(getProjectMapUrl(projectDetail.id));
  };

  return (
    <div className={styles.InputWrapper}>
      <input
        type='text'
        minLength={1}
        maxLength={100}
        className={styles.Search}
        placeholder='Search (or enter # for land parcel results)'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyPress={(e) => keySubmit(e, search.trim())}
        data-testid='search-input-field'
        aria-label='Input search term'
      />

      <button
        data-testid='searchBtn'
        type='submit'
        className={`${styles.Button} ${styles.Submit} ${search.length > 0 && styles.Focus}`}
        disabled={!search}
        onClick={() => {
          sendCustomEvent(getMapSearchTermEvent(getUserUuid(user), projectDetail));
          onSearch(search.trim());
        }}
        aria-label='Submit search'
      >
        <FiSearch fontSize='2rem' />
      </button>

      <button
        data-testid='clearBtn'
        className={`${styles.Button} ${styles.Close} ${search.length > 0 && styles.Focus}`}
        onClick={clearSearch}
        aria-label='Close'
      >
        <IoMdClose fontSize='2rem' />
      </button>
    </div>
  );
};

export default SearchInputField;
