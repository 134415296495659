import React, { RefObject, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { InterestDto, ProjectDto } from '../../api/LRPProxy';
import useWindowSize, { IWindowSize } from '../../hooks/useWindowSize';
import { getSupportingEvidenceUrl } from '../../utils/router';
import ModalComponent from './ModalComponent';
import PopoverComponent from './PopoverComponent';
import styles from './PopoverModal.module.scss';

type Props = {
  title: string;
  children?: React.ReactNode;
  data?: InterestDto[];
  project?: ProjectDto;
  previousRouteLabel: string;
  containerRef?: RefObject<HTMLDivElement> | null;
  parcelId?: string;
};

const PopOverComponent = (props: Props) => {
  const { width }: IWindowSize = useWindowSize();
  let isSmallScreen;

  if (width && width < 907) {
    isSmallScreen = true;
  } else {
    isSmallScreen = false;
  }

  const sortingOrder = ['Freehold', 'Leasehold', 'Tenant', 'Occupation', 'Mortgage', 'ThirdParty'];

  const interestsByType = useMemo(() => {
    const interests: { [key: string]: InterestDto[] } = {
      Freehold: [],
      Leasehold: [],
      Tenant: [],
      Occupation: [],
      Mortgage: [],
      ThirdParty: [],
    };

    if (props.data) {
      props.data.forEach((interest) => {
        const { type } = interest;
        if (!type) {
          return;
        }

        return interests[type].push(interest);
      });
    }

    return interests;
  }, [props.data]);

  const Container = isSmallScreen ? ModalComponent : PopoverComponent;

  return (
    <Container title={props.title} containerRef={props.containerRef} parcelId={props.parcelId} project={props.project}>
      {sortingOrder.map(
        (key: string, i: number) =>
          interestsByType[key].length > 0 && (
            <Container.Entry typeTitle={key} key={key + i}>
              {interestsByType[key].map((interest: any, i: number) => (
                <div className={styles.infoWrapper} key={i}>
                  <div>
                    {interest.description ? `${interest.description}.` : `${interest.type}.`}
                    <br />
                    {interest.partyInterestCapacity && (
                      <>
                        {interest.partyInterestCapacity.charAt(0).toUpperCase()}
                        {interest.partyInterestCapacity.slice(1)}.{' '}
                      </>
                    )}
                    {interest.status === 'Historic' || (interest.partyInterestStatus === 'Historic' && <>(Historic)</>)}
                  </div>
                  {interest.supportingEvidences.length > 0 && (
                    <div>
                      <span className='font-weight-bold'>Supporting Evidences: </span>
                      {interest.supportingEvidences.map((evidence: any, j: number) => (
                        <Link
                          to={{
                            pathname: getSupportingEvidenceUrl(props.project?.id, evidence.id),
                            state: {
                              previousRoute: { label: props.previousRouteLabel },
                            },
                          }}
                          key={evidence.id}
                        >
                          {evidence.supportingEvidenceReference}
                          {evidence.direction && ` (${evidence.direction})`}
                          {interest.supportingEvidences?.length === j + 1 ? '' : ','}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </Container.Entry>
          )
      )}
    </Container>
  );
};
export default PopOverComponent;
