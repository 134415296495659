import { MapProvider } from '@tq/web-maps';
import React from 'react';
import { useParams } from 'react-router-dom';
import { TimeOnPageCounter } from '../../../../components/analytics';
import { getMapPagePageview } from '../../../../components/analytics/constants';
import { useGetProject } from '../../../../hooks/queries/useGetProject';
import { isValidGuid } from '../../../../utils/validGuid';
import { ProjectLandParcelParams } from '../hooks/useLandParcelDetailLogic';
import { LandParcelDetail } from './LandParcelDetail';

export const LandParcelDetailWrapper: React.FunctionComponent = () => {
  const { projectId } = useParams<ProjectLandParcelParams>();
  const { data: projectDetailResult } = useGetProject({ projectId: projectId }, { enabled: isValidGuid(projectId) });

  const projectDetail = projectDetailResult?.result;

  if (!projectDetail) return null;

  const webMapApiKey = projectDetail.webMapAPIKey;

  return (
    <MapProvider apiUrl={process.env.REACT_APP_WEB_MAPS_API_URL} apiKey={webMapApiKey}>
      <TimeOnPageCounter gaEvent={getMapPagePageview} pageReference='Map' projectName={projectDetail?.name} />
      <LandParcelDetail projectDetail={projectDetail} />
    </MapProvider>
  );
};
