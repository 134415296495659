import React, { cloneElement, FC, isValidElement, ReactElement } from 'react';
import { StyledButton, StyledButtonIcon } from './Button.styles';
import { BoxProps, ButtonProps } from './types';

type Props = ButtonProps & {
  /**
   * If `true`, the button will show a spinner.
   */
  isLoading?: boolean;
  /**
   * The label to show in the button when `isLoading` is true
   * If no text is passed, it only shows the spinner
   */
  loadingText?: string;
  /**
   * If `true`, the button will take up the full width of its container.
   */
  isFullWidth?: boolean;
  /**
   * The html button type to use.
   */
  type?: 'button' | 'reset' | 'submit';
  /**
   * If added, the button will show an icon before the button's label.
   */
  leftIcon?: ReactElement;
  /**
   * If added, the button will show an icon after the button's label.
   */
  rightIcon?: ReactElement;
};

const ButtonIcon: FC<BoxProps> = (props) => {
  const { children, className, ...rest } = props;

  const _children = isValidElement(children)
    ? cloneElement(children, {
        'aria-hidden': true,
        focusable: false,
      })
    : children;

  return <StyledButtonIcon {...rest}>{_children}</StyledButtonIcon>;
};

export const Button: FC<Props> = (props) => {
  const { isFullWidth, leftIcon, children, rightIcon } = props;

  return (
    <StyledButton $isFullWidth={isFullWidth} {...props}>
      {leftIcon && <ButtonIcon mr={[isFullWidth ? 'auto' : 2]}>{leftIcon}</ButtonIcon>}
      {children}
      {rightIcon && <ButtonIcon ml={[isFullWidth ? 'auto' : 2]}>{rightIcon}</ButtonIcon>}
    </StyledButton>
  );
};
