import React from 'react';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import PartyIcon from '../../../../assets/Party/PartyIcon-white.svg';
import PopOver from './PopOver';
import Modal from './Modal';
import styles from './Party.module.scss';
import useWindowSize, { IWindowSize } from '../../../../hooks/useWindowSize';
import { IAddressType } from './Party';

interface RefObject {
  current: any;
}

interface IProps {
  containerRef?: RefObject;
  projectId: string;
  partyId: string;
  referenceId: string;
  partyName: string;
  partyAddresses: IAddressType;
}

const PartyDetails: React.FC<IProps> = ({ referenceId, partyName, partyAddresses, containerRef }) => {
  const popOverProps = { title: `Addresses for Party ID: ${referenceId}`, addresses: partyAddresses };
  const { width }: IWindowSize = useWindowSize();

  const hasPartyAddresses = !!partyAddresses.count;
  const isSmallScreenDevice = width ? width < 907 : false;

  return (
    <Card className={styles.partyCard} data-testid='party-details'>
      <Card.Header className={styles.cardTitle}>
        <img className={styles.icon} src={PartyIcon} alt='icon' />
        <span> Party Details</span>
      </Card.Header>
      <Card.Body className={styles.cardbody}>
        <ListGroup className='list-group-flush'>
          <ListGroupItem className={styles.cardItems}>
            <div>
              <span className={styles.cardLabels}>ID:</span>
              <span className={styles.cardValues} data-testid='id'>{`${referenceId}`}</span>
            </div>
          </ListGroupItem>
          <ListGroupItem className={styles.cardItems}>
            <div>
              <span className={styles.cardLabels}>Name: </span>
              <span className={styles.cardValues} data-testid='name'>{`${partyName}`}</span>
            </div>
          </ListGroupItem>
          <ListGroupItem className={styles.cardItems}>
            <div className={styles.cardAddressContainer}>
              <div className={styles.cardAddress}>
                <span
                  className={styles.cardAddressLabel}
                  style={partyAddresses.count ? { width: '9.4rem' } : { width: '7rem' }}
                  data-testid='addresses count'
                >
                  Address
                  {partyAddresses.count ? (partyAddresses.count > 1 ? ` (${partyAddresses.count}):` : `:`) : `:`}
                </span>

                <span className={styles.cardAddressValue} data-testid='primary address'>
                  {partyAddresses.count ? `${partyAddresses.currentAddressPrimary}` : `No records held.`}
                </span>
              </div>
              {hasPartyAddresses &&
                (isSmallScreenDevice ? (
                  <Modal partyAddresses={popOverProps} />
                ) : (
                  <PopOver containerRef={containerRef} partyAddresses={popOverProps} />
                ))}
            </div>
          </ListGroupItem>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default PartyDetails;
