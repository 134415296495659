import React, { useEffect, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import Box from '../ui/Box';
import Image from '../ui/Image';
import Text from '../ui/Text';
import { FaBars } from 'react-icons/fa';
import { IoMdHelpCircle } from 'react-icons/io';

import { NavContext } from '../../providers/NavProvider';
import logoBig from '../../assets/LR Logo_N_BG.svg';
import logoIcon from '../../assets/lRIcon.svg';
import userIcon from '../../assets/userIcon.svg';
import styles from './Header.module.scss';
import useIsMobile from '../../hooks/useIsMobile';
import { getUserUuid } from '../../utils/gaUtils';
import { useTagManager } from '../../components/analytics';
import { getHelpPageHelp } from '../../components/analytics/constants';
import { MdLogout } from 'react-icons/md';
import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@tq/ui-components';
import { BsChevronDown } from 'react-icons/bs';

const menuItemStyles = {
  fontSize: '1.8rem',
  color: 'white',
  backgroundColor: 'rgb(25, 45, 87)',
  width: '100%',
  border: 'none',
  minWidth: '13rem',
  padding: '1rem',
};

const Header = () => {
  const { burgerOpenClose, closeSideNav } = React.useContext(NavContext);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = instance.getActiveAccount();
  const { pathname } = useLocation();
  const history = useHistory();
  const previousPathname = useRef(pathname);
  const isMobile = useIsMobile();
  const { sendCustomEvent } = useTagManager();

  const handleLogout = () => {
    if (sessionStorage.getItem(`dismissedMobileLandscapePrompt`) === `true`) {
      sessionStorage.removeItem(`dismissedMobileLandscapePrompt`);
    }
    sessionStorage.clear();
    instance.logout();
  };

  useEffect(() => {
    if (previousPathname.current !== pathname) {
      closeSideNav();
    }
    previousPathname.current = pathname;
  });

  return (
    <header className={`${styles.header} `}>
      <div className='container-fluid h-100'>
        <div className='row justify-content-between h-100 align-items-center'>
          <div className={styles.header__logo}>
            <Link to='/' aria-label='Logo' className='d-none d-lg-block'>
              <img src={logoBig} alt='logo' className=' logo ' data-testid='logo' />
            </Link>
            <Link to='/' aria-label='Logo' className='  d-block d-lg-none'>
              <img src={logoIcon} alt='logo' className=' logo ' />
            </Link>
          </div>

          <div className='d-flex justify-content-between align-items-center'>
            <Link
              className={styles.header__helpLink}
              to='/help'
              title='Help'
              onClick={() => sendCustomEvent(getHelpPageHelp(getUserUuid(account)))}
              data-testid='help-button'
            >
              <Box as={IoMdHelpCircle} size='20px' mr={2} />
              Help
            </Link>
            <Menu>
              <MenuButton
                sx={{
                  width: 'max-content',
                  height: 'max-content',
                  background: 'none',
                  padding: '0.5rem',
                  border: '2px solid transparent',
                  '&:active, &:focus': {
                    border: '2px solid white',
                  },
                }}
                variant='menu'
                as={IconButton}
                icon={
                  <>
                    <Image mr='2' src={userIcon} role='presentation' />
                    <Text mr='2' color='white' display={['none', 'unset']}>
                      {account?.name}
                    </Text>
                    <BsChevronDown color='white' />
                  </>
                }
              />
              <MenuList alignSelf={'center'}>
                {isMobile && (
                  <MenuItem
                    title='Go to Help page'
                    aria-label='Go to Help page'
                    onClick={() => history.push('/help')}
                    sx={{
                      ...menuItemStyles,
                      borderBottom: '1px solid white',
                    }}
                  >
                    Help
                  </MenuItem>
                )}
                {isAuthenticated && (
                  <MenuItem
                    title='Log out'
                    aria-label='Log out'
                    data-testid='user-menu-logout'
                    onClick={() => handleLogout()}
                    sx={menuItemStyles}
                  >
                    Logout
                    <span style={{ marginLeft: '2rem' }}>
                      <MdLogout />
                    </span>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>

            <button
              className={`${styles.header__burgerMenu} d-block d-lg-none`}
              onClick={burgerOpenClose}
              title='Open navigation menu'
              name='Open navigation menu'
              aria-label='Open navigation menu'
            >
              <FaBars size='25px' />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
