import { useCallback, useState } from 'react';
import useToast from '../../components/toast/useToast';
import { useGetDocumentSasToken } from './useGetSasDocument';

const useDownloadDocument = ({ id, fileName, projectId }: { id: string; projectId: string; fileName: string }) => {
  const [shouldDownload, setShouldDownload] = useState(false);
  const { isLoading: isDownloading, data: document } = useGetDocumentSasToken(
    { id, projectId },
    {
      enabled: shouldDownload,
      onSuccess: (document) => {
        window.open(document.result.uri);
      },
    }
  );
  const toast = useToast();

  const download = useCallback(() => {
    toast({
      id: `download-document`,
      title: 'Downloading document.',
      description: 'Your download will begin very shortly.',
      status: 'info',
    });
    if (!document) {
      setShouldDownload(true);
      return;
    }
    window.open(document?.result?.uri);
  }, [document, toast]);

  return { download, isDownloading };
};

export default useDownloadDocument;
