import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProjectDto } from '../api/LRPProxy';
import SearchInputField from '../components/search/SearchInputField';
import { RootState } from '../store';
import { clearSearch as emptySearch, setSearchTerm } from '../store/search/actions';
import { thunkSearch } from '../store/search/thunks';
import { SearchState } from '../store/search/types';
import { getProjectMapUrl } from '../utils/router';

type Props = {
  projectDetail: ProjectDto;
};

export const SearchContainer: React.FC<Props> = ({ projectDetail }) => {
  const dispatch = useDispatch();
  const searchState = useSelector<RootState, SearchState>((state) => state.search);

  const history = useHistory();

  const onSearch = (term: string) => {
    if (projectDetail.id) {
      history.push(getProjectMapUrl(projectDetail.id));
      dispatch(thunkSearch(term, projectDetail.id));
    }
  };

  const onCancel = () => {
    dispatch(emptySearch());
    dispatch(setSearchTerm(''));
  };

  return (
    <SearchInputField
      onSearch={onSearch}
      onCancel={onCancel}
      projectDetail={projectDetail}
      searchTerm={searchState.searchTerm}
    />
  );
};
