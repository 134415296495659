import themedCss from '@styled-system/css';
import React, { FC } from 'react';
import styled from 'styled-components';
import Box from '../../components/ui/Box';
import Flex from '../../components/ui/Flex';
import Image from '../../components/ui/Image';
import Text from '../../components/ui/Text';
import { BoxProps } from '../../components/ui/types';

export const Wrapper = styled.div`
  flex-grow: 1;
  background-color: #fafbfc;
`;

export const Header = styled(Flex)`
  align-items: flex-end;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #fafbfc;
  z-index: 2;

  ${themedCss({
    bg: 'background.main',
    pt: [3, null, 10],
    pb: [6, null, 5],
  })}

  @media (max-width: 991px) {
    ${themedCss({
      py: 3,
      borderBottom: 'none',
    })}
  }
`;

export const Body = styled(Box)`
  flex: 1 1 auto;

  ${themedCss({
    pb: 5,
  })}
`;

export const Card = styled(Flex)`
  flex-direction: column;
  min-height: 32rem;
  overflow: hidden;
  border-radius: 1.2rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border-top: solid 1px #d5e3ef;
`;

export const CardHeader = styled(Box)`
  background: #192d57;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;

  ${themedCss({
    px: 4,
    py: 3,
  })}
`;

export const CardBody = styled(Box)`
  /* ${themedCss({
    pt: [3, null, null, 6],
    pb: [3, null, null, 4],
    px: [2, null, null, 5],
  })} */
`;

export const StatContainer = styled(Flex)`
  flex: 1;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(44, 40, 40, 0.11);
  border-radius: 1rem;

  ${themedCss({
    bg: 'background.main',
    px: 2,
    py: 3,
    flexDirection: 'column',
    alignItems: 'center',
  })}
`;

export const StatTextContainer = styled(Box)`
  max-width: 100%;

  ${themedCss({
    mt: [1, null, null, 0],
    textAlign: 'center',
  })};
`;

export const StatIconContainer = styled(Flex)`
  flex: 0 0 3.2rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${themedCss({
    width: '3.2rem',
    height: '3.2rem',
    ml: '0',
    order: -1,
  })};
`;

export const StatIcon: FC<BoxProps> = (props) => <Image size='12px' {...props} />;

export const StatLabel = styled(Text)`
  color: #454545;

  ${themedCss({
    fontSize: ['11px', null, null, '13px'],
  })}
`;

export const StatValue = styled(Text)`
  color: #192d57;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  ${themedCss({
    fontSize: ['16px', null, null, '20px'],
  })}
`;
