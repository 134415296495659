import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useMsal } from '@azure/msal-react';
import { client } from '../../api';
import { ProjectsDto, SwaggerResponse } from '../../api/LRPProxy';
import useToast from '../../components/toast/useToast';
import { getFallbackQueryErrorToastOptions, getQueryError403ToastOptions } from '../../utils/error';
import { QueryError } from './types';

type TResult = SwaggerResponse<ProjectsDto[]>;
type Variables = {
  pageNumber?: number | undefined;
  pageSize?: number | undefined;
  orderBy?: string | null | undefined;
  fields?: string | null | undefined;
};

type Options = UseQueryOptions<TResult, QueryError>;

export const useGetProjectsQueryOptions = () => {
  const toast = useToast();
  const { instance } = useMsal();
  return useCallback(
    ({ pageNumber, orderBy, fields }: Variables, options: Options = {}) => ({
      queryKey: ['projects', { pageNumber, orderBy, fields }],
      queryFn: () =>
        client.getProjects(pageNumber, 100, orderBy, fields).catch((e) => {
          const fireFallbackToast = () => toast(getFallbackQueryErrorToastOptions());

          if ('status' in e) {
            switch (e.status) {
              case 401:
                instance.logout();
                break;
              case 403:
                toast(getQueryError403ToastOptions());
                break;
              default:
                fireFallbackToast();
            }         
          } else fireFallbackToast();

          throw e;
        }),
      retry: false,
      ...options,
    }),
    [toast, instance]
  );
};

export const useGetProjects = (variables: Variables, options?: Options) => {
  const getProjectsQueryOptions = useGetProjectsQueryOptions();
  return useQuery<TResult, QueryError>(getProjectsQueryOptions(variables, options));
};
