import { useSelect } from 'downshift';
import { default as React, useCallback, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsChevronDown } from 'react-icons/bs';
import { usePopper } from 'react-popper';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { ClientsDto2, ProjectsDto } from '../api/LRPProxy';
import arrowDown from '../assets/arrow-down.svg';
import arrowUp from '../assets/arrow-up.svg';
import { getUserUuid } from '../utils/gaUtils';
import LoadingSpinner from '../components/loading-spinner/LoadingSpinner';
import ProjectCard from '../components/project-card/ProjectCard';
import { useTagManager, TimeOnPageCounter } from '../components/analytics';
import { getSelectedProjectCardEvent, getProjectListPageview } from '../components/analytics/constants';
import { useGetProjects } from '../hooks/queries/useGetProjects';
import { clearSearch as emptySearch, setSearchTerm } from '../store/search/actions';
import { mergeRefs } from '../utils/reactUtils';
import { Helmet } from 'react-helmet';
import {
  Container,
  HeaderContent,
  HeaderWrapper,
  MainWrapper,
  Option,
  OptionContent,
  OptionList,
  OptionSuffix,
  OptionSuffixIcon,
  Select,
  SelectTrigger,
  Title,
  Wrapper,
} from './ProjectsList.styles';

export type Project = {
  id: string;
  name?: string | undefined;
  status?: string | undefined;
  client?: ClientsDto2;
  webMapAPIKey?: string | undefined;
  lastRefreshedDate?: Date;
  documentStorageURL?: string | undefined;
  documentStorageContainer?: string | undefined;
};

const items = [
  { id: 'lastRefreshedDate desc', label: 'Last updated' },
  { id: 'status asc', label: 'Status', order: 'asc' },
  { id: 'status desc', label: 'Status', order: 'desc' },
  { id: 'name asc', label: 'Project name', order: 'asc' },
  { id: 'name desc', label: 'Project name', order: 'desc' },
  // { id: 'name', title: 'Project name' },
];

const useTogglePopper = () => {
  const referenceElement = useRef<HTMLButtonElement | null>(null);
  const popperElement = useRef<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement.current, popperElement.current, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
    ],
  });

  return {
    styles,
    attributes,
    setArrowElement,
    referenceElement,
    popperElement,
  };
};

const ProjectsList = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const popper = useTogglePopper();
  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, getItemProps, highlightedIndex } = useSelect({
    items,
    initialSelectedItem: items[0],
  });
  const { status: projectsStatus, data: projects } = useGetProjects({ orderBy: selectedItem?.id });
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  const selectTriggerText = useMemo(() => {
    if (selectedItem) {
      let label = selectedItem?.label;
      if (selectedItem.order === 'asc') label += ' A-Z';
      if (selectedItem.order === 'desc') label += ' Z-A';

      return label;
    }

    return 'Sort projects';
  }, [selectedItem]);

  const dispatch = useDispatch();

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      if (!hasScrolled && e.currentTarget.scrollTop > 0) {
        setHasScrolled(true);
      } else if (hasScrolled && e.currentTarget.scrollTop === 0) {
        setHasScrolled(false);
      }
    },
    [hasScrolled, setHasScrolled]
  );

  const handleClick = useCallback(
    (project: ProjectsDto) => {
      sendCustomEvent(getSelectedProjectCardEvent(getUserUuid(user), project));
      dispatch(emptySearch());
      dispatch(setSearchTerm(''));
    },
    [dispatch, sendCustomEvent, user]
  );

  const AscIcon = (item: { label: string }) => <OptionSuffixIcon item={item} src={arrowDown} />;
  const DescIcon = (item: { label: string }) => <OptionSuffixIcon item={item} src={arrowUp} />;

  if (!projects) {
    if (projectsStatus === 'loading')
      return (
        <div className={`w-100 d-flex justify-content-center align-items-center`}>
          {/* Work around until loading spinner is fixed  with a max width for desktop and mobile */}
          <LoadingSpinner style={{ margin: '0 1.6rem', flexGrow: 1, maxWidth: '36rem' }} />
        </div>
      );
    if (projectsStatus === 'error') return <></>;

    return <></>;
  }

  const { ref: downshiftRef, ...toggleButtonProps } = getToggleButtonProps();

  return (
    <>
      <Helmet>
        <title>TerraQuest Land Referencing Projects</title>
      </Helmet>
      <Wrapper onScroll={handleScroll} className={`w-100 d-flex flex-column`}>
        <HeaderWrapper $hasScrolled={hasScrolled}>
          <Container>
            <TimeOnPageCounter gaEvent={getProjectListPageview} pageReference='MyPro' />
            <HeaderContent>
              <Title className='text-primary'>My Projects</Title>
              <Select ref={popper.popperElement}>
                <SelectTrigger
                  {...toggleButtonProps}
                  ref={mergeRefs([popper.referenceElement, downshiftRef])}
                  type='button'
                >
                  {selectTriggerText}
                  <BsChevronDown style={{ marginLeft: 'auto', strokeWidth: '0.15rem' }} />
                </SelectTrigger>
                {isOpen && (
                  <OptionList
                    $isOpen={isOpen}
                    style={popper.styles.popper}
                    {...popper.attributes.popper}
                    {...getMenuProps()}
                  >
                    {items.map((item, index) => (
                      <Option
                        key={`${item}${index}`}
                        $isSelected={selectedItem?.id === item.id}
                        $isHighlighted={highlightedIndex === index}
                        {...getItemProps({ item, index })}
                      >
                        <OptionContent>
                          {item.label}
                          {item.order && (
                            <OptionSuffix>
                              {(() => {
                                if (item.order === 'asc') {
                                  return (
                                    <span>
                                      A-Z
                                      <AscIcon label={item.label} />
                                    </span>
                                  );
                                } else if (item.order === 'desc') {
                                  return (
                                    <span>
                                      Z-A
                                      <DescIcon label={item.label} />
                                    </span>
                                  );
                                }
                              })()}
                            </OptionSuffix>
                          )}
                        </OptionContent>
                      </Option>
                    ))}
                  </OptionList>
                )}
              </Select>
            </HeaderContent>
          </Container>
        </HeaderWrapper>
        <MainWrapper>
          <Container>
            <Row>
              {projects?.result?.map(
                (project) =>
                  project.id !== undefined && (
                    <Col xs={12} md={6} lg={4} key={project.id} className='mb-4'>
                      <ProjectCard project={project as Project} onClick={() => handleClick(project)} />
                    </Col>
                  )
              )}
            </Row>
          </Container>
        </MainWrapper>
      </Wrapper>
    </>
  );
};

export default ProjectsList;
