import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { isValidGuid } from '../utils/validGuid';
import { getProjectMapUrl } from '../utils/router';
import { ProjectLandParcelParams } from '../routes/project/land-parcel-detail/hooks/useLandParcelDetailLogic';

/**
 * React hook that returns a callback that, when invoked, attempts to redirect to the project map based on the `projectId` found in the current URL.
 * If the URL doesn't contain a valid `projectId`, it redirects to the home page (ProjectsList)
 */
const useRedirectToProjectMap = () => {
  const history = useHistory();
  const match = useRouteMatch<Partial<ProjectLandParcelParams>>();
  const urlContainsValidProjectId = isValidGuid(match.params.projectId);

  return useCallback(
    () => history.replace(urlContainsValidProjectId ? getProjectMapUrl(match.params.projectId) : '/'),
    [history, match, urlContainsValidProjectId]
  );
};

export default useRedirectToProjectMap;
