import React, { FC } from 'react';
import { Spinner } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import DownloadDownIcon from '../../../../assets/SupportingEvidence/DownloadDownIcon.svg';
import useDownloadDocument from '../../../../hooks/queries/useGetSasDownloadDocument';
import { getUserUuid } from '../../../../utils/gaUtils';
import { useTagManager } from '../../../../components/analytics';
import { getMapPanelSupportingEvidenceDownloadEvent } from '../../../../components/analytics/constants';
import styles from './SupportingEvidence.module.scss';
import { ProjectDto } from '../../../../api/LRPProxy';

type Props = {
  id: string;
  name: string;
  project: ProjectDto;
  type: string;
};

const DocumentCard: FC<Props> = ({ id, project, name, type }) => {
  const { download, isDownloading } = useDownloadDocument({ id, projectId: project.id ?? '', fileName: name });
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  return (
    <div key={id} className={styles.AccordionResult}>
      <div className={styles.Results}>
        <div>
          <span className={styles.listLabel}>Type: </span>
          <span>{type}</span>
        </div>
        <hr className={styles.Break} />
        <div className={styles.DownloadCtaContainer}>
          <div className={styles.DownloadCtaContainerText}>
            <span className={styles.listLabel}>Name: </span>
            <span>{name}</span>
          </div>
          {isDownloading ? (
            <Spinner
              animation='border'
              role='status'
              variant='primary'
              style={{ flex: '0 0 2rem', marginLeft: '1rem' }}
            />
          ) : (
            <button
              aria-label='Download documents'
              disabled={isDownloading}
              className={styles.DownloadButton}
              onClick={() => {
                sendCustomEvent(getMapPanelSupportingEvidenceDownloadEvent(getUserUuid(user), project, id));
                download();
              }}
            >
              <img src={DownloadDownIcon} alt='Download documents' style={{ width: '2rem', height: '2rem' }} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentCard;
