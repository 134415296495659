import { format } from 'date-fns';
import { Timeline } from 'primereact/timeline';
import { Tooltip } from 'primereact/tooltip';
import React, { useRef, useState, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { TimelinesDto } from '../../api/LRPProxy';
import { getUserUuid } from '../../utils/gaUtils';
import Box from '../../components/ui/Box';
import Flex from '../../components/ui/Flex';
import Heading from '../../components/ui/Heading';
import Text from '../../components/ui/Text';
import { useTagManager } from '../../components/analytics';
import { getProjectOverviewSelectMilestone } from '../../components/analytics/constants';
import { useGetTimelineQuery } from '../../hooks/queries/useGetTimelineQuery';
import { useGetProjectOverview } from '../../hooks/queries/useGetProjectOverview';
import useIsMobile from '../../hooks/useIsMobile';
import { StyledModal } from './ProjectInformationCard';
import { Card, CardBody, CardHeader } from './ProjectOverview.styles';

type Props = {
  item: TimelinesDto;
};

const TimeLineMarker: React.FC<Props> = (props) => {
  const [openedModalId, setOpenedModalId] = useState<string>('');
  const modalRef = useRef(null);
  const isMobile = useIsMobile();
  const { sendCustomEvent } = useTagManager();
  const { instance } = useMsal();
  const user = instance.getActiveAccount();
  const { projectId } = useParams<{ projectId: string }>();
  const { data } = useGetProjectOverview({ projectId });

  return (
    <>
      <Box
        as={isMobile ? 'div' : 'button'}
        aria-label='Click to view details'
        minWidth='1.5rem'
        minHeight='1.5rem'
        width='1.5rem'
        height='1.5rem'
        backgroundColor={props.item.description ? '#4c723e' : '#707070'}
        sx={{ borderRadius: '50%', cursor: 'pointer', border: 0 }}
        className={`custom-${props.item.id}`}
        onClick={() => {
          sendCustomEvent(getProjectOverviewSelectMilestone(getUserUuid(user), data?.result.project));
          if (!props.item.id) return;
          if (!isMobile) setOpenedModalId(props.item.id);
        }}
      />
      <Tooltip target={`.custom-${props.item.id}`} position='top' autoHide={false} hideDelay={100} baseZIndex={100000}>
        <Box>
          <Heading as='h3' color='#192d57' fontWeight='600' mb='.5rem'>
            {props.item.title}
          </Heading>
          <Text color='#454545'>{props.item.description}</Text>
        </Box>
      </Tooltip>

      <StyledModal
        ref={modalRef}
        contentStyle={{ borderRadius: isMobile ? undefined : '1.2rem' }}
        id={props.item.id}
        dismissableMask
        showHeader={false}
        visible={props.item.id === openedModalId && !isMobile}
        style={{ maxWidth: '50rem', minWidth: '30rem', overflow: 'auto' }}
        onHide={() => {
          setOpenedModalId('');
        }}
        ariaCloseIconLabel='close modal'
        focusOnShow
      >
        <Flex
          justifyContent='space-between'
          alignItems='center'
          paddingTop='1rem'
          sx={{ position: 'sticky', top: '0', backgroundColor: 'white' }}
        >
          <Heading as='h3' color='#0c203d' fontSize='1.6rem' fontWeight='600' paddingY={2}>
            {props.item.title}
          </Heading>
          <Box
            as='button'
            onClick={() => setOpenedModalId('')}
            style={{ backgroundColor: 'transparent' }}
            aria-label='close modal'
            title='close modal'
            aria-labelledby='close modal'
            role='button'
            sx={{ border: '0', padding: '0', _hover: { border: '1px solid black' } }}
          >
            <AiOutlineClose fontSize='2rem' />
          </Box>
        </Flex>
        <Box overflowY='auto'>
          <Text fontSize='1.5rem' lineHeight='1.67' color='#454545'>
            {props.item.description}
          </Text>
        </Box>
      </StyledModal>
    </>
  );
};

const TimelineCard = ({ startDate, modifiedDate }: { startDate: Date | undefined; modifiedDate: Date | undefined }) => {
  const isMobile = useIsMobile();
  const { projectId } = useParams<{ projectId: string }>();
  const { data: timeLineData, status: timeLineStatus } = useGetTimelineQuery({ projectId: projectId });
  const timeLineEvents = useMemo(() => {
    if (!timeLineData) return;

    return timeLineData?.result.map((tl) => {
      return {
        id: tl.id,
        date: tl.date && format(tl.date, 'MM/dd/yyyy'),
        description: tl.description,
        title: tl.title,
      };
    });
  }, [timeLineData]);

  const timeLineEventsWithNoData = useMemo(() => {
    if (timeLineData?.result.length === 0 && startDate?.getTime() === modifiedDate?.getTime()) {
      return [
        {
          id: startDate?.getTime() ?? 0,
          date: startDate ? format(startDate, 'dd/MM/yy') : null,
          description: 'Project created',
          title: 'Project created',
        },
      ];
    } else if (timeLineData?.result.length === 0 && startDate?.getTime() !== modifiedDate?.getTime()) {
      return [
        {
          id: startDate?.getTime(),
          date: startDate ? format(startDate, 'dd/MM/yy') : null,
          description: 'Project created',
          title: 'Project created',
        },
        {
          id: modifiedDate?.getTime(),
          date: modifiedDate ? format(modifiedDate, 'dd/MM/yy') : null,
          description: 'Project modified',
          title: 'Project modified',
        },
      ];
    }
  }, [modifiedDate, startDate, timeLineData?.result.length]);

  const marker = (item: TimelinesDto) => {
    return <TimeLineMarker item={item} />;
  };

  const customizedContent = (item: TimelinesDto) => {
    const style = !isMobile
      ? { display: 'inline-block', transform: 'rotate(45deg) translateX(7px) translateY(-1px)' }
      : null;
    return (
      <>
        <Text color='#454545' fontWeight='600' fontSize='1.2rem' sx={style} as='span'>
          {item.date}
        </Text>
      </>
    );
  };

  return (
    <Card sx={{ gridColumn: ['span 6'] }} minHeight='unset !important'>
      <CardHeader>
        <Heading color='white' fontWeight='600'>
          Project Timeline/Milestones
        </Heading>
      </CardHeader>
      <CardBody padding='2rem' height='100%'>
        {timeLineStatus === 'loading' && (
          <Box minHeight='100px' display='grid'>
            <Spinner animation='border' role='status' style={{ display: 'flex', margin: 'auto' }} color='#192d57' />
          </Box>
        )}
        {timeLineStatus === 'success' && (
          <Timeline
            style={{
              overflowY: 'auto',
              paddingInline: '3rem',
              paddingBottom: isMobile ? undefined : '3rem',
            }}
            value={timeLineData?.result.length > 0 ? timeLineEvents : timeLineEventsWithNoData}
            layout={isMobile ? 'vertical' : 'horizontal'}
            align={isMobile ? 'alternate' : 'left'}
            content={customizedContent}
            marker={marker}
            className='scroller'
          />
        )}
      </CardBody>
    </Card>
  );
};

export default TimelineCard;
