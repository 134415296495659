import { useCallback, useState } from 'react';
import useToast from '../components/toast/useToast';
import { useGetDocumentSasToken, TResult } from './queries/useGetSasDocument';
import { useGetProjectDocuments } from './queries/useGetProjectDocuments';

type Options = {
  onSave?(data: TResult): void;
};

const useDownloadProjectDocuments = ({ projectId }: { projectId: string }, options?: Options) => {
  const [shouldDownload, setShouldDownload] = useState(false);
  const { data: documentsData } = useGetProjectDocuments({ projectId });

  const toast = useToast();

  const { isLoading: isDownloading, data: document } = useGetDocumentSasToken(
    { id: documentsData?.result[0]?.id ? documentsData?.result[0]?.id : '', projectId },
    {
      enabled: !!documentsData && !!shouldDownload,
      onSuccess: (data) => {
        window.open(data.result.uri);
      },
    }
  );

  const download = useCallback(() => {
    toast({
      id: `download-project-documents-${projectId}`,
      title: 'Downloading project documents.',
      description: 'Your download will begin very shortly.',
      status: 'info',
    });
    if (!document) {
      setShouldDownload(true);
      return;
    }

    window.open(document?.result?.uri);
  }, [projectId, document, toast]);

  return { download, isDownloading, isDownloaded: !!document, hasDocuments: !!documentsData?.result[0] };
};

export default useDownloadProjectDocuments;
