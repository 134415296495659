import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { SendCustomEventProps, useTagManager } from './index';
import { getUserUuid } from '../../utils/gaUtils';

type Props = {
  gaEvent: (userId: string, timeOnPage: string, projectName?: string) => SendCustomEventProps;
  pageReference: 'MyPro' | 'ProOverview' | 'Map';
  projectName?: string;
};

const firingConditions = {
  MyPro: () => !window.location.href.endsWith('.uk/') && !window.location.href.endsWith('3000/'),
  ProOverview: () => !window.location.href.includes('/project') || window.location.href.includes('/map'),
  Map: () => !window.location.href.includes('/map'),
};

let startTime: string | null;
let globalEvent: (userId: string, timeOnPage: string, projectName?: string) => SendCustomEventProps;
let globalSendCustomEvent: (params: SendCustomEventProps) => void;
let globalName: string | undefined;
let globalUserId: string;

const sendTimeEvent = () => {
  if (startTime !== null) {
    const currentTime = new Date().getTime();
    const timeOnPage = (currentTime - parseInt(startTime)) / 1000; // Convert to seconds

    globalSendCustomEvent(globalEvent(globalUserId, timeOnPage.toString(), globalName ?? undefined));

    sessionStorage.removeItem('startTime');
  }
};

/**
 * Handles sending the event when the tab or window is closed.
 * Has to be global - if multiple instances are created the beforeunload fires
 * for each instance and sends many false PageView events. This is not
 * resolved by removeEventListener. In turn, the sendTimeEvent and all its
 * variables also need to be global.
 **/
window.addEventListener('beforeunload', () => {
  sendTimeEvent();
});

export const TimeOnPageCounter: React.FC<Props> = (props) => {
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  // The purpose of this useEffect is to track time spent on the page and send GA4 events on unload.
  useEffect(() => {
    // Check if there is a startTime value in sessionStorage
    startTime = sessionStorage.getItem('startTime');

    if (!startTime) {
      startTime = new Date().getTime().toString();
      sessionStorage.setItem('startTime', startTime);
    }

    globalSendCustomEvent = sendCustomEvent;
    globalUserId = getUserUuid(user);
    globalName = props.projectName;
    globalEvent = props.gaEvent;

    /**
     * In short, this cleanup runs when the AuthenticatedRoute component unmounts.
     * It will fire a GA4 event with the amount of time the user spent on the current page.
     * Putting the logic in the 'return () => {}' logic means it should only fire when the component is unmounted and thus only fire when the page is closed.
     * However, components are weird, and they seem to unload and reload themselves a couple of times when first created.
     * Thus the need for the firing conditions - we can inspect the URL to ensure these events don't fire if we are on the page relating to the component.
     **/
    return () => {
      if (firingConditions[props.pageReference]()) {
        sendTimeEvent();
      }
    };
  }, [props, sendCustomEvent, user]);

  return <div />;
};
