import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { AiFillWarning } from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import { ISearchDto, ProjectDto, SearchDto } from '../../api/LRPProxy';
import ParcelIcon from '../../assets/Search/ParcelIcon.svg';
import PartyIcon from '../../assets/Search/PartyIcon.svg';
import SupportEvidenceIcon from '../../assets/Search/SupportingEvidenceIcon.svg';
import styles from './Search.module.scss';
import { SearchAccordion } from './SearchAccordion';

type Props = {
  searchDetails: ISearchDto;
  project: ProjectDto;
  searchTerm: string | undefined;
  hasActiveAccordion?: boolean;
  setHasActiveAccordion?: (v: boolean) => void;
};
export const showInfo = (info: SearchDto[]) => {
  let data;
  if (info.length) {
    //I wrap the statement with (info.length) because it was complaining about info.length === 1 will be always false
    if (info.length >= 100) {
      data = ` > 100 results`;
    } else if (info.length) {
      if (info.length === 1) {
        data = ` 1 result`;
      } else {
        data = ` ${info.length} results`;
      }
    }
  } else {
    data = ` 0 results`;
  }

  return data;
};
const SearchResult: React.FC<Props> = ({ searchDetails, setHasActiveAccordion, project, searchTerm }) => {
  const { parties, parcels, supportingEvidences } = searchDetails;
  const [activeId, setActiveId] = useState<number | null>(null);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const toggleActive = (id: number) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  useEffect(() => {
    // When an accordion is engaged, this will set a max height on the search results container
    if (setHasActiveAccordion) setHasActiveAccordion(activeId !== null);
  }, [activeId, setHasActiveAccordion]);

  return (
    <Accordion data-testid='search-results-accordion' className={`${styles.AccordionWrapper}`}>
      {parties && (
        <Card className={styles.Accordion} aria-live='assertive' aria-atomic='false'>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='0'
            className={`${styles.AccordionToggle} ${parties.length === 0 && styles.grey} `}
            onClick={() => toggleActive(0)}
            data-id='1'
            data-testid='party toggle'
          >
            <div className='d-flex justify-content-center align-items-center'>
              <img src={PartyIcon} alt='icon' />
            </div>
            Party:<span className='ml-1'>{showInfo(parties)}</span>
            <button
              aria-label='show party results'
              className='ml-auto bg-transparent border-0'
              disabled={parties.length === 0}
            >
              <FiChevronDown fontSize='2.4rem' className={`${activeId === 0 && styles.Rotate} ml-auto`} />
            </button>
          </Accordion.Toggle>

          <Accordion.Collapse
            eventKey={parties.length ? '0' : '-1'}
            className={`${styles.AccordionBody} ${isSafari ? styles.isSafari : ''}`}
          >
            <Card.Body className='p-0'>
              {parties.length >= 100 && (
                <div className={styles.Warning}>
                  <AiFillWarning fontSize='2.5rem' />
                  <div>
                    <span>Showing only first 100 results.</span> <br></br>
                    Please refine your search term.
                  </div>
                </div>
              )}
              <SearchAccordion items={parties} section='parties' project={project} searchTerm={searchTerm} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}

      {parcels && (
        <Card className={styles.Accordion} aria-live='assertive' aria-atomic='false'>
          <Accordion.Toggle
            as={Card.Header}
            variant='link'
            eventKey='1'
            className={`${styles.AccordionToggle} ${parcels.length === 0 && styles.grey}`}
            onClick={() => toggleActive(1)}
            data-testid='parcel toggle'
          >
            <div className='d-flex justify-content-center align-items-center'>
              <img src={ParcelIcon} alt='icon' />
            </div>
            Parcel:
            <span className='ml-1'>{showInfo(parcels)}</span>
            <button
              aria-label='show parcel results'
              className='ml-auto bg-transparent border-0'
              disabled={parcels.length === 0}
            >
              <FiChevronDown fontSize='2.4rem' className={`${activeId === 1 && styles.Rotate} ml-auto`} />
            </button>
          </Accordion.Toggle>

          <Accordion.Collapse
            eventKey={parcels.length ? '1' : '-1'}
            className={`${styles.AccordionBody} ${isSafari ? styles.isSafari : ''}`}
          >
            <Card.Body className='p-0'>
              {parcels.length >= 100 && (
                <div className={styles.Warning}>
                  <AiFillWarning fontSize='2.5rem' />
                  <div>
                    <span>Showing only first 100 results.</span> <br></br>
                    Please refine your search term.
                  </div>
                </div>
              )}
              <SearchAccordion items={parcels} section='parcels' project={project} searchTerm={searchTerm} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}

      {supportingEvidences && (
        <Card className={styles.Accordion} aria-live='assertive' aria-atomic='false'>
          <Accordion.Toggle
            as={Card.Header}
            variant='link'
            eventKey='2'
            className={`${styles.AccordionToggle} ${supportingEvidences.length === 0 && styles.grey}`}
            onClick={() => toggleActive(2)}
            data-testid='supporting toggle'
          >
            <div className='d-flex justify-content-center align-items-center'>
              <img src={SupportEvidenceIcon} alt='icon' />
            </div>
            Supporting Evidence:
            <span className='ml-1'>{showInfo(supportingEvidences)}</span>
            <button
              aria-label='show supporting evidence results'
              className='ml-auto bg-transparent border-0'
              disabled={supportingEvidences.length === 0}
            >
              <FiChevronDown fontSize='2.4rem' className={`${activeId === 2 && styles.Rotate} ml-auto`} />
            </button>
          </Accordion.Toggle>

          <Accordion.Collapse
            eventKey={supportingEvidences.length ? '2' : '-1'}
            className={`${styles.AccordionBody} ${isSafari ? styles.isSafari : ''}`}
          >
            <Card.Body className='p-0'>
              {supportingEvidences.length >= 100 && (
                <div className={styles.Warning} data-testid='warning message'>
                  <AiFillWarning fontSize='2.5rem' />
                  <div>
                    <span>Showing only first 100 results.</span> <br></br>
                    Please refine your search term.
                  </div>
                </div>
              )}
              <SearchAccordion
                items={supportingEvidences}
                section='supportingEvidence'
                project={project}
                searchTerm={searchTerm}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
    </Accordion>
  );
};

export default SearchResult;
