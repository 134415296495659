import React, { useContext } from 'react';
import Alert from '../alert/Alert';
import { UseToastOptions } from './Toast.types';
import { ToastContext } from './ToastProvider';

const defaultOptions = {
  duration: 5000,
};

/**
 * React hook that returns a function that can be used to show toasts
 */
export function useToast() {
  const { notify, isVisible, updateToast, closeToast, closeAll } = useContext(ToastContext);

  const toast = (options: UseToastOptions) => {
    const opts = {
      ...defaultOptions,
      // This can be modified later to allow custom components or replaced for use in another codebase,
      //  with a different design
      message: (props: any) => <Alert isClosable {...props} {...options} />,
      ...options,
    };

    return notify?.(opts);
  };

  toast.close = closeToast;
  toast.closeAll = closeAll;
  toast.isVisible = isVisible;

  toast.updateToast = (id: string, options: Omit<UseToastOptions, 'id'>) => {
    if (!id) return;

    const opts = {
      ...defaultOptions,
      message: (props: any) => <Alert {...props} {...options} />,
      ...options,
    };

    updateToast?.(id, opts);
  };

  return toast;
}

export default useToast;
