import { SystemCssProperties } from '@styled-system/css';
import React, { FC, useEffect, useState } from 'react';
import Box from './ui/Box';
import { BoxProps } from './ui/types';

let warned = false;

const Box100vh: FC<BoxProps> = ({ sx = {}, height, ...props }) => {
  const trueHeight = use100vh();

  if (!warned && ((sx as SystemCssProperties).height || height || props.style?.height)) {
    warned = true;
    console.warn('<Box100vh /> overrides the height property of the sx prop');
  }
  const sxWithTrueHeight = {
    ...sx,
    height: trueHeight ? `${trueHeight}px` : '100vh',
  };
  return <Box sx={sxWithTrueHeight} {...props} />;
};

export const use100vh = (): number | null => {
  const [height, setHeight] = useState<number | null>(measureHeight);

  useEffect(() => {
    const setMeasuredHeight = () => {
      const measuredHeight = measureHeight();
      setHeight(measuredHeight);
    };

    window.addEventListener('resize', setMeasuredHeight);
    return () => window.removeEventListener('resize', setMeasuredHeight);
  }, []);

  return height;
};

const measureHeight = (): number | null => document.documentElement?.clientHeight || window.innerHeight;

export default Box100vh;
