import { Box as Div, Divider, VStack } from '@tq/ui-components';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { PartyListDto } from '../../api/LRPProxy';
import Box from '../../components/ui/Box';
import Flex from '../../components/ui/Flex';
import Heading from '../../components/ui/Heading';
import Text from '../../components/ui/Text';
import { convertMetresSquaredToHectares } from '../../utils/areaConverter';
import { chartColors, chartOptions } from '../../utils/charts';
import { Card, CardBody, CardHeader } from './ProjectOverview.styles';

type Props = {
  parties: PartyListDto[] | undefined;
  count: number | undefined;
};
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const FreeholdPartiesCard: React.FC<Props> = ({ parties, count }) => {
  const colors = useMemo(() => {
    return chartColors;
  }, []);

  const partiesData = useMemo(() => {
    return {
      labels: [''],
      datasets: parties
        ? parties.map((pa, i) => {
            return {
              label: pa.principalName,

              backgroundColor: colors[i],

              data: [convertMetresSquaredToHectares(pa.area)],

              borderRadius: 5,
            };
          })
        : [],
    };
  }, [colors, parties]);

  return (
    <Card sx={{ bg: 'background.main', gridColumn: ['span 6', 'span 2'], padding: '0 !important' }} padding={0}>
      <CardHeader>
        <Flex justifyContent='space-between'>
          <Heading color='white' fontWeight='600'>
            Freehold Parties
          </Heading>
          <Heading color='white'>{count}</Heading>
        </Flex>
      </CardHeader>
      <CardBody height='100%'>
        {parties && parties.length > 0 ? (
          <>
            <Box px='1.6rem' width='80%' marginX='auto' paddingTop='1.6rem'>
              <Heading as='h3' color='#454545' textAlign='center' fontSize='1.3rem' fontWeight='600'>
                Parties by total size
              </Heading>
              <Bar options={chartOptions} data={partiesData} aria-label='Freehold Parties Chart' role='img' />
            </Box>
            <Box my={4}>
              <Divider sx={{ border: '1px solid #d8e5ee' }} />

              <VStack
                spacing={8}
                width='100%'
                alignItems='stretch'
                divider={<Divider sx={{ border: '1px solid #d8e5ee' }} />}
              >
                {parties?.map((pr, i) => (
                  <Flex justifyContent='space-between' px={4} key={pr.id}>
                    <Flex alignItems='center'>
                      <Div borderRadius='50%' backgroundColor={colors[i]} width={8} height={8} mr={14}></Div>
                      <Text
                        fontSize='1.3rem'
                        color='#454545'
                        fontWeight='light'
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap' }}
                        title={pr.principalName}
                        width={['unset', '18rem']}
                      >
                        {pr.principalName}
                      </Text>
                    </Flex>
                    <Text fontSize='1.3rem' color='#454545' fontWeight='600'>
                      {convertMetresSquaredToHectares(pr.area)}ha
                    </Text>
                  </Flex>
                ))}
              </VStack>
            </Box>
          </>
        ) : (
          <Flex height='100%' justifyContent='center' alignItems='center'>
            <Heading>No Data Found</Heading>
          </Flex>
        )}
      </CardBody>
    </Card>
  );
};

export default FreeholdPartiesCard;
