import React, { forwardRef, ForwardRefExoticComponent } from 'react';
import Box from './Box';
import { BoxProps } from './types';

const px = (n: any) => (typeof n === 'number' ? n + 'px' : n);

const widthToColumns = (width: any): any =>
  Array.isArray(width) ? width.map(widthToColumns) : !!width && `repeat(auto-fit, minmax(${px(width)}, 1fr))`;

const countToColumns = (n: any): any => (Array.isArray(n) ? n.map(countToColumns) : !!n && `repeat(${n}, 1fr)`);

const Tiles: ForwardRefExoticComponent<
  BoxProps & { columns?: number | number[]; gap?: number | number[] }
> = forwardRef(({ width, columns, gap = 3, ...props }, ref) => {
  const gridTemplateColumns = width ? widthToColumns(width) : countToColumns(columns);
  return (
    <Box
      ref={ref}
      tx='layout'
      variant='tiles'
      {...props}
      sx={{
        display: 'grid',
        gridGap: gap,
        gridTemplateColumns,
        ...props.sx,
      }}
    />
  );
});

Tiles.displayName = 'Tiles';

export default Tiles;
