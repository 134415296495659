const palette = {
  lightBlueGrey: {
    50: '#e4eef5',
    80: 'rgb(183,210,229,.8)',
    100: '#c5dbea',
  },
  warmGrey: {
    10: '#f0f1f2',
    100: '#707070',
  },
  navy: '#06203d',
};

const baseTheme = {
  colors: {
    ...palette,
    background: {
      main: '#ffffff',
      sub: '#fafbfc',
      card: '#f7f7f7',
    },
    text: {
      body: '#454545',
      heading: '#2c405a',
      link: '#2b72ca',
      active: '#2b72ca',
    },
  },
  sizes: {
    container: '1140px',
  },
  space: [
    0,
    '.5rem',
    '1.0rem',
    '1.5rem',
    '2.0rem',
    '2.5rem',
    '3.0rem',
    '3.5rem',
    '4.0rem',
    '4.5rem',
    '5.0rem',
    '5.5rem',
    '6.0rem',
    '6.5rem',
    '7.0rem',
    '7.5rem',
    '8.0rem',
    '8.5rem',
    '9.0rem',
    '9.5rem',
    '10rem',
  ],
  breakpoints: ['769px', '993px', '1201px', '1601px'],
};

const theme = {
  ...baseTheme,
  layout: {
    container: {
      maxWidth: baseTheme.sizes.container,
      px: 4,
    },
  },
  buttons: {
    secondary: {
      background: baseTheme.colors.lightBlueGrey[50],
      color: baseTheme.colors.navy,
      border: `solid 1px ${baseTheme.colors.lightBlueGrey[100]}`,
      padding: '0 1.5rem',
      borderRadius: '.4rem',
    },
  },
  text: {
    h1: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: ['2.4rem', '3.4rem'],
      color: 'text.heading',
      fontWeight: 600,
    },
    h3: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: '2.6rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.7rem',
      color: 'text.heading',
    },
    body: {
      fontSize: '1.6rem',
      color: 'text.body',
    },
    link: {
      fontSize: '1.6rem',
      color: 'text.link',
    },
  },
};

export default theme;
