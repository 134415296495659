import themedCss from '@styled-system/css';
import { default as React, forwardRef } from 'react';
import { BsCaretDownFill } from 'react-icons/bs';
import { FiChevronRight } from 'react-icons/fi';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Box from '../../components/ui/Box';
import Image from '../../components/ui/Image';
import { BoxProps } from '../../components/ui/types';

// Any because I can't figure out how to use compound components with SC
export const HelpContentLayout: any = styled.section`
  flex-grow: 1;
  max-width: 112rem;
`;

export const CardImageContainer = styled(Box)`
  margin-top: auto;
  position: relative;
`;

// eslint-disable-next-line react/display-name
HelpContentLayout.Header = (props: BoxProps) => (
  <Box px='1.6rem'>
    <Box pt={[0, 9]} pb={4} {...props} />
  </Box>
);

export const NavLink = styled(ReactRouterLink)<{
  $isActive: boolean;
  $isSubRoute?: boolean;
}>`
  display: flex;
  border-bottom: 1px solid #eeeeee;
  align-items: center;

  &:first-child {
    ${themedCss({
      borderTop: ['1px solid #eeeeee', 'unset'],
    })}
  }

  ${({ $isActive, $isSubRoute }) => css`
    ${themedCss({
      pl: $isSubRoute ? ['3.6rem'] : ['2.7rem', '2'],
      pr: ['1.8rem', '3'],
      py: ['1.2rem', '3'],
      fontWeight: [null, $isSubRoute ? null : 600],
    })}

    span {
      ${themedCss({
        color: $isActive ? 'text.active' : 'text.body',
      })}
    }

    &:hover {
      text-decoration: none;

      span {
        ${themedCss({
          color: 'text.active',
        })}
      }
    }
  `};
`;

export const DesktopNav = styled((props) => <Box as='nav' {...props} />)`
  flex: 0 0 31.2rem;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  border-right: 1px solid #eeeeee;

  ${themedCss({
    bg: 'background.main',
    pl: 4,
    pt: 9,
    display: ['none', 'block'],
  })}
`;

export const MobileNav = styled((props) => <Box as='nav' {...props} />)`
  ${themedCss({
    px: '1.6rem',
    py: 5,
    display: ['block', 'none'],
  })}
`;

export const Content = styled(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement, BoxProps>((props, ref) => <Box ref={ref} {...props} />)
)`
  flex-grow: 1;
  justify-content: center;
`;

export const Card = styled(Box)`
  display:flex;
  flex-direction: column;
  border: 0.1rem solid #d8e5ee;
  border-radius: 0.4rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  padding: 1.8rem 2rem;

  &:hover {
    border: 0.1rem solid #000000;
    text-decoration: none;
  }
  border
`;

export const CardImageOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0c2d57;
  border-radius: 0.4rem;
  opacity: 0.79;
`;

export const CardImageOverlayIcon = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: translate(-50%, -50%);
  width: 6.4rem;
  height: 6.4rem;
`;

export const CardImage = styled(Image)`
  width: 100%;
  border-radius: 0.4rem;
  position: relative;
`;

export const ChevronRightIcon = styled(FiChevronRight)`
  margin-left: auto;
  width: 16px;
  height: 16px;
  flex: 0 0 1.6rem;
`;

export const MobileNavToggle = styled.button`
  display: flex;
  color: #11203d;
  padding: 1.1rem 1.6rem;
  font-weight: 600;
  line-height: 1;
  border-radius: 4px;
  background-color: #e4eef5;
  text-align: left;
  font-size: 1.6rem;
  width: 100%;

  ${themedCss({
    py: '1.4rem',
    pl: 2,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'lightBlueGrey.100',
  })}
`;

export const CaretDownIcon = styled(BsCaretDownFill)`
  margin-left: auto;
`;
