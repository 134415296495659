import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { getUserUuid } from '../../utils/gaUtils';
import Button from '../../components/Button';
import Heading from '../../components/ui/Heading';
import { useTagManager } from '../../components/analytics';
import { getProjectOverviewViewMap } from '../../components/analytics/constants';
import { useProjectThumbnailSrc } from '../../hooks/useProjectThumbnailSrc';
import { getProjectMapUrl } from '../../utils/router';
import { Card, CardBody, CardHeader } from './ProjectOverview.styles';
import { useGetProjectOverview } from '../../hooks/queries/useGetProjectOverview';

const ProjectMapCard = () => {
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const { data } = useGetProjectOverview({ projectId });
  const src = useProjectThumbnailSrc({ projectId });
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  return (
    <Card sx={{ gridColumn: ['span 6', 'span 3'] }}>
      <CardHeader>
        <Heading color='white' fontWeight='600'>
          Project Map
        </Heading>
      </CardHeader>
      <CardBody
        sx={{
          display: 'flex',
          flex: '1 0 auto',
          backgroundImage: `url(${src})`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          pb: 5,
          px: 4,
        }}
      >
        <Button
          title='Go to Project Map'
          aria-label='Go to Project Map'
          onClick={() => {
            sendCustomEvent(getProjectOverviewViewMap(getUserUuid(user), data?.result.project));
            history.push(getProjectMapUrl(projectId));
          }}
        >
          View Project Map
        </Button>
      </CardBody>
    </Card>
  );
};

export default ProjectMapCard;
