import styled from 'styled-components';

const TitleBar = styled.div<{ variant?: string }>`
  margin-bottom: 1rem;
  position: sticky;
  position: -webkit-sticky;
  padding: 1.5rem 2rem;
  font-weight: bold;
  top: -1.7rem;
  z-index: 100;

  ${({ variant }) =>
    variant === 'blue'
      ? `
    border: solid 0.1rem #c5dbea;
    background-color: #e4eef5;
    color: #0c203d;
    `
      : ''}
`;

export default TitleBar;
