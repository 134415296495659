// This to be refactored so that users

import React, { ReactNode } from 'react';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import styles from './Card.module.scss';

// This to be refactored to use compound components so that we dont need to pass 'hasTwoButtons' prop

type CardType = {
  title?: string;
  icon?: string;
  children?: ReactNode;
  border?: string;
  hasTwoButtons?: boolean;
  footerContent?: ReactNode;
};

const GeneralCard = (props: CardType) => {
  return (
    <Card className={`${styles.cardContainer} ${props.border && styles[props.border]}`}>
      {props.title && (
        <Card.Header className={styles.cardTitle}>
          <img src={props.icon} alt='icon' className={styles.icon} />
          <span data-testid='title'>{props.title}</span>
        </Card.Header>
      )}

      <Card.Body className={styles.cardBody}>
        <ListGroup className='list-group-flush'>{props.children}</ListGroup>
      </Card.Body>

      {props.footerContent && (
        <Card.Footer className={`${styles.buttonsContainer} ${props.hasTwoButtons ? styles.hasTwoButtons : ''}`}>
          {props.footerContent}
        </Card.Footer>
      )}
    </Card>
  );
};

type EntryType = {
  label?: string;
  text?: string | number;
  children?: React.ReactNode;
};

const CardEntry = (props: EntryType) => {
  return (
    <ListGroupItem className={styles.listItem}>
      <div className={`${props.children ? 'd-flex justify-content-between align-items-center' : ''}`}>
        <div>
          <span className={`${styles.listLabel} mr-1`}>{props.label}:</span>
          <span> {props.text}</span>
        </div>
        {props.children}
      </div>
    </ListGroupItem>
  );
};

GeneralCard.Entry = CardEntry;

export default GeneralCard;
