import React, { useState } from 'react';
import styles from './PopOver.module.scss';
import { Overlay, Popover } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { IAddressDetails } from './Modal';

interface RefObject {
  current: any;
}

interface IProps extends IAddressDetails {
  containerRef?: RefObject;
}

const PopoverComp: React.FC<IProps> = (props) => {
  const {
    containerRef,
    partyAddresses: { title, addresses },
  } = props;
  const [entered, setEntered] = useState(false);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShow(!show);
    setTarget(event.currentTarget);
  };

  return (
    <>
      <button className={styles.TooltipInfo} data-testid='popover-info' onClick={handleClick}>
        Info
      </button>

      <Overlay
        show={show}
        target={target}
        placement='right'
        container={containerRef && containerRef.current}
        containerPadding={132}
        onEntered={() => setEntered(true)}
        rootClose
        onHide={() => {
          if (entered) {
            setShow(false);
            setEntered(false);
          }
        }}
        popperConfig={{
          modifiers: [
            {
              name: 'preventOverflow',
            },
          ],
        }}
      >
        <Popover id='popover-contained-addresses' className={styles.popoverBlockContainer}>
          <Popover.Title className={styles.titleContainer}>
            <span className={styles.title}>{title}</span>
            <button className={styles.button} onClick={handleClick} aria-label='close info panel'>
              <IoMdClose fontSize='2rem' />
            </button>
          </Popover.Title>
          <Popover.Content bsPrefix='popover-body' className={`scroller ${styles.popover_content}`} tabIndex={0}>
            <div data-testid='popover-party-detail' className={styles.popoverContainer}>
              <div className={styles.popoverCurrentAddressesContainer}>
                <span className={styles.addressTitles}>Current Addresses:</span>
                <div className={styles.popoverAddressesContainer} data-testid='current addresses'>
                  {addresses.currentAddresses?.length ? (
                    addresses.currentAddresses?.map((address, index) => {
                      return (
                        <div
                          key={`${index}_${address.substring(0, 5)}`}
                          className={styles.popoverAddressSection}
                          data-testid='current address'
                        >
                          {address}
                        </div>
                      );
                    })
                  ) : (
                    <span className={styles.popoverAddressSection}>No records held.</span>
                  )}
                </div>
              </div>
              <div className={styles.popoverHistoricalAddressesContainer}>
                <span className={styles.addressTitles}>Historical Addresses:</span>
                <div className={styles.popoverAddressesContainer}>
                  {addresses.historicalAddresses?.length ? (
                    addresses.historicalAddresses?.map((address, index) => {
                      return (
                        <div key={`${index}_${address.substring(0, 5)}`} className={styles.popoverAddressSection}>
                          {address}
                        </div>
                      );
                    })
                  ) : (
                    <span className={styles.popoverAddressSection}>No records held.</span>
                  )}
                </div>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

export default PopoverComp;
