import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { thunkGetProjects } from '../store/projectsList/thunks';

export const AuthWrapper: React.FC = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const isLoginInProgress = inProgress !== 'none';

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(thunkGetProjects());
    }
  }, [dispatch, isAuthenticated, isLoginInProgress]);

  useEffect(() => {
    if (!isAuthenticated && !isLoginInProgress) {
      instance.loginRedirect();
    }
  }, [instance, isAuthenticated, isLoginInProgress, instance.loginRedirect]);

  if (isAuthenticated) {
    return <Fragment>{props.children}</Fragment>;
  }

  return <Fragment />;
};
