import React, { createContext, FC, useContext, useState } from 'react';
import Box from '../../components/ui/Box';

type TAccordionContext = {
  id?: string;
  isExpanded?: boolean;
  toggle?: () => void;
};

const AccordionContext = createContext<TAccordionContext>({});
const Accordion: any = (props: any) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggle = () => setIsExpanded((prev) => !prev);

  return (
    <AccordionContext.Provider value={{ id: props.id, isExpanded, toggle }}>
      <Box {...props} />
    </AccordionContext.Provider>
  );
};

const Header: FC = (props) => {
  const context = useContext(AccordionContext);

  if (!context) {
    console.error('Header needs to be wrapped within an Accordion.');
    return null;
  }

  const { id, isExpanded, toggle } = context;

  return (
    <Box
      as='button'
      id={`${id}-header`}
      aria-expanded={isExpanded}
      onClick={toggle}
      aria-controls={`${id}-panel`}
      {...props}
    />
  );
};

const Panel: FC = (props) => {
  const context = useContext(AccordionContext);

  if (!context) {
    console.error('Panel needs to be wrapped within an Accordion.');
    return null;
  }

  const { id, isExpanded } = context;

  return <Box id={`${id}-panel`} hidden={!isExpanded} aria-labelledby={id} {...props} />;
};

Accordion.Header = Header;
Accordion.Panel = Panel;

export default Accordion;
