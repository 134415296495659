import styled, { css } from 'styled-components';

export const CloseButton = styled.button`
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: -0.8rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  border: none;
`;

const getContainerVariantStyles = ($status: string) => {
  switch ($status) {
    case `danger`:
    default:
      return css`
        background-color: #f2e5e5;
        border-color: #8e443e;
        color: #8e443e;
      `;
    case `success`:
      return css`
        background-color: #e2eed8;
        border-color: #4c723e;
        color: #4c723e;
      `;
    case `warning`:
      return css`
        background-color: #fcf7e4;
        border-color: #b14e0c;
        color: #b14e0c;
      `;
    case `info`:
      return css`
        background-color: #dcedf6;
        border-color: #346480;
        color: #346480;
      `;
  }
};

export const Container = styled.div<{ $status: string }>`
  display: flex;
  width: 100%;
  ${({ $status }) => getContainerVariantStyles($status)}

  border-width: 0.1rem;
  border-style: solid;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  margin: 0 auto;
  padding: 1.5rem 2.5rem;
  align-items: center;
  position: relative;
`;

export const Title = styled.h3`
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
`;
