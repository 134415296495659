import { ProjectsDto } from '../../../api/LRPProxy';
import { SendCustomEventProps } from '../TagManagerProvider';

const GA_PROJECT_OVERVIEW_PAGE = {
  gaCategory: 'Project Overview Page',
  gaLabel: 'Project Overview Page',
};

const GA_PROJECT_OVERVIEW_PAGE_PAGEVIEW: SendCustomEventProps = {
  ...GA_PROJECT_OVERVIEW_PAGE,
  gaAction: 'ProOverview - Pageview',
};

const GA_PROJECT_OVERVIEW_PAGE_DOWNLOAD_DOCS: SendCustomEventProps = {
  ...GA_PROJECT_OVERVIEW_PAGE,
  gaAction: 'ProOverview - Download Docs',
};

const GA_PROJECT_OVERVIEW_PAGE_SELECT_MILESTONE: SendCustomEventProps = {
  ...GA_PROJECT_OVERVIEW_PAGE,
  gaAction: 'ProOverview - Milestones',
};

const GA_PROJECT_OVERVIEW_PAGE_VIEW_PROJECT_MAP: SendCustomEventProps = {
  ...GA_PROJECT_OVERVIEW_PAGE,
  gaAction: 'ProOverview - View Pro Map',
};

export const getProjectOverviewPageview = (
  userId: string,
  timeOnPage: string,
  projectName?: string
): SendCustomEventProps => {
  return {
    ...GA_PROJECT_OVERVIEW_PAGE_PAGEVIEW,
    gaUserId: userId,
    gaProjectName: projectName,
    gaTimeOnPage: timeOnPage,
  };
};

export const getProjectOverviewDownloadDocs = (userId: string, project?: ProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_OVERVIEW_PAGE_DOWNLOAD_DOCS,
    gaUserId: userId,
    gaProjectName: project?.name,
  };
};

export const getProjectOverviewSelectMilestone = (userId: string, project?: ProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_OVERVIEW_PAGE_SELECT_MILESTONE,
    gaUserId: userId,
    gaProjectName: project?.name,
  };
};

export const getProjectOverviewViewMap = (userId: string, project?: ProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_OVERVIEW_PAGE_VIEW_PROJECT_MAP,
    gaUserId: userId,
    gaProjectName: project?.name,
  };
};
