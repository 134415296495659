import { PartyDto, AddressDto } from '../api/LRPProxy';
import { IAddressType } from '../routes/project/map/party/Party';

interface IAddressLines {
  addressLine1?: string | undefined;
  addressLine2?: string | undefined;
  addressLine3?: string | undefined;
  addressLine4?: string | undefined;
  addressLine5?: string | undefined;
  addressLine6?: string | undefined;
  postalTown?: string | undefined;
  postCode?: string | undefined;
  country?: string | undefined;
  type?: string | undefined;
  status?: string | undefined;
}

const fetchAddress = (address: IAddressLines) => {
  const {
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    addressLine5,
    addressLine6,
    postalTown,
    postCode,
    country,
  } = address;
  const returnAddress =
    (addressLine1 ? addressLine1 : '') +
    (addressLine2 ? ', ' + addressLine2 : '') +
    (addressLine3 ? ', ' + addressLine3 : '') +
    (addressLine4 ? ', ' + addressLine4 : '') +
    (addressLine5 ? ', ' + addressLine5 : '') +
    (addressLine6 ? ', ' + addressLine6 : '');

  if (returnAddress) {
    return (
      returnAddress +
      (postalTown ? ', ' + postalTown : '') +
      (postCode ? ', ' + postCode : '') +
      (country ? ', ' + country : '')
    );
  }
};

const fetchAddresses = (addressArray: AddressDto[] | undefined) => {
  const returnAddressesArray: string[] = [];
  if (!addressArray?.length) {
    return [];
  }

  addressArray.forEach((address) => {
    const fetchedAddress = fetchAddress(address);
    if (fetchedAddress) {
      returnAddressesArray.push(fetchedAddress);
    }
  });

  return returnAddressesArray;
};

const fetchCurrentAddressPrimary = (addressArray: AddressDto[] | undefined, showInfo: boolean) => {
  let currentAddressPrimary;
  const currentAddressPrimaryArray = addressArray?.filter((address) => {
    return address?.type === 'Primary';
  });

  if (currentAddressPrimaryArray?.length) {
    currentAddressPrimary = fetchAddress(currentAddressPrimaryArray[0]);
  } else {
    if (showInfo) {
      currentAddressPrimary = 'No primary addresses on record. Click info to view other addresses';
    } else {
      currentAddressPrimary = 'No primary addresses on record.';
    }
  }

  return currentAddressPrimary;
};

export const addressFormatter = (state: PartyDto, showInfo: boolean) => {
  const { addresses } = state;

  const historicalAddressArray = addresses?.filter((address) => {
    return address.status === 'Historic';
  });

  const currentAddressArray = addresses
    ?.sort((a, b) => {
      if (!a.type) return 1;
      if (!b.type) return -1;
      return b?.type > a?.type ? 1 : -1;
    })
    ?.filter((address) => {
      return address.status === 'Current';
    });

  const currentAddressPrimary = fetchCurrentAddressPrimary(currentAddressArray, showInfo);
  const currentAddressesArray = fetchAddresses(currentAddressArray);
  const historicalAddressesArray = fetchAddresses(historicalAddressArray);

  const addressData: IAddressType = {
    count: currentAddressesArray?.length + historicalAddressesArray?.length,
    currentAddresses: currentAddressesArray,
    historicalAddresses: historicalAddressesArray,
    currentAddressPrimary: currentAddressPrimary,
  };

  return addressData;
};
