import { useMemo } from 'react';

import { useGetClientLogo } from './queries/useGetClientLogoQuery';

type Props = { projectId: string };

export const useProjectClientLogoSrc = ({ projectId }: Props): string => {
  const { data: blob } = useGetClientLogo({ projectId });

  return useMemo(() => (blob ? URL.createObjectURL(blob.result.data) : ''), [blob]);
};
