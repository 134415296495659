import React, { ComponentPropsWithoutRef, FC } from 'react';
import styled from 'styled-components';

const StyledButton = styled(({ className, ...props }) => (
  <button className={`${className} btn-secondary btn`} {...props} />
))`
  font-size: 1.4rem;
  font-weight: bold;
  padding: 1.4rem 1.3rem;

  ${({ $fullWidth }) =>
    $fullWidth
      ? `
      width: 100%;
      text-align: center;
  `
      : ``}

  ${({ $outline }) =>
    $outline
      ? `
      border: solid 1px #3980d6;
      background-color: white;
      color: #3980d6;
      outline-color: #3980d6;

      &:hover {
        background-color: #3980d6;
        color: white;
      }

      &:focus {
        background-color: #3980d6;
        color: white;
      }
  `
      : ``}
`;

type Props = ComponentPropsWithoutRef<'div'> & { className?: string; fullWidth?: boolean; outline?: boolean };

const Button: FC<Props> = ({ fullWidth, className, outline, ...props }) => (
  <StyledButton className={`${className} btn`} $outline={outline} $fullWidth={fullWidth} {...props} />
);

export default Button;
