import React, { forwardRef } from 'react';
import styled, { StyledComponentPropsWithRef } from 'styled-components';

const PanelHeader = styled(
  // eslint-disable-next-line react/display-name
  forwardRef<
    HTMLDivElement,
    StyledComponentPropsWithRef<'div'> & {
      $shouldHaveShadow?: boolean;
    }
  >((props, ref) => <div ref={ref} {...props} />)
)`
  display: flex;

  > div {
    flex: 1 1 auto;
  }
`;

export const ToggleButton = styled.button`
  position: absolute;
  width: 4rem;
  height: 5.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: #fafbfc;
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid #c5dbea;
  border-left: none;
  top: 2.4rem;
  right: -4rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  clip-path: inset(-6px -6px -6px 0px);

  @media (max-width: 768px) {
    right: 0;
    border-right: none;
    border-left: 1px solid #c5dbea;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    clip-path: unset;

    &[aria-expanded='false'] {
      right: -4rem;
      border-left: none;
      border-right: 1px solid #c5dbea;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

export const ClickableGoBackHeader = styled(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement, StyledComponentPropsWithRef<typeof PanelHeader>>((props, ref) => (
    <PanelHeader ref={ref} role='button' tabIndex={0} {...props} />
  ))
)`
  display: flex;
  background: #fff;
  height: 7.6rem;
  padding: 0 2rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid #c5dbea;

  @media (max-width: 768px) {
    margin-right: 5rem;
    border-bottom-right-radius: 4px;
  }
`;

export const SearchHeader = styled(PanelHeader)`
  display: flex;
  min-height: 9.3rem;

  padding: 2.4rem 2rem 0;

  @media (max-width: 768px) {
    padding-right: 5rem;
  }

  ${({ $shouldHaveShadow }) => ($shouldHaveShadow === false ? 'box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);' : ``)};
`;

export const GoBackHeaderContent = styled.div`
  display: flex;
  font-size: 1.6rem;
  align-items: center;
  width: 100%;

  > span {
    display: flex;
    align-items: center;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;

    > b {
      svg {
        margin-top: 0.2rem;
      }

      display: inline-flex;
      align-items: center;
      font-weight: 600;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export const PreviousRouteLabel = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Content = styled(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement, StyledComponentPropsWithRef<'div'> & { $shouldHavePaddingTop: boolean }>((props, ref) => (
    <div ref={ref} {...props} />
  ))
)`
  outline: none;
  padding-top: ${({ $shouldHavePaddingTop }) => ($shouldHavePaddingTop ? '1.5rem' : '0')};
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2.2rem;
  overflow-y: auto;

  > div:not(:first-of-type) {
    margin-top: 1.5rem;
  }

  @media (max-width: 768px) {
    padding-bottom: 1.6rem;

    > div {
      min-width: unset;
    }
  }
`;

export const Container = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: #fafbfc;
  width: 45.3rem;
  box-sizing: border-box;
  z-index: 4;
  outline: none;
  transition: margin-left 0.15s ease-in-out;

  &.information-panel-animate-enter {
    margin-left: -45.3rem;
  }
  &.information-panel-animate-enter-active {
    margin-left: 0;
  }
  &.information-panel-animate-exit {
    margin-left: -45.3rem;
  }
  &.information-panel-animate-exit-done {
    margin-left: -45.3rem;
  }

  @media (max-width: 768px) {
    width: 100%;

    &.information-panel-animate-enter {
      margin-left: -100%;
      transition: margin-left 0.15s ease-in-out;
    }
    &.information-panel-animate-enter-active {
      margin-left: 0;
    }
    &.information-panel-animate-exit {
      margin-left: -100%;
      transition: margin-left 0.15s ease-in-out;
    }
    &.information-panel-animate-exit-done {
      margin-left: -100%;
    }
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: auto;
  width: 2.8rem;

  &:hover {
    cursor: pointer;
  }
`;
