import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PartyDto } from '../../../../api/LRPProxy';
import PartyDetails from './PartyDetails';
import { useGetParty } from '../../../../hooks/queries/useGetParty';
import { partyNameFormat } from '../../../../utils/PartyNameFormatterUtils';
import LoadingSpinner from '../../../../components/loading-spinner/LoadingSpinner';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getLandParcelUrl } from '../../../../utils/router';
import { PartyAssociatedLandParcel } from './PartyAssociatedLandParcels';
import { ProjectLandParcelParams } from '../../land-parcel-detail/hooks/useLandParcelDetailLogic';
import { WithRequired } from '../../../../types';
import { useProjectMapDetailContext } from '../../context/project-map-detail-context';
import { addressFormatter } from '../../../../utils/AddressFormatterUtils';

export interface IAddressType {
  count: number | undefined;
  currentAddresses: string[] | undefined;
  historicalAddresses: string[] | undefined;
  currentAddressPrimary: string | undefined;
}

export type Pagination = {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPageLink: null;
  currentPageLink: null;
};

const getReferenceId = (state: PartyDto) => {
  const { reference } = state;
  return reference + '';
};

const PartyInformationPanel: React.FC = () => {
  const {
    closeInfoPanel,
    projectDetail,
    projectLandParcelParams,
    isInfoPanelOpen,
    handleFitAndSelectFeatureById,
    handleFitAndHighlightFeatureById,
  } = useProjectMapDetailContext();
  const { projectId, partyId } = projectLandParcelParams as WithRequired<ProjectLandParcelParams, 'partyId'>;

  const { data: partyData, status: partyStatus } = useGetParty({ projectId, id: partyId });
  const history = useHistory();
  const isMobile = useIsMobile();

  const locateParcel = useCallback(
    (id) => {
      if (id) {
        handleFitAndHighlightFeatureById(id, isInfoPanelOpen);
        isMobile && closeInfoPanel();
      }
    },
    [closeInfoPanel, handleFitAndHighlightFeatureById, isInfoPanelOpen, isMobile]
  );

  const viewLandParcelDetails = useCallback(
    (landParcelId, currentPartyName: string) => {
      if (landParcelId) {
        handleFitAndSelectFeatureById(landParcelId, isInfoPanelOpen);
        history.push({
          pathname: getLandParcelUrl(projectId, landParcelId),
          state: {
            previousRoute: {
              label: `Party: ${currentPartyName}`,
            },
          },
        });
      }
    },
    [handleFitAndSelectFeatureById, history, isInfoPanelOpen, projectId]
  );

  if (partyStatus === 'loading') return <LoadingSpinner />;
  if (!partyData?.result) return <div data-testid='error-message'>There was an error.</div>;

  const partyName = partyNameFormat(partyData.result);
  const referenceId = getReferenceId(partyData.result);
  const partyAddresses = addressFormatter(partyData.result, true);

  return (
    <>
      <PartyDetails
        projectId={projectId}
        referenceId={referenceId}
        partyId={partyId}
        partyName={partyName}
        partyAddresses={partyAddresses}
      />

      <PartyAssociatedLandParcel
        partyName={partyName}
        partyId={partyId}
        handleLocateParcel={locateParcel}
        handleViewParcelDetail={viewLandParcelDetails}
        project={projectDetail}
      />
    </>
  );
};

export default PartyInformationPanel;
