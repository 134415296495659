import { IProjectsDto } from '../../../api/LRPProxy';
import { SendCustomEventProps } from '../TagManagerProvider';

const GA_PROJECT_LIST_PAGE = {
  gaCategory: 'Project List Page',
  gaLabel: 'Project List Page',
};

const GA_PROJECT_LIST_PAGE_SELECT_PROJECT_CARD: SendCustomEventProps = {
  ...GA_PROJECT_LIST_PAGE,
  gaAction: 'MyPro - Select Pro Card',
};

const GA_PROJECT_LIST_PAGE_SELECT_PROJECT_PANEL: SendCustomEventProps = {
  ...GA_PROJECT_LIST_PAGE,
  gaAction: 'MyPro - Select Pro Panel',
};

const GA_PROJECT_LIST_PAGE_DOWNLOAD_DOCS: SendCustomEventProps = {
  ...GA_PROJECT_LIST_PAGE,
  gaAction: 'MyPro - Download Docs',
};

const GA_PROJECT_LIST_PAGE_VIEW_PROJECT_MAP: SendCustomEventProps = {
  ...GA_PROJECT_LIST_PAGE,
  gaAction: 'MyPro - View Pro Map',
};

const GA_PROJECT_LIST_PAGE_PAGEVIEW: SendCustomEventProps = {
  ...GA_PROJECT_LIST_PAGE,
  gaAction: 'MyPro - Pageview',
};

export const getSelectedProjectCardEvent = (userId: string, project: IProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_LIST_PAGE_SELECT_PROJECT_CARD,
    gaUserId: userId,
    gaProjectName: project.name,
  };
};

export const getSelectedProjectPanelEvent = (userId: string, project: IProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_LIST_PAGE_SELECT_PROJECT_PANEL,
    gaUserId: userId,
    gaProjectName: project.name,
  };
};

export const getProjectListPageview = (userId: string, timeOnPage: string): SendCustomEventProps => {
  return {
    ...GA_PROJECT_LIST_PAGE_PAGEVIEW,
    gaUserId: userId,
    gaTimeOnPage: timeOnPage,
  };
};

export const getMyProDownloadDocsEvent = (userId: string, projectName?: string): SendCustomEventProps => {
  return {
    ...GA_PROJECT_LIST_PAGE_DOWNLOAD_DOCS,
    gaUserId: userId,
    gaProjectName: projectName,
  };
};

export const getMyProViewMapEvent = (userId: string, projectName?: string): SendCustomEventProps => {
  return {
    ...GA_PROJECT_LIST_PAGE_VIEW_PROJECT_MAP,
    gaUserId: userId,
    gaProjectName: projectName,
  };
};
