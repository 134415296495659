import themedCss from '@styled-system/css';
import styled from 'styled-components';
import Box100vh from './Box100vh';

export const Main = styled.main`
  display: flex;
  flex-grow: 1;
  grid-area: main;
  position: relative;
  overflow: hidden;
`;

export const LayoutContainer = styled(Box100vh)`
  display: grid;
  grid-template-columns: 6.2rem 1fr;
  grid-template-rows: 9rem 1fr 4rem;
  overflow: hidden;

  ${themedCss({
    gridTemplateAreas: [
      `'header header'
      'main main'
      'footer footer'`,
      null,
      `'header header'
      'nav main'
      'footer footer'`,
    ],
  })}
`;
