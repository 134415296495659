import React from 'react';
import { Link } from 'react-router-dom';
import Box from '../components/ui/Box';
import Heading from '../components/ui/Heading';

const NotFound = () => {
  return (
    <>
      <Box display='grid' width='100%' height='100%' sx={{ placeItems: 'center', gap: '2rem' }}>
        <Heading alignSelf='end' as='h1'>
          We are sorry, Page not found.
        </Heading>

        <Link
          to='/'
          style={{
            backgroundColor: '#06203d',
            color: 'white',
            alignSelf: 'start',
            paddingInline: '3rem',
            paddingBlock: '1rem',
            borderRadius: '2rem',
          }}
        >
          My projects
        </Link>
      </Box>
    </>
  );
};

export default NotFound;
