import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { client } from '../../api';
import { FileResponse, SwaggerResponse } from '../../api/LRPProxy';
import { QueryError } from './types';

type TResult = SwaggerResponse<FileResponse>;
type Variables = {
  projectId: string;
};

type Options = UseQueryOptions<TResult, QueryError>;

export const useGetClientLogoOptions = () => {
  return useCallback(
    ({ projectId }: Variables, options: Options = {}) => ({
      queryKey: ['client-logo', { projectId }],
      queryFn: () => client.downloadClientLogo(projectId),
      retry: false,
      ...options,
    }),
    []
  );
};

export const useGetClientLogo = (variables: Variables, options?: Options) => {
  const getClientLogo = useGetClientLogoOptions();
  return useQuery<TResult, QueryError>(getClientLogo(variables, options));
};
