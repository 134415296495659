import { EventType, AccountInfo, EventMessage } from '@azure/msal-browser';
import { GA_LOGIN_PAGE_LRP_USER_LOGIN, GA_LOGIN_PAGE_LRP_USER_LOGOUT } from '../components/analytics/constants';

// Regular expression to extract the domain of an email address.
const regex = /(?<=@)([\w-]+)(\.[\w-]+)+/;
let globalUuid = '';

/**
 * Some context surrounding UUIDs:
 * Sending just the User ID to GA is not very user-friendly.
 * But, we cannot send the User Names or Emails as we do not want to give this data to Google.
 * We therefore create UUIDs, a blend of User ID and the domain part of the user's email, i.e. '[EmailDomain] ([UserID])'.
 * This gives GA users access to enough helpful data without giving away client-sensitive details.
 */
export const getUserUuid = (activeAccount: AccountInfo | null): string => {
  // True on logout events or prior to logging in - will be empty string ('') if prior to logging in.
  if (!activeAccount) return globalUuid;

  // Obtain user's email address and ID
  const userId = activeAccount.localAccountId;
  const userEmail = activeAccount.idTokenClaims?.email as string;

  // Merge to create UUID
  const userEmailDomain = regex.exec(userEmail);
  let UUID;

  if (userEmailDomain) {
    UUID = `${userEmailDomain[0]} (${userId})`;
  }

  // In the case that the user's email does not match the regex, we can still use their ID as a fallback
  globalUuid = UUID ?? userId;

  return globalUuid;
};

export const gaEventSender = (e: EventMessage, activeAccount: AccountInfo) => {
  let gaEvent;

  if (e.eventType === EventType.LOGIN_SUCCESS) {
    gaEvent = GA_LOGIN_PAGE_LRP_USER_LOGIN;
  }

  if (e.eventType === EventType.LOGOUT_SUCCESS) {
    gaEvent = GA_LOGIN_PAGE_LRP_USER_LOGOUT;
  }

  if (gaEvent) {
    // Send the login/logout event to GA.
    // We cannot use the SendCustomEvent hook as these events are outside the body of a function component
    window.gtag('event', 'custom-event', {
      event_name: gaEvent.gaAction,
      event_category: gaEvent.gaCategory,
      event_label: gaEvent.gaLabel,
      user_uuid: getUserUuid(activeAccount) ?? globalUuid,
    });
  }
};
