import styled from 'styled-components';

export const OrientationOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(223, 237, 245, 0.85);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-height: 500px) {
    display: none;
  } ;
`;

export const TopActions = styled.div`
  display: flex;
  min-width: 0;
  max-height: calc(100% - 20rem);
  padding: 2.4rem 2rem;
  flex-direction: row;
  pointer-events: none;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Signpost = styled.button<{
  $currentRouteHasInformationPanel: boolean;
}>`
  overflow: hidden;
  white-space: nowrap;
  pointer-events: all;
  background-color: white;
  padding: 0 1.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #c5dbea;
  border-radius: 0.5rem;
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  height: 5.2rem;
  z-index: 3;

  span {
    font-size: 1.6rem;
    text-align: right;
    max-width: 32.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 0.2rem;
    text-overflow: ellipsis;
    padding-right: 0.5rem;
  }

  margin-left: ${({ $currentRouteHasInformationPanel }) => ($currentRouteHasInformationPanel ? '4rem' : `2rem`)};

  @media (max-width: 768px) {
    height: 3.6rem;
    padding: 0 0.4rem;
    margin-top: ${({ $currentRouteHasInformationPanel }) => ($currentRouteHasInformationPanel ? '0.8rem' : 0)};
    margin-left: ${({ $currentRouteHasInformationPanel }) => ($currentRouteHasInformationPanel ? '4rem' : 0)};
  }
`;

export const DownloadButton = styled.button<{
  $currentRouteHasInformationPanel: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background-color: white;
  padding: 0.7rem 1rem;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #c5dbea;
  border-radius: 0.5rem;
  z-index: 3;
  height: 3.6rem;
  pointer-events: all;
  svg {
    font-size: 2.2rem;
  }

  > :first-child {
    margin-right: 0.5rem;
  }

  @media (max-width: 1050px) {
    svg {
      margin-right: 0;
    }
    span {
      display: none;
    }
  }
  @media (max-width: 768px) {
    height: 3.6rem;
    align-self: center;
    margin-top: ${({ $currentRouteHasInformationPanel }) => ($currentRouteHasInformationPanel ? '0.8rem' : 0)};
    font-size: 2rem;
    padding: 0 1rem;

    > :first-child {
      margin-right: 0;
    }
  }
`;

export const Viewport = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
  width: 100%;
  flex-direction: column;
  align-items: space-between;
`;

export const OrientationOverlayContent = styled.div`
  background-color: #ffffff;
  padding: 1.4rem 2rem;
  color: #0c203d;
  border-radius: 1rem;
  box-shadow: 0 0 1.4rem 0.2rem #bed0db;
  z-index: 1000;
  position: relative;
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;

  p {
    width: 80%;
    font-size: 1.6rem;
    font-weight: bold;
  }

  button {
    background-color: transparent;
    border: 0;
  }
`;
