import { Attribution, ControlBar, LayerSwitcher, Map, ResetPosition, ScaleLine, View, Zoom } from '@tq/web-maps';
import React from 'react';

type BaseMapProps = {
  layers: JSX.Element[];
  resetPositionPadding: number[];
};

export const BaseMap: React.FunctionComponent<BaseMapProps> = ({ layers, resetPositionPadding }) => {
  return (
    <>
      <Map style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <View />
      </Map>
      <ControlBar>
        <ResetPosition padding={resetPositionPadding} />
        <Zoom />
        <Attribution />
        <ScaleLine />
        <LayerSwitcher>{layers}</LayerSwitcher>
      </ControlBar>
    </>
  );
};
