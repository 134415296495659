import { useEffect, useRef } from 'react';

/**
 * useEffect hook that invokes only on update.
 * It does not invoke on mount
 */
const useUpdateEffect: typeof useEffect = (effect, deps) => {
  const hasMounted = useRef(false);

  useEffect(() => {
    if (hasMounted.current) {
      return effect();
    }

    hasMounted.current = true;
    return;
    // eslint-disable-next-line
  }, deps);

  return hasMounted.current;
};

export default useUpdateEffect;
