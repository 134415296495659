import { useQuery, UseQueryOptions } from 'react-query';
import { client as api } from '../../api';
import { GeoJsonFeatureCollectionDto, SwaggerResponse } from '../../api/LRPProxy';

type TResult = SwaggerResponse<GeoJsonFeatureCollectionDto>;
type Variables = { boundary?: string | null; projectId: string };
type Options = UseQueryOptions<TResult, unknown>;

const getProjectLandParcelsQueryOptions = ({ boundary, projectId }: Variables, options: Options = {}): Options => ({
  queryKey: ['projectLandParcels', { boundary, projectId }],
  queryFn: () => api.parcels(boundary, projectId),
  ...options,
});

export const useGetProjectLandParcels = (variables: Variables, options?: Options) =>
  useQuery(getProjectLandParcelsQueryOptions(variables, options));
