export const chartColors = ['#225C8E', '#7A7A14', '#47852E', '#B85B05', '#0F78D3'];

export const chartOptions = {
  aspectRatio: 0.9,
  responsive: true,
  plugins: {
    title: {
      display: true,
      font: {
        size: 12,
      },
    },
    legend: {
      display: false,
    },
    animation: {
      duration: 2000,
    },
  },
};
