import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useMsal } from '@azure/msal-react';
import { client as api } from '../../api';
import { SupportingEvidencesDto2, SwaggerResponse } from '../../api/LRPProxy';
import useToast from '../../components/toast/useToast';
import {
  getQueryError404ToastOptions,
  getFallbackQueryErrorToastOptions,
  getStatusCodeFromQueryError,
} from '../../utils/error';
import { isValidGuid } from '../../utils/validGuid';
import useRedirectToProjectMap from '../useRedirectToProjectMap';
import { QueryError } from './types';

type TResult = SwaggerResponse<SupportingEvidencesDto2>;
type Variables = { id: string; projectId: string };
type Options = UseQueryOptions<TResult, QueryError>;

const useGetSupportingEvidenceQueryOptions = () => {
  const redirectToProjectMap = useRedirectToProjectMap();
  const toast = useToast();
  const { instance } = useMsal();

  return useCallback(
    ({ id, projectId }: Variables, options: Options = {}) => ({
      queryKey: ['supportingEvidence', { id, projectId }],
      queryFn: () =>
        api.getSupportingEvidence(id, projectId).catch((e) => {
          const fireFallbackToast = () => toast(getFallbackQueryErrorToastOptions());

          const statusCode = getStatusCodeFromQueryError(e);

          switch (statusCode) {
            case 401:
              instance.logout();
              break;

            case 404:
              toast(getQueryError404ToastOptions());
              break;

            default:
              fireFallbackToast();
          }

          throw e;
        }),
      onError: (e: QueryError) => {
        redirectToProjectMap();
      },
      retry: false,
      enabled: isValidGuid(id) && isValidGuid(projectId),
      ...options,
    }),
    [redirectToProjectMap, toast, instance]
  );
};

export const useGetSupportingEvidence = (variables: Variables, options?: Options) => {
  const getSupportingEvidenceQueryOptions = useGetSupportingEvidenceQueryOptions();

  return useQuery<TResult, QueryError>(getSupportingEvidenceQueryOptions(variables, options));
};
