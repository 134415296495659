import React, { FC } from 'react';
import { Spinner } from 'react-bootstrap';
import Flex from '../ui/Flex';
import { BoxProps } from '../ui/types';

type Props = BoxProps;

const LoadingSpinner: FC<Props> = (props) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bg: '#e0eef6',
        height: '20.4rem',
        boxShadow: '0 0.3rem 0.6rem 0 rgba(23, 23, 23, 0.18)',
        color: '#346480',
        minWidth: '41.3rem',
      }}
      {...props}
    >
      <Spinner animation='border' role='status' />
      <span className='mt-2 font-weight-bold'>Fetching your results...</span>
    </Flex>
  );
};

export default LoadingSpinner;
