export const projectPaths: {
  overview: string;
  mapRoot: string;
  party: string;
  landParcel: string;
  supportingEvidence: string;
} = {
  overview: '/project/:projectId?',
  mapRoot: '/project/:projectId?/map',
  party: '/project/:projectId/map/party/:partyId?',
  landParcel: '/project/:projectId/map/landparcel/:landParcelId?',
  supportingEvidence: '/project/:projectId/map/supportingevidence/:supportingEvidenceId?',
};

const isSupportingEvidencePath = (path: string) => path === projectPaths.supportingEvidence;
const isPartyPath = (path: string) => path === projectPaths.party;
const isLandParcelPath = (path: string) => path === projectPaths.landParcel;

export const currentPathHasInformationPanel = (path: string) =>
  isSupportingEvidencePath(path) || isPartyPath(path) || isLandParcelPath(path);

export const getProjectsListUrl = () => '/';

export const getProjectOverviewUrl = (projectId = '') => `/project/${projectId}`;

export const getProjectMapUrl = (projectId = '') => `${getProjectOverviewUrl(projectId)}/map`;

export const getPartyUrl = (projectId = '', partyId = '') => `${getProjectMapUrl(projectId)}/party/${partyId}`;

export const getLandParcelUrl = (projectId = '', landParcelId = '') =>
  `${getProjectMapUrl(projectId)}/landparcel/${landParcelId}`;

export const getSupportingEvidenceUrl = (projectId = '', supportingEvidenceId = '') =>
  `${getProjectMapUrl(projectId)}/supportingevidence/${supportingEvidenceId}`;
