import React, { FC, ReactNode, useCallback, useRef } from 'react';

import { MdContentCopy } from 'react-icons/md';
import Text from './ui/Text';
import styled from 'styled-components';
import themedCss from '@styled-system/css';
import useToast from './toast/useToast';

type Props = {
  children: ReactNode;
  title: string;
};

const StyledText = styled(Text)`
  font-size: 1.6rem;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }

  ${themedCss({
    color: 'text.link',
  })};
`;

export const CopyTextToClipboard: FC<Props> = (props) => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const toast = useToast();

  const handleClick = useCallback(() => {
    const el = document.createElement('textarea') as HTMLTextAreaElement;
    document.body.appendChild(el);

    if (ref.current) {
      el.value = ref.current?.innerText;
    }
    el.select();
    const success = document.execCommand('copy');
    document.body.removeChild(el);
    toast({
      status: 'success',
      title: `Copied.`,
      description: 'Content has been copied to clipboard.',
      isClosable: true,
    });

    return success;
  }, [toast]);

  return (
    <StyledText ref={ref} onClick={handleClick} as='span' {...props}>
      {props.children}
      <MdContentCopy className='ml-2' />
    </StyledText>
  );
};
