import { useQuery, UseQueryOptions } from 'react-query';
import { client as api } from '../../api';
import { FileResponse, SwaggerResponse } from '../../api/LRPProxy';

type TResult = SwaggerResponse<FileResponse>;
type Variables = { projectId: string };
type Options = UseQueryOptions<TResult, unknown>;

const getProjectThumbnailBlobQueryOptions = ({ projectId }: Variables, options?: Options): Options => ({
  queryKey: ['getProjectThumbnailBlob', { projectId }],
  queryFn: () => api.downloadProjectThumbnail(projectId),
  ...options,
  retry: false,
});

export const useGetProjectThumbnailBlob = (variables: Variables, options?: Options) =>
  useQuery<TResult, unknown>(getProjectThumbnailBlobQueryOptions(variables, options));
