import { SendCustomEventProps } from '../TagManagerProvider';
import { ProjectsDto } from '../../../api/LRPProxy';

const GA_PROJECT_MAP_PAGE = {
  gaCategory: 'Project Map Page',
  gaLabel: 'Project Map Page',
};

const GA_PROJECT_MAP_PAGE_SEARCH_TERM: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PAGE,
  gaAction: 'ProMap - Search Term',
};

const GA_PROJECT_MAP_PAGE_OPEN_PANEL: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PAGE,
  gaAction: 'ProMap - Open Panel',
};

const GA_PROJECT_MAP_PAGE_ZOOM_RESET: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PAGE,
  gaAction: 'ProMap - Map Reset',
};

const GA_PROJECT_MAP_PAGE_ZOOM_IN: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PAGE,
  gaAction: 'ProMap - Map Zoom In',
};

const GA_PROJECT_MAP_PAGE_ZOOM_OUT: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PAGE,
  gaAction: 'ProMap - Map Zoom Out',
};

const GA_PROJECT_MAP_PAGE_PROJECT_DOCUMENTS: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PAGE,
  gaAction: 'ProMap - Pro Docs',
};

const GA_PROJECT_MAP_PAGE_BACK: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PAGE,
  gaAction: 'ProMap - Back',
};

const GA_PROJECT_MAP_PAGE_PARCEL: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PAGE,
  gaAction: 'ProMap - Parcel',
};

const GA_PROJECT_MAP_PAGE_PAGEVIEW: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PAGE,
  gaAction: 'ProMap - Pageview',
};

export const getMapSearchTermEvent = (userId: string, project: ProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PAGE_SEARCH_TERM,
    gaUserId: userId,
    gaProjectName: project.name,
  };
};

export const getMapOpenPanelEvent = (userId: string, project: ProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PAGE_OPEN_PANEL,
    gaUserId: userId,
    gaProjectName: project.name,
  };
};

export const getMapZoomResetEvent = (userId: string, project: ProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PAGE_ZOOM_RESET,
    gaUserId: userId,
    gaProjectName: project.name,
  };
};

export const getMapZoomInEvent = (userId: string, project: ProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PAGE_ZOOM_IN,
    gaUserId: userId,
    gaProjectName: project.name,
  };
};

export const getMapZoomOutEvent = (userId: string, project: ProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PAGE_ZOOM_OUT,
    gaUserId: userId,
    gaProjectName: project.name,
  };
};

export const getMapProjectDocumentsEvent = (userId: string, project: ProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PAGE_PROJECT_DOCUMENTS,
    gaUserId: userId,
    gaProjectName: project.name,
  };
};

export const getMapBackEvent = (userId: string, project: ProjectsDto): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PAGE_BACK,
    gaUserId: userId,
    gaProjectName: project.name,
  };
};

export const getMapParcelEvent = (userId: string, project: ProjectsDto, parcelId: string): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PAGE_PARCEL,
    gaUserId: userId,
    gaProjectName: project.name,
    gaParcelId: parcelId,
  };
};

export const getMapPagePageview = (userId: string, timeOnPage: string, projectName?: string): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PAGE_PAGEVIEW,
    gaUserId: userId,
    gaProjectName: projectName,
    gaTimeOnPage: timeOnPage,
  };
};
