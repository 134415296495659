import { default as React, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import ParcelIcon from '../../../../assets/LandParcels/ParcelIcon-white.svg';
import Button from '../../../../components/Button';
import CardButton from '../../../../components/card-button/CardButton';
import CardInfo from '../../../../components/card-info/CardInfo';
import GeneralCard from '../../../../components/card/GeneralCard';
import LoadingSpinner from '../../../../components/loading-spinner/LoadingSpinner';
import PopoverModalInterest from '../../../../components/popover-modal/PopoverModalInterests';
import TitleBar from '../../../../components/TitleBar';
import { useGetLandParcel } from '../../../../hooks/queries/useGetLandParcel';
import { useGetLandParcelParties } from '../../../../hooks/queries/useGetLandParcelParties';
import { addressFormatter } from '../../../../utils/AddressFormatterUtils';
import { getInterestsText } from '../../../../utils/interestsFormatterUtils';
import { getUserUuid } from '../../../../utils/gaUtils';
import { useTagManager } from '../../../../components/analytics';
import { getMapPanelPartyDetailsEvent } from '../../../../components/analytics/constants';
import { Helmet } from 'react-helmet';
import { getPartyUrl } from '../../../../utils/router';
import { WithRequired } from '../../../../types';
import { ProjectLandParcelParams } from '../../land-parcel-detail/hooks/useLandParcelDetailLogic';
import { PartiesDto } from '../../../../api/LRPProxy';
import { useProjectMapDetailContext } from '../../context/project-map-detail-context';

export type Pagination = {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPageLink: null;
  currentPageLink: null;
};

const getLandParcelCurrentAddress = (landParcelParty: PartiesDto) =>
  addressFormatter(landParcelParty, false).currentAddressPrimary ?? 'No records held.';

const LandParcelInformationPanel: React.FC = () => {
  const {
    projectDetail,
    projectLandParcelParams,
    isInfoPanelOpen,
    isMobile,
    closeInfoPanel,
    handleSelectFeatureById,
    handleFitFeature,
  } = useProjectMapDetailContext();
  const { projectId, landParcelId } = projectLandParcelParams as WithRequired<ProjectLandParcelParams, 'landParcelId'>;
  const history = useHistory();

  useEffect(() => {
    handleSelectFeatureById(landParcelId);
  }, [landParcelId, handleSelectFeatureById]);

  const { data: landParcelDetail, status: getLandParcelStatus } = useGetLandParcel({
    id: landParcelId,
    projectId,
  });
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  const {
    data: getLandParcelPartiesQueryResult,
    status: landParcelPartiesStatus,
    hasNextPage,
    fetchNextPage,
  } = useGetLandParcelParties({
    landParcelId: landParcelId,
    projectId,
  });

  const viewParcel = (id: string | undefined) => {
    if (id) {
      handleFitFeature(id, isInfoPanelOpen);
      isMobile && closeInfoPanel();
    }
  };

  if (getLandParcelStatus === 'loading' || landParcelPartiesStatus === 'loading') return <LoadingSpinner />;

  if (!landParcelDetail?.result || !getLandParcelPartiesQueryResult) return <div>There was an error.</div>;

  const projectName = projectDetail.name;
  const landParcelPlanNumber = landParcelDetail?.result.numberOnPlan;
  const landParcelDetailId = landParcelDetail?.result.id;
  const landParcelDescription = landParcelDetail?.result.description;

  const pagination = JSON.parse(
    getLandParcelPartiesQueryResult.pages[getLandParcelPartiesQueryResult.pages.length - 1].headers['x-pagination']
  );

  const flattenedLandParcelPartiesData = getLandParcelPartiesQueryResult.pages.map((query) => query.result).flat();

  return (
    <>
      <Helmet>
        <title>{`TerraQuest Land Referencing - ${projectName} Parcel ${landParcelPlanNumber}`}</title>
      </Helmet>
      <GeneralCard title='Land Parcel Details' icon={ParcelIcon}>
        <GeneralCard.Entry label='Parcel Number' text={landParcelPlanNumber}>
          <CardButton
            title={`View Land Parcel ${landParcelPlanNumber}`}
            aria-label={`View Land Parcel ${landParcelPlanNumber}`}
            textColor='grey'
            onClick={() => viewParcel(landParcelDetailId)}
          >
            View
          </CardButton>
        </GeneralCard.Entry>
        <GeneralCard.Entry label='Description' text={landParcelDescription} />
      </GeneralCard>

      <TitleBar variant='blue'>Associated Party Interests: ({pagination.totalCount}) </TitleBar>

      <InfiniteScroll
        dataLength={flattenedLandParcelPartiesData.length}
        next={() => {
          if (hasNextPage) {
            fetchNextPage();
          }
        }}
        hasMore={!!hasNextPage}
        loader={<LoadingSpinner />}
        scrollThreshold={0.8}
        style={{ overflow: 'unset', height: 'unset' }}
      >
        {flattenedLandParcelPartiesData?.map((landParcelParty) => (
          <GeneralCard
            border='grey'
            footerContent={
              <Button
                fullWidth
                onClick={() => {
                  sendCustomEvent(getMapPanelPartyDetailsEvent(getUserUuid(user), projectDetail, landParcelParty.id));
                  history.replace(getPartyUrl(projectId, landParcelParty.id), {
                    previousRoute: {
                      label: `Land Parcel: ${landParcelPlanNumber}`,
                    },
                  });
                }}
                className='btn-secondary'
              >
                Party details
              </Button>
            }
            key={landParcelParty.id}
          >
            <GeneralCard.Entry
              label='Name'
              text={
                landParcelParty.isOrganisation
                  ? `${landParcelParty.topo} ${landParcelParty.principalName}`
                  : `${landParcelParty.title} ${landParcelParty.principalName}`
              }
            >
              <CardInfo>
                ID:
                <p>&nbsp;{landParcelParty.reference}</p>
              </CardInfo>
            </GeneralCard.Entry>
            <GeneralCard.Entry
              label='Address'
              text={
                landParcelParty.addresses?.length ? getLandParcelCurrentAddress(landParcelParty) : `No records held.`
              }
            />
            <GeneralCard.Entry label='Interests' text={getInterestsText(landParcelParty.interests)}>
              <PopoverModalInterest
                title={`Interests for Party ID: ${landParcelParty.reference}`}
                data={landParcelParty.interests}
                project={projectDetail}
                previousRouteLabel={`Land Parcel: ${landParcelPlanNumber}`}
                partyId={landParcelParty.id}
              />
            </GeneralCard.Entry>
          </GeneralCard>
        ))}
      </InfiniteScroll>
    </>
  );
};

export default LandParcelInformationPanel;
