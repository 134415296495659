import { default as React, useCallback, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { FiChevronDown } from 'react-icons/fi';
import { DocumentsDto2 } from '../../../../api/LRPProxy';
import IncomingIcon from '../../../../assets/SupportingEvidence/IncomingIcon.svg';
import OutgoingIcon from '../../../../assets/SupportingEvidence/OutgoingIcon.svg';
import SupportingEvidenceIcon from '../../../../assets/SupportingEvidence/SupportingEvidenceIcon-white.svg';
import CardInfo from '../../../../components/card-info/CardInfo';
import GeneralCard from '../../../../components/card/GeneralCard';
import LoadingSpinner from '../../../../components/loading-spinner/LoadingSpinner';
import { useGetSupportingEvidence } from '../../../../hooks/queries/useGetSupportingEvidence';
import DocumentCard from './DocumentCard';
import styles from './SupportingEvidence.module.scss';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import { WithRequired } from '../../../../types';
import { ProjectLandParcelParams } from '../../land-parcel-detail/hooks/useLandParcelDetailLogic';
import { useProjectMapDetailContext } from '../../context/project-map-detail-context';

const SupportingEvidenceInformationPanel: React.FC = () => {
  const { projectLandParcelParams, projectDetail } = useProjectMapDetailContext();
  const { projectId, supportingEvidenceId } = projectLandParcelParams as WithRequired<
    ProjectLandParcelParams,
    'supportingEvidenceId'
  >;

  const { data: supportingEvidenceData, status: supportingEvidenceStatus } = useGetSupportingEvidence({
    projectId,
    id: supportingEvidenceId,
  });

  const [activeId, setActiveId] = useState<number | null>(null);

  const toggleActive = useCallback(
    (id: number) => {
      if (activeId === id) {
        setActiveId(null);
      } else {
        setActiveId(id);
      }
    },
    [activeId]
  );

  if (supportingEvidenceStatus === 'loading') return <LoadingSpinner />;
  if (!supportingEvidenceData?.result) return <div>There was an error.</div>;

  const supportingEvidence = supportingEvidenceData.result;
  const supportingEvidenceReference = supportingEvidence.supportingEvidenceReference;
  const direction = supportingEvidence.direction;
  const documentsLength = supportingEvidence.documents?.length ?? 0;
  return (
    <>
      <Helmet>
        <title>{`TerraQuest Land Referencing - ${projectDetail?.name} SupEvidence ${supportingEvidenceReference}`}</title>
      </Helmet>
      <GeneralCard title='Supporting Evidence' icon={SupportingEvidenceIcon}>
        <GeneralCard.Entry label='Reference' text={supportingEvidenceReference}>
          {direction && (
            <CardInfo>
              {direction}
              {direction === 'Outgoing' && <img src={OutgoingIcon} alt='icon' />}
              {direction === 'Incoming' && <img src={IncomingIcon} alt='icon' />}
            </CardInfo>
          )}
        </GeneralCard.Entry>
        <GeneralCard.Entry label='Type' text={supportingEvidence.description} />
        <GeneralCard.Entry
          label='Date'
          text={supportingEvidence.date && format(supportingEvidence.date, 'dd/MM/yyyy')}
        />
      </GeneralCard>
      <Accordion className={styles.AccordionWrapper}>
        <Card className={styles.Accordion} aria-live='assertive' aria-atomic='false'>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='0'
            className={`${styles.AccordionToggle} ${documentsLength === 0 && styles.grey} `}
            onClick={() => toggleActive(0)}
            data-id='1'
            data-testid='document toggle'
          >
            Documents: ({documentsLength})
            <button
              aria-label='show document supportingEvidenceData.result'
              className='ml-auto bg-transparent border-0'
              disabled={documentsLength === 0}
            >
              <FiChevronDown fontSize='2.4rem' className={`${activeId === 0 && styles.Rotate} ml-auto`} />
            </button>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey={documentsLength ? '0' : '-1'} className={styles.AccordionBody}>
            <Card.Body className='p-0'>
              {supportingEvidenceData.result.documents?.map(
                (document: DocumentsDto2) =>
                  document.id &&
                  document.documentURL &&
                  document.type && (
                    <DocumentCard
                      key={document.id}
                      id={document.id}
                      project={projectDetail}
                      name={document.documentURL}
                      type={document.type}
                    />
                  )
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default SupportingEvidenceInformationPanel;
