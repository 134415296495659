import React from 'react';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    gtag: {
      (command: 'config', targetId: string, additionalConfigInfo?: Record<string, unknown>): void;
      (command: 'event', eventName: string, additionalEventInfo?: Record<string, unknown>): void;
      (command: 'set', attributes: Record<string, unknown>): void;
      (command: 'consent', subCommand: 'default' | 'update', consent: Record<string, 'granted' | 'denied'>): void;
    };
  }
}

interface ITagManagerContext {
  sendCustomEvent: (params: SendCustomEventProps) => void;
}

export interface SendCustomEventProps {
  /**
   * Value that will appear as the event action
   * in analytics event reports.
   */
  gaAction: string;
  /**
   * The category of the event
   */
  gaCategory?: string;
  /**
   * The label of the event
   */
  gaLabel?: string;
  /**
   * The UUID of the event
   */
  gaUserId?: string;
  /**
   * The name of a project
   */
  gaProjectName?: string;
  /**
   * The ID of a parcel
   */
  gaParcelId?: string;
  /**
   * The ID of a party
   */
  gaPartyId?: string;
  /**
   * The ID of a supporting document
   */
  gaSupportingDocumentId?: string;
  /**
   * The amount of time spent on a page (for Pageview events)
   */
  gaTimeOnPage?: string;
}

const TagManagerContext = React.createContext<ITagManagerContext | undefined>(undefined);

interface TagManagerProviderProps {
  onLoad?: () => void;
}

/**
 * Provides a method to update consent for Google Tag Manager (GTM).
 *
 * The gtag code must already be set up in the document and must send a default
 * consent message with both `ad_storage` and `analytics_storage` set to `denied`.
 * This is so that no cookies will be set until we have read the cookie preferences
 * in this component and sent a consent update to GTM.
 *
 * In this applicaiton (the content site), it is set up in `html.js`.
 */
export const TagManagerProvider: React.FC<TagManagerProviderProps> = ({ children }) => {
  /**
   * Send custom analytics event to associated analytics account.
   */
  const sendCustomEvent = ({ gaAction, gaCategory, gaLabel, gaUserId, ...rest }: SendCustomEventProps): void => {
    if (window.gtag) {
      window.gtag('event', 'custom-event', {
        event_name: gaAction,
        event_category: gaCategory,
        event_label: gaLabel,
        user_uuid: gaUserId,
        ...rest,
      });
    }
  };

  const value = {
    sendCustomEvent,
  };

  return <TagManagerContext.Provider value={value}>{children}</TagManagerContext.Provider>;
};

export function useTagManager(): ITagManagerContext {
  const context = React.useContext(TagManagerContext);
  if (context === undefined) {
    throw new Error('useTagManager must be used within a TagManagerProvider');
  }
  return context;
}
