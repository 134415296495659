import React, { useState, createContext, useCallback } from 'react';

type NavigationProperties = {
  isOpen: boolean;
  openSideNav: () => void;
  closeSideNav: () => void;
  burgerOpenClose: () => void;
};
export const NavContext = createContext<NavigationProperties>({
  isOpen: false,
  openSideNav: () => {},
  closeSideNav: () => {},
  burgerOpenClose: () => {},
});

const NavProvider: React.FC = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openSideNav = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
    }
  }, [isOpen]);

  const closeSideNav = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [isOpen]);

  const burgerOpenClose = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <NavContext.Provider
      value={{
        isOpen,
        openSideNav,
        closeSideNav,
        burgerOpenClose,
      }}
    >
      {props.children}
    </NavContext.Provider>
  );
};
export default NavProvider;
