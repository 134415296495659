import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { client as api } from '../../api';
import { DocumentSasTokenDto, SwaggerResponse } from '../../api/LRPProxy';
import useToast from '../../components/toast/useToast';
import {
  getFallbackQueryErrorToastOptions,
  getQueryError401ToastOptions,
  getStatusCodeFromQueryError,
} from '../../utils/error';

export type TResult = SwaggerResponse<DocumentSasTokenDto>;
type Variables = { id: string; projectId: string };
type Options = UseQueryOptions<TResult, unknown>;

export const useGetDocumentSasTokenOptions = () => {
  const toast = useToast();

  return useCallback(
    ({ id, projectId }: Variables, options: Options): Options => ({
      queryKey: ['getDocumentSasToken', { id, projectId }],
      queryFn: () => {
        return api.documentSaSToken(id, projectId).catch((e) => {
          const fireFallbackToast = () =>
            toast(
              getFallbackQueryErrorToastOptions({
                title: 'A download error has occurred.',
                description:
                  'This document is currently unable to be downloaded. Please try downloading again or contact our help desk.',
              })
            );

          const statusCode = getStatusCodeFromQueryError(e);

          switch (statusCode) {
            case 401:
              toast(
                getQueryError401ToastOptions({
                  description:
                    "You don't have access to this document. Please contact our help desk if you feel you should.",
                })
              );
              break;

            case 404:
            default:
              fireFallbackToast();
          }

          throw e;
        });
      },
      retry: false,
      ...options,
    }),
    [toast]
  );
};

export const useGetDocumentSasToken = (variables: Variables, options: Options) => {
  const getDocumentSasTokenQueryOptions = useGetDocumentSasTokenOptions();
  return useQuery<TResult, unknown>(getDocumentSasTokenQueryOptions(variables, options));
};
