import { InterestDto, InterestsDto } from '../api/LRPProxy';

export type AcceptedTypes = InterestDto | InterestsDto;

export const getInterestsText = (interests?: AcceptedTypes[]) => {
  const interestsByKey: { [key: string]: AcceptedTypes[] } = {
    Freehold: [],
    Leasehold: [],
    Tenant: [],
    Occupation: [],
    Mortgage: [],
    ThirdParty: [],
  };

  interests?.forEach((interest: AcceptedTypes) => {
    const { type } = interest;
    if (!type) {
      return;
    }
    return interestsByKey[type].push(interest);
  });
  const landParcelInterests: string[] = [];

  Object.keys(interestsByKey).forEach((key) => {
    if (interestsByKey[key].length > 0) {
      landParcelInterests.push(`${key} (${interestsByKey[key].length})`);
    }
  });
  return landParcelInterests.join(', ');
};
