import themedCss from '@styled-system/css';
import styled from 'styled-components';
import Accordion from '../../../components/ui/Accordion';

const Header = styled(Accordion.Header)`
  display: flex;
  width: 100%;
  border-radius: 0.4rem;
  font-size: 1.7rem;
  background-color: #f6f8f8;

  &[aria-expanded='true'] {
    border-radius: 0.4rem 0.4rem 0 0;

    svg {
      transform: rotate(180deg);
    }
  }

  &[aria-expanded='true'],
  &:hover {
    p {
      ${themedCss({ color: 'text.active' })}
    }

    svg {
      ${themedCss({ color: 'text.active' })}
    }
  }

  ${themedCss({
    py: 3,
    px: 4,
    color: 'text.body',
    borderWidth: '.1rem',
    borderStyle: 'solid',
    borderColor: 'lightBlueGrey.80',
  })}
`;
const Panel = styled(Accordion.Panel)`
  border-radius: 0 0 0.4rem 0.4rem;

  .list-unstyled {
    list-style-type: none;
  }
  ul {
    list-style-type: disc;
  }

  ${themedCss({
    bg: 'background.main',
    py: 3,
    px: 5,
    // Can't use shorthand else styled-system can't evaluate the theme values
    borderLeftWidth: '.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: 'lightBlueGrey.80',
    borderRightWidth: '.1rem',
    borderRightStyle: 'solid',
    borderRightColor: 'lightBlueGrey.80',
    borderBottomWidth: '.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: 'lightBlueGrey.80',
  })}
`;
const FAQ = styled(Accordion)``;

FAQ.Header = Header;
FAQ.Panel = Panel;

export default FAQ;
