import { useHighlightFeatureById, useMapActions, useSelectInteractionEvent } from '@tq/web-maps';
import useIsMobile from '../../../../hooks/useIsMobile';
import { highlightParcelStyles, HIGHLIGHT_LAYER_ID, LAND_PARCEL_LAYER_ID } from '../fragments';

export type UseProjectMapDetailResult = {
  handleFitAndSelectFeatureById: (featureId: string, isInfoPanelOpen: boolean) => void;
  handleFitAndHighlightFeatureById: (featureId: string, isInfoPanelOpen: boolean) => void;
  handleSelectFeatureById: (featureId: string) => void;
  handleFitFeature: (featureId: string, isInfoPanelOpen: boolean) => void;
  isMobile: boolean;
};

export function useProjectMapDetail(): UseProjectMapDetailResult {
  const { selectFeatureById } = useSelectInteractionEvent();
  const { fitFeature } = useMapActions();

  const { highlightFeatureById } = useHighlightFeatureById({
    id: HIGHLIGHT_LAYER_ID,
    zIndex: 500,
    shouldResetOnMove: true,
    style: highlightParcelStyles,
  });

  const isMobile = useIsMobile();

  const handleSelectFeatureById = (featureId: string) => {
    selectFeatureById({ featureId, layerId: LAND_PARCEL_LAYER_ID });
  };

  const handleFitFeature = (featureId: string, isInfoPanelOpen: boolean) => {
    const mobilePadding = [40, 40, 40, 40];
    const defaultPadding = isInfoPanelOpen ? [160, 160, 160, 453 + 160] : [160, 160, 160, 160];
    const padding = isMobile ? mobilePadding : defaultPadding;
    fitFeature(featureId, { padding });
  };

  const handleFitAndSelectFeatureById = (featureId: string, isInfoPanelOpen: boolean) => {
    handleSelectFeatureById(featureId);
    handleFitFeature(featureId, isInfoPanelOpen);
  };

  const handleFitAndHighlightFeatureById = (featureId: string, isInfoPanelOpen: boolean) => {
    highlightFeatureById(featureId, LAND_PARCEL_LAYER_ID);
    handleFitFeature(featureId, isInfoPanelOpen);
  };

  return {
    handleFitAndSelectFeatureById,
    handleFitAndHighlightFeatureById,
    handleFitFeature,
    handleSelectFeatureById,
    isMobile,
  };
}
