import React from 'react';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMsal } from '@azure/msal-react';
import { ProjectDto } from '../../../../api/LRPProxy';
import { getUserUuid } from '../../../../utils/gaUtils';
import Button from '../../../../components/Button';
import GeneralCard from '../../../../components/card/GeneralCard';
import LoadingSpinner from '../../../../components/loading-spinner/LoadingSpinner';
import PopoverModal from '../../../../components/popover-modal/PopoverModal';
import TitleBar from '../../../../components/TitleBar';
import { useTagManager } from '../../../../components/analytics';
import {
  getMapPanelParcelDetailsEvent,
  getMapPanelParcelLocateEvent,
} from '../../../../components/analytics/constants';
import { useGetPartyLandParcels } from '../../../../hooks/queries/useGetPartyLandParcels';
import { getInterestsText } from '../../../../utils/interestsFormatterUtils';

type PartyAssociatedLandParcelProps = {
  project: ProjectDto;
  partyName: string;
  partyId: string;
  handleViewParcelDetail: (landParcelId: string | undefined, partyName: string) => void;
  handleLocateParcel: (landParcelId: string | undefined) => void;
};

export const PartyAssociatedLandParcel: React.FunctionComponent<PartyAssociatedLandParcelProps> = (props) => {
  const { partyName, project, partyId, handleViewParcelDetail, handleLocateParcel } = props;
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  const {
    data: partyLandParcelsData,
    status: partyLandParcelsStatus,
    hasNextPage,
    fetchNextPage,
  } = useGetPartyLandParcels({
    partyId,
    projectId: project.id ?? '',
  });

  if (partyLandParcelsStatus === 'loading') return <LoadingSpinner />;
  if (!partyLandParcelsData) return <div data-testid='error-message'>There was an error.</div>;

  const totalLandParcels = JSON.parse(
    partyLandParcelsData.pages[partyLandParcelsData.pages.length - 1].headers['x-pagination']
  ).totalCount;

  const partyLandParcelList = partyLandParcelsData.pages.map((query) => query.result).flat();

  return (
    <>
      <Helmet>
        <title>{`TerraQuest Land Referencing - ${project.name} Party ${partyName}`}</title>
      </Helmet>
      <TitleBar data-testid='party-land-parcels' variant='blue'>
        Associated Land Parcels: ({totalLandParcels}){' '}
      </TitleBar>

      <InfiniteScroll
        dataLength={partyLandParcelList.length}
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        loader={<LoadingSpinner />}
        scrollThreshold={0.8}
        style={{ overflow: 'unset', height: 'unset' }}
      >
        {partyLandParcelList?.map((landParcel) => (
          <GeneralCard
            border='grey'
            hasTwoButtons
            footerContent={
              <>
                <Button
                  data-testid='view-parcel-detail'
                  onClick={() => {
                    sendCustomEvent(getMapPanelParcelDetailsEvent(getUserUuid(user), landParcel.id, project.name));
                    handleViewParcelDetail(landParcel.id, partyName);
                  }}
                >
                  Land parcel details
                </Button>
                <Button
                  data-testid='locate-parcel'
                  outline
                  onClick={() => {
                    sendCustomEvent(getMapPanelParcelLocateEvent(getUserUuid(user), project.name, landParcel.id));
                    handleLocateParcel(landParcel.id);
                  }}
                >
                  Locate on map
                </Button>
              </>
            }
            key={landParcel.id}
          >
            <GeneralCard.Entry label='Parcel Number' text={landParcel.numberOnPlan}></GeneralCard.Entry>
            <GeneralCard.Entry label='Description' text={landParcel.description}></GeneralCard.Entry>
            <GeneralCard.Entry label='Interests' text={getInterestsText(landParcel.interests)}>
              <PopoverModal
                title={`Interests for Parcel Number: ${landParcel.numberOnPlan}`}
                data={landParcel.interests}
                project={project}
                previousRouteLabel={`Party: ${partyName}`}
                parcelId={landParcel.id}
              />
            </GeneralCard.Entry>
          </GeneralCard>
        ))}
      </InfiniteScroll>
    </>
  );
};
