import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { getUserUuid } from '../../utils/gaUtils';
import { useTagManager } from '../../components/analytics';
import {
  getMapPanelSelectParcelFromSearchEvent,
  getMapPanelSelectPartyFromSearchEvent,
  getMapPanelSelectDocumentFromSearchEvent,
} from '../../components/analytics/constants';
import { getLandParcelUrl, getPartyUrl, getSupportingEvidenceUrl } from '../../utils/router';
import styles from './Search.module.scss';
import { useProjectMapDetailContext } from '../../routes/project/context/project-map-detail-context';
import { ProjectDto } from '../../api/LRPProxy';

export type HistoryLocationState = {
  previousRoute?: {
    label: string;
    scrollPosition: [number, number];
  };
};

export type AccordionResult = {
  id?: string;
  reference?: string;
  termFoundIn?: string;
  supportingEvidenceReference?: string;
  numberOnPlan?: string;
  direction?: string;
  principalName?: string;
};

type Props = {
  items: AccordionResult[];
  section?: string;
  project: ProjectDto;
  searchTerm?: string;
};

export const HighlightText: React.FunctionComponent<{ text: string | undefined; highlight: string | undefined }> = ({
  text,
  highlight,
}) => {
  if (!text || !highlight) {
    return null;
  }
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <>
      {parts.map((part: string, i: number) => (
        <span
          data-testid='highlight-text'
          key={`${i}-${part}`}
          style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}
        >
          {part}
        </span>
      ))}
    </>
  );
};

export const SearchAccordion: React.FC<Props> = ({ items, section, project, searchTerm }) => {
  const history = useHistory<HistoryLocationState>();
  const { handleFitAndSelectFeatureById, isInfoPanelOpen } = useProjectMapDetailContext();
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  const viewLandParcelDetails = useCallback(
    (landParcelId) => {
      if (landParcelId) {
        handleFitAndSelectFeatureById(landParcelId, isInfoPanelOpen);
        history.push(getLandParcelUrl(project.id, landParcelId));
      }
    },
    [handleFitAndSelectFeatureById, isInfoPanelOpen, history, project]
  );

  return (
    <>
      {section === 'parties' && (
        <>
          {items.map((party: AccordionResult) => (
            <div key={party.id} className={styles.AccordionResult}>
              <div className={styles.Results}>
                <Link
                  to={{
                    pathname: getPartyUrl(project.id, party.id),
                  }}
                  data-testid='party link'
                  onClick={() => {
                    sendCustomEvent(getMapPanelSelectPartyFromSearchEvent(getUserUuid(user), project, party.id));
                  }}
                >
                  Party ID: #<HighlightText text={party.reference} highlight={searchTerm} /> <br></br>
                  <HighlightText
                    text={party.termFoundIn?.length === 0 ? party.principalName : party.termFoundIn}
                    highlight={searchTerm}
                  />
                </Link>
              </div>
            </div>
          ))}
        </>
      )}
      {section === 'parcels' && (
        <>
          {items.map((landParcel: AccordionResult) => (
            <div key={`${landParcel.id}-${landParcel.reference}`} className={styles.AccordionResult}>
              <div
                className={styles.Results}
                onClick={() => {
                  sendCustomEvent(getMapPanelSelectParcelFromSearchEvent(getUserUuid(user), project, landParcel.id));
                  viewLandParcelDetails(landParcel.id);
                }}
                data-testid='parcel link'
              >
                Parcel Number: #<HighlightText text={landParcel.numberOnPlan} highlight={searchTerm} />
              </div>
            </div>
          ))}
        </>
      )}
      {section === 'supportingEvidence' && (
        <>
          {items.map((evidence: AccordionResult) => (
            <div key={evidence.id} className={styles.AccordionResult}>
              <div className={styles.Results}>
                <Link
                  to={{ pathname: getSupportingEvidenceUrl(project.id, evidence.id) }}
                  data-testid='supporting link'
                  onClick={() => {
                    sendCustomEvent(getMapPanelSelectDocumentFromSearchEvent(getUserUuid(user), project, evidence.id));
                  }}
                >
                  Reference ID: <HighlightText text={evidence.supportingEvidenceReference} highlight={searchTerm} />
                  {evidence.direction && <span className='ml-2'>({evidence.direction.toLowerCase()})</span>}
                </Link>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};
