import { SendCustomEventProps } from '../TagManagerProvider';

const GA_HELP_PAGE = {
  gaCategory: 'Help Page',
  gaLabel: 'Help Page',
};

const GA_HELP_PAGE_HELP: SendCustomEventProps = {
  ...GA_HELP_PAGE,
  gaAction: 'Help - Help',
};

const GA_HELP_PAGE_HOME: SendCustomEventProps = {
  ...GA_HELP_PAGE,
  gaAction: 'Help - Home',
};

const GA_HELP_PAGE_FAQ: SendCustomEventProps = {
  ...GA_HELP_PAGE,
  gaAction: 'Help - FAQ',
};

const GA_HELP_PAGE_GLOSSARY: SendCustomEventProps = {
  ...GA_HELP_PAGE,
  gaAction: 'Help - Glossary',
};

export const getHelpPageHelp = (userId: string): SendCustomEventProps => {
  return {
    ...GA_HELP_PAGE_HELP,
    gaUserId: userId,
  };
};

export const getHelpPageHome = (userId: string): SendCustomEventProps => {
  return {
    ...GA_HELP_PAGE_HOME,
    gaUserId: userId,
  };
};

export const getHelpPageFaq = (userId: string): SendCustomEventProps => {
  return {
    ...GA_HELP_PAGE_FAQ,
    gaUserId: userId,
  };
};

export const getHelpPageGlossary = (userId: string): SendCustomEventProps => {
  return {
    ...GA_HELP_PAGE_GLOSSARY,
    gaUserId: userId,
  };
};
