import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { client as api } from '../../api';
import { LandParcelDto, SwaggerResponse } from '../../api/LRPProxy';

type TResult = SwaggerResponse<LandParcelDto[]>;
type Variables = {
  projectId: string;
  partyId: string;
  interestTypes?: string[] | null | undefined;
  pageNumber?: number | undefined;
  pageSize?: number | undefined;
  orderBy?: string | null | undefined;
  fields?: string | null | undefined;
};
type Options = UseInfiniteQueryOptions<TResult, unknown>;

const getPartyLandParcelsQueryOptions = ({ projectId, partyId }: Variables, options: Options = {}): Options => ({
  queryKey: ['partyLandParcels', { partyId, projectId }],
  queryFn: ({ pageParam = 1 }) =>
    api.getPartyLandParcels(
      partyId,
      undefined,
      pageParam,
      parseInt(process.env.REACT_APP_LRP_PAGE_SIZE || ''),
      'numberOnPlan ASC',
      undefined,
      projectId
    ),
  getNextPageParam: (lastPage) => {
    const { currentPage, totalPages } = JSON.parse(lastPage.headers['x-pagination']);

    if (currentPage < totalPages) {
      return currentPage + 1;
    }

    return undefined;
  },
  ...options,
});

export const useGetPartyLandParcels = (variables: Variables, options?: Options) =>
  useInfiniteQuery<TResult, unknown>(getPartyLandParcelsQueryOptions(variables, options));
