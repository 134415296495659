import Box from '../../../components/ui/Box';
import { CopyTextToClipboard } from '../../../components/CopyTextToClipboard';
import FAQAccordion from './FAQAccordion';
import { FiChevronDown } from 'react-icons/fi';
import Flex from '../../../components/ui/Flex';
import Heading from '../../../components/ui/Heading';
import { HelpContentLayout } from '../Help.styles';
import LayerPanelImage from '../../../assets/help/help-page-layer-panel.jpg';
import React from 'react';
import ResetLocationButtonImage from '../../../assets/help/help-page-relocation-button.jpg';
import Text from '../../../components/ui/Text';

const faqs = [
  {
    id: 1,
    question: 'I am unable to see my Project. Please help!',
    content: (
      <>
        If your Project is already set up in the portal you should be able to view it in My Projects section. If a new
        project needs to be set up in the portal, please contact one of the support team on{' '}
        <CopyTextToClipboard title='Email service desk link'>support@landreferencingportal.co.uk</CopyTextToClipboard>.
      </>
    ),
  },
  {
    id: 2,
    question: 'How do I switch between Projects?',
    content: (
      <>
        You can switch between projects easily using the My Projects section by following these steps:
        <ul className='ml-5 list-unstyled mt-3'>
          <li>Option 1: Return to the My Projects page by clicking on the menu.</li>
          <li>
            Option 2: Expand the menu to view a list of your projects and click on the project name you want to view.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 3,
    question: 'How do I add a user to the Portal?',
    content: (
      <>
        To add additional users to the LR Portal, please speak to one of our support agents who can help. You can
        contact one of the support team on{' '}
        <CopyTextToClipboard title='Email service desk link'>support@landreferencingportal.co.uk</CopyTextToClipboard>.
      </>
    ),
  },
  {
    id: 4,
    question: 'How do I obtain access to other Projects?',
    content: (
      <>
        To obtain access to other Projects, please speak to one of our support agents who can help. You can contact one
        of the support team on{' '}
        <CopyTextToClipboard title='Email service desk link'>support@landreferencingportal.co.uk</CopyTextToClipboard>.
      </>
    ),
  },
  {
    id: 5,
    question: 'How often is the data in LR Portal updated?',
    content: (
      <>
        The referencing details displayed by our Land Referencing Portal are updated regularly following quality checks
        and approval on the information regarding the Project.
        <ul className='ml-5'>
          <li>You can see the “Last Updated Date” on the Project List page.</li>
          <li>The background maps are sourced using Ordnance Survey and are updated regularly.</li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    question: 'How do I see Land Parcels?',
    content: (
      <>
        To see Land Parcel information, please follow these steps:
        <ul className='ml-5 list-unstyled mt-3'>
          <li>Step 1: Ensure the Land Parcel layer is turned on.</li>
          <img src={LayerPanelImage} alt='layer-panel' className='mt-3' />
          <li>Step 2: Zoom in to a level where you can see Streets and Roads on the map.</li>
        </ul>
      </>
    ),
  },
  {
    id: 7,
    question: 'Where does the data come from?',
    content: (
      <>
        The maps used within the Portal are sourced from Ordnance Survey. Parcel information is sourced from HM Land
        Registry, Site Visits and contact events.
      </>
    ),
  },
  {
    id: 8,
    question: `I tried to use the search feature but it did not return the expected results. What's wrong?`,
    content: (
      <>
        The Land Referencing Portal allows you to search the following information
        <ul className='ml-5'>
          <li>Party Name.</li>
          <li>Party Address.</li>
          <li>Parcel Numbers (Number on Plan).</li>
          <li>Supporting evidence.</li>
        </ul>
        <p>You can start your search query with a hashtag (#) to search only for land parcels.</p>
        If you are unable to find certain information you believe should be on the portal, you can contact one of the
        support team on{' '}
        <CopyTextToClipboard title='Email service desk link'>support@landreferencingportal.co.uk</CopyTextToClipboard>.
      </>
    ),
  },
  {
    id: 9,
    question: 'How do I relocate to the correct area on a map?',
    content: (
      <>
        Please use the “Reset to default position” button, in the bottom right-hand corner of the screen.
        <img src={ResetLocationButtonImage} alt='Reset location button' className='mt-3' />
      </>
    ),
  },
  {
    id: 10,
    question: 'Who do I contact if I believe the information shown is incorrect?',
    content: (
      <>
        If you believe the information you are seeing is incorrect, please speak to one of our support agents who can
        help; You can contact one of the support team on{' '}
        <CopyTextToClipboard title='Email service desk link'>support@landreferencingportal.co.uk</CopyTextToClipboard>.
      </>
    ),
  },
];

const FAQRoute = () => {
  return (
    <HelpContentLayout>
      <HelpContentLayout.Header>
        <Heading as='h1' variant='h1'>
          Frequently asked questions
        </Heading>
      </HelpContentLayout.Header>
      <Box px='1.6rem' pr={2} pb={9}>
        {faqs.map((faq, i) => (
          <FAQAccordion key={faq.id} mt={i !== 0 ? 3 : undefined} id={faq.id}>
            <FAQAccordion.Header>
              <Flex alignItems='center' flexGrow={1}>
                <Text mr={3} textAlign='left'>
                  {faq.question}
                </Text>
                <Box size='2.4rem' as={FiChevronDown} ml='auto' flex='0 0 2.4rem' />
              </Flex>
            </FAQAccordion.Header>
            <FAQAccordion.Panel>{faq.content}</FAQAccordion.Panel>
          </FAQAccordion>
        ))}
      </Box>
    </HelpContentLayout>
  );
};

export default FAQRoute;
