const atbrGeometryStyles = {
  render: {
    stroke: {
      color: 'rgba(255,0,0,1)',
      width: 3,
    },
    text: {
      font: '12px Open Sans,sans-serif',
      fill: {
        color: 'rgba(255,0,0,0.1)',
      },
    },
  },
};

const highlightParcelStyles = {
  stroke: {
    color: 'rgba(255,0,0,1)',
    width: 2,
  },
  fill: {
    color: 'rgba(255, 206, 255, 0.38)',
  },
  text: {
    font: '600 1.8rem Open Sans,sans-serif',
    fill: {
      color: 'rgba(255,0,0,1)',
    },
  },
};

const landParcelGeometryStyles = {
  render: {
    stroke: {
      color: 'rgba(255,0,0,1)',
      width: 2,
    },
    fill: {
      color: 'rgba(255, 206, 255, 0.09)',
    },
    text: {
      font: '600 1.8rem Open Sans,sans-serif',
      fill: {
        color: 'rgba(255,0,0,1)',
      },
    },
  },
  highlight: highlightParcelStyles,
  select: {
    stroke: {
      color: '#2226fa',
      width: 3,
    },
    fill: {
      color: 'rgba(59, 134, 255, 0.18)',
    },
    text: {
      font: '600 1.8rem Open Sans,sans-serif',
      fill: {
        color: '#2226fa',
      },
    },
  },
};

export { landParcelGeometryStyles, atbrGeometryStyles, highlightParcelStyles };
