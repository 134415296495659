import React, { FC } from 'react';
import { forwardRef } from 'react';
import Box from './Box';
import { HeadingProps } from './types';

const Heading: FC<HeadingProps> = forwardRef((props, ref) => (
  <Box ref={ref} as='h2' tx='text' variant='heading' {...props} />
));

Heading.displayName = 'Heading';
export default Heading;
