/**
  The Box component is a fundemental building block that accepts commonly used style attributes as props.

  Style attributes that aren't available as props can be set using the 'sx' prop.
  The 'sx' prop is similar to the 'style' prop, except it can accept values from the theme object 
  and it applies the styles by adding a class, it does not set them inline.

  The Box can be used to render any element using the 'as' prop

  e.g:
    <Box
      as="li"
      color="primary"
      padding="1.2rem"
      sx={{ textDecoration: 'underline' }} 
    /> 
    ----Renders---
    <li class="class-that-applies-desired-styles" />
 */

import css, { get } from '@styled-system/css';
import React from 'react';
import styled from 'styled-components';
import {
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import { BoxProps } from './types';

type Props = BoxProps & FlexboxProps & TypographyProps & ColorProps & LayoutProps & SpaceProps;

// Map sx prop to theme values
const sx = (props: any) => css(props.sx)(props.theme);

const base = (props: any) => css(props.__css)(props.theme);

// Map variant prop to theme values
const mapVariantProps = ({
  theme,
  variant,
  tx = 'variants',
}: {
  theme: Record<string, unknown>;
  variant: string | string[];
  tx: string;
}) => css(get(theme, tx + '.' + variant, get(theme, variant)))(theme);

const Box: React.FC<Props> = styled.div`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  ${base}
  ${mapVariantProps}
  ${(props: any) => props.css}
  ${sx}
  ${compose(space, layout, typography, color, flexbox)}
`;

export default Box;
