import { useEffect } from 'react';

/**
 * React hook that provides a declarative `setTimeout`
 *
 * @param callback the callback to run after specified delay
 * @param delay the delay (in ms). If null
 */
export function useTimeout(callback: (...args: any[]) => void, delay: number | null) {
  useEffect(() => {
    if (delay === null) return;

    const timeoutId = window.setTimeout(() => {
      callback();
    }, delay);

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [delay, callback]);
}

export default useTimeout;
