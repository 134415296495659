import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useHistory } from 'react-router-dom';
import { client as api } from '../../api';
import { ProjectOverviewDto, SwaggerResponse } from '../../api/LRPProxy';
import useToast from '../../components/toast/useToast';
import {
  getFallbackQueryErrorToastOptions,
  getQueryError401ToastOptions,
  getStatusCodeFromQueryError,
} from '../../utils/error';
import { isValidGuid } from '../../utils/validGuid';
import { getProject404ToastOptions } from './useGetProject';

type TResult = SwaggerResponse<ProjectOverviewDto>;
type Variables = { projectId: string };
type Options = UseQueryOptions<TResult, unknown>;

const useGetProjectOverviewQueryOptions = () => {
  const toast = useToast();
  const history = useHistory();

  return useCallback(
    ({ projectId }: Variables, options: Options = {}): Options => ({
      queryKey: ['projectOverview', { projectId }],
      queryFn: () =>
        api.getProjectOverview(projectId).catch((e) => {
          const fireFallbackToast = () => toast(getFallbackQueryErrorToastOptions());

          const statusCode = getStatusCodeFromQueryError(e);

          switch (statusCode) {
            case 401:
              toast(
                getQueryError401ToastOptions({
                  description:
                    "You don't have access to this project. Please contact our help desk if you feel you should.",
                })
              );
              break;

            case 404:
              toast(getProject404ToastOptions());
              break;

            default:
              fireFallbackToast();
          }

          throw e;
        }),
      onError: () => {
        history.replace('/');
      },
      retry: false,
      enabled: isValidGuid(projectId),
      ...options,
    }),
    [toast, history]
  );
};

export const useGetProjectOverview = (variables: Variables, options?: Options) => {
  const getProjectOverviewQueryOptions = useGetProjectOverviewQueryOptions();
  return useQuery<TResult>(getProjectOverviewQueryOptions(variables, options));
};
