import React, { useState } from 'react';
import styles from './PopOver.module.scss';
import { Modal } from 'react-bootstrap';
import { IAddressType } from './Party';

export interface IAddressDetails {
  partyAddresses: {
    title: string;
    addresses: IAddressType;
  };
}

const ModalComp: React.FC<IAddressDetails> = (props) => {
  const {
    partyAddresses: { title, addresses },
  } = props;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleClick = () => setShow(true);

  return (
    <>
      <button className={styles.TooltipInfo} data-testid='modal-info' onClick={handleClick}>
        Info
      </button>

      <Modal show={show} onHide={handleClose} backdrop='static' centered dialogClassName={styles.modalDoc}>
        <Modal.Header closeButton className={styles.modalHeaderButton}></Modal.Header>
        <Modal.Body>
          <div data-testid='party-detail-modal' className={styles.modalContainer}>
            <span className={styles.title}>{title}</span>
            <div className={styles.modalCurrentAddressesContainer}>
              <span className={styles.addressTitles}>Current Addresses:</span>
              <div className={styles.modalAddressesContainer} data-testid='address container'>
                {addresses.currentAddresses?.length ? (
                  addresses.currentAddresses?.map((address, index) => {
                    return (
                      <div key={`${index}_${address.substring(0, 5)}`} className={styles.modalAddressSection}>
                        {address}
                      </div>
                    );
                  })
                ) : (
                  <span className={styles.modalAddressSection}>No records held.</span>
                )}
              </div>
            </div>
            <div className={styles.modalHistoricalAddressesContainer}>
              <span className={styles.addressTitles}>Historical Addresses:</span>
              <div className={styles.modalAddressesContainer}>
                {addresses.historicalAddresses?.length ? (
                  addresses.historicalAddresses?.map((address, index) => {
                    return (
                      <div key={`${index}_${address.substring(0, 5)}`} className={styles.modalAddressSection}>
                        {address}
                      </div>
                    );
                  })
                ) : (
                  <span className={styles.modalAddressSection}>No records held.</span>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComp;
