import { Button } from '@tq/ui-components';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { BsChevronLeft } from 'react-icons/bs';
import { HiDownload } from 'react-icons/hi';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { ClientsDto2 } from '../../api/LRPProxy';
import { getUserUuid } from '../../utils/gaUtils';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import useToast from '../../components/toast/useToast';
import Box from '../../components/ui/Box';
import { Container } from '../../components/ui/Container';
import Flex from '../../components/ui/Flex';
import Grid from '../../components/ui/Grid';
import Heading from '../../components/ui/Heading';
import Text from '../../components/ui/Text';
import { useTagManager, TimeOnPageCounter } from '../../components/analytics';
import { getProjectOverviewDownloadDocs, getProjectOverviewPageview } from '../../components/analytics/constants';
import { getProject404ToastOptions } from '../../hooks/queries/useGetProject';
import { useGetProjectOverview } from '../../hooks/queries/useGetProjectOverview';
import useDownloadProjectDocuments from '../../hooks/useDownloadProjectDocuments';
import useIsMobile from '../../hooks/useIsMobile';
import { useProjectClientLogoSrc } from '../../hooks/useProjectClientLogoSrc';
import { dateFormatter, daysBetween, formatNumberOfDays } from '../../utils/DateFormatterUtils';
import { isValidGuid } from '../../utils/validGuid';
import FreeholdPartiesCard from './FreeholdPartiesCard';
import LandParcelsCard from './LandParcelsCard';
import ProjectInformationCard from './ProjectInformationCard';
import ProjectMapCard from './ProjectMapCard';
import { Body, Header } from './ProjectOverview.styles';
import SupportingEvidenceCard from './SupportingEvidenceCard';
import TimelineCard from './TimelineCard';

export type Project = {
  id: string;
  name?: string | undefined;
  status?: string | undefined;
  client?: ClientsDto2;
  webMapAPIKey?: string | undefined;
  lastRefreshedDate?: Date;
};

const ProjectOverview = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { download, isDownloading } = useDownloadProjectDocuments({ projectId });
  const clientLogo = useProjectClientLogoSrc({ projectId });
  const { data, status } = useGetProjectOverview({ projectId });
  const isMobile = useIsMobile();
  const toast = useToast();
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();

  if (!isValidGuid(projectId)) {
    toast(getProject404ToastOptions());
    return <Redirect to='/' />;
  }

  if (!data) {
    if (status === 'loading') {
      return (
        <Flex alignItems='center' justifyContent='center' size='100%'>
          <LoadingSpinner />
        </Flex>
      );
    }
  }

  return (
    <Flex bg={['background.main', null, 'background.sub']} flexDirection='column' flex='1 1 auto' overflowY='auto'>
      <Header>
        <Container>
          <TimeOnPageCounter
            gaEvent={getProjectOverviewPageview}
            pageReference='ProOverview'
            projectName={data?.result.project?.name}
          />
          <Flex alignItems='center'>
            <Flex alignItems='center'>
              <Text fontSize={['1.5rem']}>
                <Link
                  to='/'
                  title='Go to My Projects'
                  aria-label='Go to My Projects'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <BsChevronLeft style={{ marginRight: '5px' }} />
                  <b>My Projects</b>&nbsp;/&nbsp;{data?.result.project?.name}
                </Link>
              </Text>
            </Flex>

            {!isMobile && (
              <Button
                disabled={isDownloading}
                ml='auto'
                title='Download project documents'
                aria-label='Download project documents'
                onClick={() => {
                  sendCustomEvent(getProjectOverviewDownloadDocs(getUserUuid(user), data?.result.project));
                  download();
                }}
                variant='secondary'
                sx={{
                  _focus: { border: '1px solid #484848' },
                  backgroundColor: 'rgb(228,238,245)',
                  border: '1px solid rgb(197,219,234)',
                  fontSize: '1.5rem',
                  whiteSpace: 'noWrap',
                  width: 'auto',
                  padding: '0.5rem 1rem',
                  borderRadius: '0.5rem',
                  color: 'rgb(6, 32, 61)',
                }}
                rightIcon={
                  isDownloading ? (
                    <Spinner animation='border' role='status' variant='primary'></Spinner>
                  ) : (
                    <HiDownload size='20px' className='ml-3' />
                  )
                }
              >
                Project Documents
              </Button>
            )}
          </Flex>
        </Container>
      </Header>
      <Body>
        <Container>
          <Box mb={[3, null, 0]}>
            <Flex py={[0, null, 5]} alignItems='center' flexDirection={['column', null, 'row']}>
              <Flex alignItems='center' width={['100%', null, '50%']}>
                <Flex
                  sx={{
                    display: 'flex',
                    flex: ['0 0 9rem', '0 0 13rem'],
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '.8rem',
                    bg: '#192d57',
                    color: 'white',
                    backgroundImage: clientLogo ? `url('${clientLogo}')` : '',
                    backgroundSize: 'contain',
                    backgroundPosition: '50% 50%',
                    backgroundRepeat: 'no-repeat',
                  }}
                  width={['9rem', '13rem']}
                  height={['9rem', '8rem']}
                  // ratio={129 / 78}
                >
                  {!clientLogo && (
                    <Text color='white' fontSize='32px'>
                      {data?.result.project?.client?.name?.slice(0, 2).toUpperCase()}
                    </Text>
                  )}
                </Flex>
                <Box sx={{ pt: 1, px: 3, mr: 'auto' }}>
                  <Flex sx={{ alignItems: 'center' }}>
                    <Box size='1.1rem' sx={{ borderRadius: '50%', bg: '#4c723e' }} />
                    <Text fontWeight='600' ml={1} sx={{ fontSize: '1.6rem' }}>
                      {data?.result.project?.status}
                    </Text>
                  </Flex>
                  <Box>
                    <Heading
                      as='h1'
                      sx={{
                        color: '#192d57',
                        mb: '.1rem',
                        fontSize: ['20px', '31px'],
                        textOverflow: 'ellipsis',
                        whiteSpace: 'noWrap',
                        overflow: 'hidden',
                      }}
                      title={data?.result.project?.name}
                    >
                      {data?.result.project?.name}
                    </Heading>
                    <Text fontSize={['12px', '13px']}>{data?.result.project?.client?.name}</Text>
                  </Box>
                </Box>
              </Flex>

              <Flex
                sx={{
                  width: ['100%', null, '50%'],
                  ml: [null, null, 'auto'],
                  py: [2, null, 3],
                  px: [2, null, 4],
                  mt: [3, null, 0],
                  bg: 'white',
                  border: 'solid 1px #dee9f2',
                  justifyContent: 'space-between',
                  borderRadius: '.8rem',
                }}
              >
                <Box sx={{ flex: '0 0 auto', mr: [1, null, 2], textAlign: 'right' }}>
                  <Text sx={{ color: '#454545', fontSize: ['11px', '13px'] }}>Project created</Text>
                  <Text sx={{ color: '#0c203d', fontSize: ['14px', '21px'] }}>
                    {dateFormatter(data?.result.project?.startDate)}
                  </Text>
                </Box>
                <Box sx={{ flex: '0 0 auto', mx: [1, null, 2], textAlign: 'right' }}>
                  <Text sx={{ color: '#454545', fontSize: ['11px', '13px'] }}>Duration</Text>
                  <Text sx={{ color: '#0c203d', fontSize: ['14px', '21px'] }}>
                    {formatNumberOfDays(daysBetween(data?.result.project?.startDate, Date.now()))}
                  </Text>
                </Box>
                <Box sx={{ flex: '0 0 auto', ml: [1, null, 2], textAlign: 'right' }}>
                  <Text sx={{ color: '#454545', fontSize: ['11px', '13px'] }}>Last updated</Text>
                  <Text sx={{ color: '#0c203d', fontSize: ['14px', '21px'] }}>
                    {dateFormatter(data?.result.project?.lastRefreshedDate)}
                  </Text>
                </Box>
              </Flex>
              {isMobile && (
                <Button
                  sx={{
                    _focus: { border: '1px solid #484848' },
                    backgroundColor: 'rgb(228,238,245)',
                    border: '1px solid rgb(197,219,234)',
                    fontSize: '1.5rem',
                    whiteSpace: 'noWrap',
                    marginTop: '1rem',
                    padding: '1rem 1rem',
                    borderRadius: '0.5rem',
                    color: 'rgb(6, 32, 61)',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  disabled={isDownloading}
                  mt={3}
                  isFullWidth
                  title='Download project documents'
                  aria-label='Download project documents'
                  variant='secondary'
                  onClick={() => {
                    sendCustomEvent(getProjectOverviewDownloadDocs(getUserUuid(user), data?.result.project));
                    download();
                  }}
                  rightIcon={
                    isDownloading ? (
                      <Spinner animation='border' role='status' variant='primary'></Spinner>
                    ) : (
                      <HiDownload size='20px' style={{ marginTop: '-.5rem' }} />
                    )
                  }
                >
                  Project Documents
                </Button>
              )}
            </Flex>
          </Box>

          <Grid
            sx={{
              gridTemplateColumns: 'repeat(6, 1fr)',
              columnGap: '3.2rem',
              rowGap: '2rem',
            }}
          >
            <ProjectMapCard />
            <ProjectInformationCard />
            <TimelineCard
              startDate={data?.result.project?.startDate}
              modifiedDate={data?.result.project?.lastRefreshedDate}
            />
            <LandParcelsCard landParcels={data?.result.landParcels?.list} count={data?.result.landParcels?.count} />
            <FreeholdPartiesCard
              parties={data?.result.landParcels?.parties?.list}
              count={data?.result.landParcels?.parties?.count}
            />
            <SupportingEvidenceCard
              supportingEvidence={data?.result.landParcels?.supportingEvidence?.list}
              count={data?.result.landParcels?.supportingEvidence?.count}
            />
          </Grid>
        </Container>
      </Body>
    </Flex>
  );
};

export default ProjectOverview;
