import React from 'react';
import { Switch } from 'react-router-dom';
import { projectPaths } from '../utils/router';
import { Login } from '../routes/custom/Login';
import AuthenticatedRoute from '../utils/AuthenticatedRoute';
import HelpLayout from './help/HelpLayout';
import NotFound from './NotFound';
import ProjectsList from './ProjectsList';
import ProjectOverview from './project/ProjectOverview';
import { LandParcelDetailWrapper } from './project/land-parcel-detail/components/LandParcelDetailWrapper';

const Routes = () => {
  return (
    <Switch>
      <AuthenticatedRoute exact path='/' component={ProjectsList} />
      <AuthenticatedRoute path='/help' component={HelpLayout} />
      {/* Leaving out this page temporarily until it is fully completed */}
      <AuthenticatedRoute exact path={projectPaths.overview} component={ProjectOverview} />

      <AuthenticatedRoute
        exact
        path={[projectPaths.mapRoot, projectPaths.party, projectPaths.landParcel, projectPaths.supportingEvidence]}
        component={LandParcelDetailWrapper}
      />
      <AuthenticatedRoute path='/' component={NotFound} />
      <AuthenticatedRoute exact path='/login' component={Login} />
    </Switch>
  );
};

export default Routes;
