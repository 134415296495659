import React, { FC, MutableRefObject, useRef, useState } from 'react';
import { BsCaretRightFill } from 'react-icons/bs';
import { FiChevronLeft, FiX } from 'react-icons/fi';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { SearchContainer as SearchInput } from '../../../containers/SearchContainer';
import { clearSearch, setSearchTerm } from '../../../store/search/actions';
import {
  ClickableGoBackHeader,
  CloseButton,
  Container,
  Content,
  GoBackHeaderContent,
  ToggleButton,
  PreviousRouteLabel,
  SearchHeader,
} from './InformationPanel.styles';
import Box from '../../../components/ui/Box';
import { CSSTransition } from 'react-transition-group';
import { getProjectMapUrl } from '../../../utils/router';
import { useProjectMapDetailContext } from '../context/project-map-detail-context';
import { HistoryLocationState } from '../../../components/search/SearchAccordion';

const ProjectMapInformationPanel: FC = ({ children }) => {
  const goBackHeaderRef = useRef<HTMLDivElement | null>(null) as MutableRefObject<HTMLDivElement | null>;
  const [headerSentinelRef, inView] = useInView({ initialInView: true });
  const history = useHistory<HistoryLocationState>();
  const { isInfoPanelOpen, projectLandParcelParams, toggleInfoPanel, projectDetail } = useProjectMapDetailContext();
  const { projectId } = projectLandParcelParams;

  const [hidden, setHidden] = useState(!isInfoPanelOpen);

  const dispatch = useDispatch();

  const clearSearchTerm = () => {
    dispatch(clearSearch());
    dispatch(setSearchTerm(''));
  };

  const previousRouteLabel = history.location.state?.previousRoute?.label;

  return (
    <CSSTransition
      in={isInfoPanelOpen}
      onExited={() => setHidden(true)}
      onEnter={() => setHidden(false)}
      timeout={150}
      classNames='information-panel-animate'
    >
      <Container $isOpen={isInfoPanelOpen} data-testid='info-panel'>
        <Box sx={{ position: 'relative' }}>
          {previousRouteLabel ? (
            <ClickableGoBackHeader
              data-testid='information-panel-go-back-header'
              ref={goBackHeaderRef}
              aria-label={`Go back to ${history.location.state?.previousRoute?.label}`}
              title={`Go back to ${history.location.state?.previousRoute?.label}`}
              $shouldHaveShadow={inView}
              hidden={hidden}
              onKeyPress={(e) => {
                e.stopPropagation();
                if (e.target === goBackHeaderRef.current && (e.key === ' ' || e.key === 'Enter')) history.goBack();
              }}
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <GoBackHeaderContent>
                <span>
                  <b>
                    <FiChevronLeft size='28px' />
                    Back&nbsp;
                  </b>
                  <PreviousRouteLabel>- {history.location.state?.previousRoute?.label}</PreviousRouteLabel>
                </span>
                <CloseButton
                  aria-label='Close information panel'
                  title='Close information panel'
                  onClick={(e) => {
                    e.stopPropagation(); // Else it will also trigger the GoBackHeader click handler
                    clearSearchTerm();
                    history.push(getProjectMapUrl(projectId));
                  }}
                >
                  <FiX size='28px' />
                </CloseButton>
              </GoBackHeaderContent>
            </ClickableGoBackHeader>
          ) : (
            <SearchHeader hidden={hidden} data-testid='information-panel-search-header' $shouldHaveShadow={inView}>
              <SearchInput projectDetail={projectDetail} />
            </SearchHeader>
          )}
          <ToggleButton
            onClick={toggleInfoPanel}
            // using aria-expanded attribute to style the toggle button
            // aria-expanded takes boolean value, depending on the value toggle button styles
            aria-expanded={isInfoPanelOpen}
          >
            <BsCaretRightFill
              aria-label='Toggle information panel'
              size='22px'
              style={{ transform: isInfoPanelOpen ? 'rotate(180deg)' : undefined }}
            />
          </ToggleButton>
        </Box>
        <Content id='information-panel-content' $shouldHavePaddingTop={!!previousRouteLabel} hidden={hidden}>
          <span ref={headerSentinelRef}></span>
          {children}
        </Content>
      </Container>
    </CSSTransition>
  );
};

export default ProjectMapInformationPanel;
