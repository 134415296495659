import React from 'react';
import styled from 'styled-components';

import { Container as BsContainer } from 'react-bootstrap';
export const Container = styled(BsContainer)`
  max-width: 1175px;
`;

export const OptionSuffixIcon = styled(({ item, ...rest }) => <img alt={`${item.label} - ${item.order}`} {...rest} />)`
  margin: 0;
  padding: 0;
`;

export const Title = styled.div`
  font-size: 3.4rem;
  font-weight: bold;
`;

export const MainWrapper = styled.div`
  padding: 6.3rem 0;
  overflow-y: auto;
  @media screen and (max-height: 768px) {
    overflow-y: unset;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
`;

export const Select = styled.div`
  margin-left: auto;
`;

export const SelectTrigger = styled.button`
  display: inline-flex;
  font-size: 1.5rem;
  color: #11203d;
  padding: 1.1rem 1.6rem;
  font-weight: 600;
  line-height: 1;
  border-radius: 4px;
  box-shadow: 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.16);
  border: solid 0.1rem #11203d;
  background-color: #e4eef5;
  text-align: left;
  min-width: 20rem;
`;

export const HeaderWrapper = styled.div<{ $hasScrolled: boolean }>`
  padding: 4.4rem 0 2rem 0;
  border: 0.1rem solid #e5edf3;

  top: 0;
  z-index: 4;
  background-color: white;
  transition: box-shadow ease-in-out 0.15s;

  @media screen and (min-height: 768px) {
    position: sticky;
  }

  ${({ $hasScrolled }) => ($hasScrolled ? 'box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.16);' : '')}
`;

export const OptionList = styled.ul<{ $isOpen: boolean }>`
  outline: none;

  ${({ $isOpen }) =>
    $isOpen
      ? `
      box-shadow: 0 0.1rem 0.4rem 0 rgba(44, 64, 90, 0.21);
      border: solid 0.1rem rgba(183, 210, 229, 0.8);
  `
      : ``}
  min-width: 20rem;
`;

export const OptionContent = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0.9rem 1.6rem;
  color: #454545;
  font-size: 1.5rem;
`;

export const OptionSuffix = styled.span`
  font-size: 1.4rem;
  border-left: solid 0.1rem rgba(183, 210, 229, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px;
  bottom: 0;
  color: #192d57;
  padding: 0 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f4;
  font-weight: 600;

  > span {
    margin-right: -6px;
  }
`;
export const Option = styled.li<{ $isHighlighted: boolean }>`
  background-color: #fff;
  cursor: pointer;
  display: flex;
  position: relative;

  :not(:last-child) {
    border-bottom: solid 0.1rem rgba(183, 210, 229, 0.8);
  }

  &:hover {
    background-color: #f3f4f4;

    ${OptionContent},
    ${OptionSuffix} {
      color: #3b78d4;

      // Why is this not working
      ${OptionSuffixIcon} {
        color: #3b78d4 !important;
        stroke: #3b78d4 !important;
        fill: #3b78d4 !important;
      }
    }
  }
  ${({ $isHighlighted }) =>
    $isHighlighted
      ? `
      background-color: #f3f4f4;

      ${OptionContent},
      ${OptionSuffix} {
        color: #3b78d4;

        // Why is this not working
        ${OptionSuffixIcon} {
          color: #3b78d4 !important;
          stroke: #3b78d4 !important;
          fill: #3b78d4 !important;
        }
      }
  `
      : ''}
`;

export const Wrapper = styled.div`
  flex-grow: 1;
  background-color: #fafbfc;
  @media screen and (max-height: 768px) {
    overflow-y: auto;
  }

  @media screen and (max-width: 768px) {
    ${HeaderWrapper} {
      padding: 2rem 0;

      ${HeaderContent} {
        flex-direction: column;

        ${Select} {
          margin-left: 0;

          ${SelectTrigger} {
            min-width: 16.8rem;
          }
        }

        ${Title} {
          font-size: 2rem;
          margin-bottom: 2rem;
        }
      }
    }

    ${MainWrapper} {
      padding: 2.2rem 0;
    }
  }
`;
