import React from 'react';

import { Login as LoginView } from '@tq/auth-components';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';

export const Login: React.FC<{ children?: never }> = () => {
  return (
    <LoginView
      clientId={process.env.REACT_APP_B2C_CLIENT_ID!}
      loadingErrorView={() => (
        <>
          <LoadingSpinner />
        </>
      )}
      scope={process.env.REACT_APP_B2C_SCOPE!}
    />
  );
};
