import React, { FC, useMemo } from 'react';
import {
  AiFillCloseCircle as DangerIcon,
  AiFillInfoCircle as InfoIcon,
  AiFillWarning as WarningIcon,
} from 'react-icons/ai';
import { IoMdCheckmarkCircle as SuccessIcon } from 'react-icons/io';
import { RiCloseLine } from 'react-icons/ri';
import Box from '../ui/Box';
import Text from '../ui/Text';
import { CloseButton, Container, Title } from './Alert.styles';

export type Status = 'success' | 'error' | 'warning' | 'info';

type Props = {
  /**
   * Id of the toast
   */
  id?: string;
  /**
  /**
   * Controls whether the assistive technology should read immediately
   * ("assertive") or wait until the user is idle ("polite").
   */
  type?: 'assertive' | 'polite';
  /**
   * Title of the toast
   */
  title?: React.ReactNode;
  /**
   * Description of the toast
   */
  description: React.ReactNode;
  /**
   * If `true`, toast will show a close button
   */
  isClosable?: boolean;
  /**
   * Callback function to run when the close button is clicked
   */
  onClose?(): void;
  /**
   * The status of the toast.
   */
  status?: Status;
};

export const Alert: FC<Props> = (props) => {
  const { id, status = 'danger', type = 'assertive', title, isClosable, onClose, description } = props;

  const { colour, Icon } = useMemo(() => {
    let colour;
    switch (status) {
      default:
      case `danger`:
        colour = '#8e443e';
        return { colour, Icon: DangerIcon };
      case `success`:
        colour = '#4c723e';
        return { colour, Icon: SuccessIcon };
      case `warning`:
        colour = '#b14e0c';
        return { colour, Icon: WarningIcon };
      case `info`:
        colour = '#346480';
        return { colour, Icon: InfoIcon };
    }
  }, [status]);

  return (
    <Container role='alert' aria-live={type} id={id} data-testid={id} $status={status} aria-label='alert'>
      <Icon fill={colour} size='24px' className='mr-4' />
      <Box flex='1'>
        {title && <Title color={colour}>{title}</Title>}
        {description && <Text color={colour}>{description}</Text>}
      </Box>
      {isClosable && (
        <CloseButton aria-label='Close alert' title='Close alert' onClick={onClose}>
          <RiCloseLine fill={colour} size='24px' />
        </CloseButton>
      )}
    </Container>
  );
};

export default Alert;
