import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { client as api } from '../../api';
import { DocumentsDto, SwaggerResponse } from '../../api/LRPProxy';

type TResult = SwaggerResponse<DocumentsDto[]>;
type Variables = {
  pageNumber?: number | undefined;
  pageSize?: number | undefined;
  orderBy?: string | null | undefined;
  fields?: string | null | undefined;
  projectId: string;
};
type Options = UseQueryOptions<TResult, unknown>;

const getProjectDocumentsQueryOptions = (
  { pageNumber, pageSize, orderBy, fields, projectId }: Variables,
  options?: Options
): Options => ({
  queryKey: ['documents', { pageNumber, pageSize, orderBy, fields, projectId }],
  queryFn: () => api.getDocuments(pageNumber, pageSize, orderBy, fields, projectId),
  ...options,
});

export const useGetProjectDocuments = (variables: Variables, options?: Options): UseQueryResult<TResult, unknown> =>
  useQuery(getProjectDocumentsQueryOptions(variables, options));
