import { IProjectsDto, ProjectsDto } from '../../api/LRPProxy';
import RequestStatus from '../RequestStatus';

export const GET_PROJECTS_REQUESTED = 'GET_PROJECTS_REQUESTED';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED';

export type projectsState = {
  projects?: IProjectsDto[];
  getProjectsStatus?: RequestStatus;
  errorMessage?: string;
};

type GetProjectsRequestedAction = {
  type: typeof GET_PROJECTS_REQUESTED;
};

type GetProjectsRequestSuccessAction = {
  type: typeof GET_PROJECTS_SUCCESS;
  payload: ProjectsDto[];
};

type GetProjectsRequestFailedAction = {
  type: typeof GET_PROJECTS_FAILED;
  payload: string;
};

export type ProjectsActionTypes =
  | GetProjectsRequestedAction
  | GetProjectsRequestSuccessAction
  | GetProjectsRequestFailedAction;
