import React, { createContext, FC, useCallback, useState } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import Toast from './Toast';
import { CreateToastOptions, ToastContextObject, ToastsState } from './Toast.types';

export const ToastContext = createContext<ToastContextObject>({});

let counter = 0;

const Ul = styled.ul`
  position: absolute;
  z-index: 99;
  pointer-events: none;
  top: 2.2rem;
  left: 0;
  right: 0;
  padding: 0 1.6rem;

  li:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

/**
 * Manages and provides toast state
 */
const ToastProvider: FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastsState>([]);

  /**
   * Remove a toast from the list state
   */
  const removeToast = useCallback((id: string) => {
    setToasts((prevState) => prevState.filter((toast) => toast.id !== id));
  }, []);

  /**
   * Create a new toast and add to the beginning of the list state
   */
  const notify = useCallback(
    (options: CreateToastOptions) => {
      counter += 1;
      const id = options.id ?? String(counter);

      const toast = {
        id,
        message: options.message,
        duration: options.duration,
        onCloseComplete: options.onCloseComplete,
        onRequestRemove: () => removeToast(String(id)),
        status: options.status,
        requestClose: false,
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setToasts((prevToasts) => [toast, ...prevToasts]);

      return id;
    },
    [removeToast]
  );

  /**
   * Requests to close a toast by it's `id`
   */
  const closeToast = useCallback((id: string) => {
    setToasts((prevToasts) => {
      return prevToasts.map((toast) => {
        if (toast.id === id) {
          return {
            ...toast,
            requestClose: true,
          };
        }
        return toast;
      });
    });
  }, []);

  /**
   * Close all toasts at once.
   */
  const closeAll = useCallback(() => {
    setToasts((prev) =>
      prev.map((toast) => ({
        ...toast,
        requestClose: true,
      }))
    );
  }, []);

  /**
   * Update a specific toast with new options by it's `id`
   */
  const updateToast = useCallback((id: string, options: CreateToastOptions) => {
    setToasts((prevToasts) => {
      const nextState = { ...prevToasts };
      const index = nextState.findIndex((toast) => toast.id === id);

      if (index !== -1) {
        nextState[index] = {
          ...nextState[index],
          ...options,
        };
      }

      return nextState;
    });
  }, []);

  const isVisible = useCallback(
    (id: string) => {
      return toasts.some((t) => id === t.id);
    },
    [toasts]
  );

  return (
    <ToastContext.Provider value={{ notify, isVisible, closeToast, closeAll, updateToast }}>
      <Ul id='toast-manager'>
        <TransitionGroup component={null}>
          {toasts.map((toast) => (
            <Transition key={toast.id} timeout={150} onExited={() => toast.onCloseComplete?.()}>
              {(state) => <Toast transitionStatus={state} {...toast} />}
            </Transition>
          ))}
        </TransitionGroup>
      </Ul>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
