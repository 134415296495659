import { ProjectDto } from '../../../api/LRPProxy';
import { SendCustomEventProps } from '../TagManagerProvider';

const GA_PROJECT_MAP_PANEL = {
  gaCategory: 'Project Map Page',
  gaLabel: 'Project Map Page',
};

const GA_PROJECT_MAP_PANEL_SELECT_PARCEL_FROM_SEARCH: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PANEL,
  gaAction: 'SearchProMapPanel - Select Parcel',
};

const GA_PROJECT_MAP_PANEL_SELECT_PARTY_FROM_SEARCH: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PANEL,
  gaAction: 'SearchProMapPanel - Select Party',
};

const GA_PROJECT_MAP_PANEL_SELECT_DOCUMENT_FROM_SEARCH: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PANEL,
  gaAction: 'SearchProMapPanel - Select Supporting Document',
};

const GA_PROJECT_MAP_PANEL_PARTY_INFO: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PANEL,
  gaAction: 'ProMapPanel - Party Info',
};

const GA_PROJECT_MAP_PANEL_PARTY_DETAILS: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PANEL,
  gaAction: 'ProMapPanel - Party Details',
};

const GA_PROJECT_MAP_PANEL_SUPPORTING_EVIDENCE: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PANEL,
  gaAction: 'ProMapPanel - Party Docs',
};

const GA_PROJECT_MAP_PANEL_SUPPORTING_EVIDENCE_DOWNLOAD: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PANEL,
  gaAction: 'ProMapPanel - Party Docs Download',
};

const GA_PROJECT_MAP_PANEL_LAND_PARCEL_INFO: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PANEL,
  gaAction: 'ProMapPanel - Parcel Info',
};

const GA_PROJECT_MAP_PANEL_LAND_PARCEL_LOCATE: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PANEL,
  gaAction: 'ProMapPanel - Parcel Locate',
};

const GA_PROJECT_MAP_PANEL_LAND_PARCEL_DETAILS: SendCustomEventProps = {
  ...GA_PROJECT_MAP_PANEL,
  gaAction: 'ProMapPanel - Parcel Details',
};

export const getMapPanelSelectParcelFromSearchEvent = (
  userId: string,
  project: ProjectDto,
  parcelId?: string
): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PANEL_SELECT_PARCEL_FROM_SEARCH,
    gaUserId: userId,
    gaProjectName: project.name,
    gaParcelId: parcelId,
  };
};

export const getMapPanelSelectPartyFromSearchEvent = (
  userId: string,
  project: ProjectDto,
  partyId?: string
): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PANEL_SELECT_PARTY_FROM_SEARCH,
    gaUserId: userId,
    gaProjectName: project.name,
    gaPartyId: partyId,
  };
};

export const getMapPanelSelectDocumentFromSearchEvent = (
  userId: string,
  project: ProjectDto,
  supportingDocumentId?: string
): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PANEL_SELECT_DOCUMENT_FROM_SEARCH,
    gaUserId: userId,
    gaProjectName: project.name,
    gaSupportingDocumentId: supportingDocumentId,
  };
};

export const getMapPanelPartyInfoEvent = (
  userId: string,
  partyId?: string,
  project?: ProjectDto
): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PANEL_PARTY_INFO,
    gaUserId: userId,
    gaProjectName: project?.name,
    gaPartyId: partyId,
  };
};

export const getMapPanelPartyDetailsEvent = (
  userId: string,
  project: ProjectDto,
  partyId?: string
): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PANEL_PARTY_DETAILS,
    gaUserId: userId,
    gaProjectName: project.name,
    gaPartyId: partyId,
  };
};

// This event fires when the user clicks through to a 'Supporting Evidence' linked to a Party, and includes the ID of that party.
export const getMapPanelSupportingEvidenceEvent = (
  userId: string,
  project?: ProjectDto,
  partyId?: string
): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PANEL_SUPPORTING_EVIDENCE,
    gaUserId: userId,
    gaProjectName: project?.name,
    gaPartyId: partyId,
  };
};

export const getMapPanelSupportingEvidenceDownloadEvent = (
  userId: string,
  project: ProjectDto,
  supportingDocumentId?: string
): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PANEL_SUPPORTING_EVIDENCE_DOWNLOAD,
    gaUserId: userId,
    gaProjectName: project.name,
    gaSupportingDocumentId: supportingDocumentId,
  };
};

export const getMapPanelParcelInfoEvent = (
  userId: string,
  parcelId?: string,
  project?: ProjectDto
): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PANEL_LAND_PARCEL_INFO,
    gaUserId: userId,
    gaProjectName: project?.name,
    gaParcelId: parcelId,
  };
};

export const getMapPanelParcelLocateEvent = (
  userId: string,
  projectName?: string,
  parcelId?: string
): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PANEL_LAND_PARCEL_LOCATE,
    gaUserId: userId,
    gaProjectName: projectName,
    gaParcelId: parcelId,
  };
};

export const getMapPanelParcelDetailsEvent = (
  userId: string,
  parcelId?: string,
  projectName?: string
): SendCustomEventProps => {
  return {
    ...GA_PROJECT_MAP_PANEL_LAND_PARCEL_DETAILS,
    gaUserId: userId,
    gaProjectName: projectName,
    gaParcelId: parcelId,
  };
};
