import { Box } from '@tq/ui-components';
import React from 'react';
import Text from '../ui/Text';

const ReadMore = ({ text, title, onClick }: { text: string | undefined; title: string; onClick: () => void }) => {
  return (
    <Text>
      {text && text?.length > 150 ? (
        <>
          {text?.slice(0, 150)}
          <Box
            as='button'
            onClick={onClick}
            title={title}
            aria-label={title}
            tabIndex={0}
            backgroundColor='transparent'
            color='blue'
            sx={{ backgroundColor: 'transparent', border: 0, color: 'rgb(43, 114, 202)' }}
          >
            ...view more
          </Box>
        </>
      ) : (
        text
      )}
    </Text>
  );
};

export default ReadMore;
