import { useDisclosure } from '@tq/ui-components/dist/hooks';
import { useParams } from 'react-router-dom';
import { GeoJsonFeatureCollectionDto } from '../../../../api/LRPProxy';
import { useGetProjectAtbr } from '../../../../hooks/queries/useGetProjectAtbr';
import { useGetProjectLandParcels } from '../../../../hooks/queries/useGetProjectLandParcels';

export type ProjectLandParcelParams = {
  projectId: string;
  landParcelId?: string;
  partyId?: string;
  supportingEvidenceId?: string;
};

export type UseLandParcelDetailLogicResult = {
  projectAtbrDetail: GeoJsonFeatureCollectionDto | undefined;
  projectLandParcelDetail: GeoJsonFeatureCollectionDto | undefined;
  isInfoPanelOpen: boolean;
  projectLandParcelParams: ProjectLandParcelParams;
  toggleInfoPanel: () => void;
  openInfoPanel: () => void;
  closeInfoPanel: () => void;
};

export function useLandParcelDetailLogic(): UseLandParcelDetailLogicResult {
  const projectLandParcelParams = useParams<ProjectLandParcelParams>();

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure({ defaultIsOpen: true });

  const { data: projectAtbrDetail } = useGetProjectAtbr({ projectId: projectLandParcelParams.projectId });
  const { data: projectLandParcelDetail } = useGetProjectLandParcels({ projectId: projectLandParcelParams.projectId });

  return {
    projectAtbrDetail: projectAtbrDetail?.result,
    projectLandParcelDetail: projectLandParcelDetail?.result,
    projectLandParcelParams,
    closeInfoPanel: onClose,
    isInfoPanelOpen: isOpen,
    openInfoPanel: onOpen,
    toggleInfoPanel: onToggle,
  };
}
