import { AppThunk } from '../store';
import { GET_PROJECTS_REQUESTED, GET_PROJECTS_FAILED } from './types';
import { getProjectsList } from './actions';

export function thunkGetProjects(): AppThunk {
  return async (dispatch, getState, api) => {
    dispatch({ type: GET_PROJECTS_REQUESTED });

    await api
      .getProjects(undefined, 100, 'name asc', undefined)
      .then((data) => {
        dispatch(getProjectsList(data.result));
      })
      .catch(() => {
        dispatch({ type: GET_PROJECTS_FAILED });
      });
  };
}
