import React, { useContext, useRef } from 'react';
import { FaArrowLeft, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import projectIcon from '../../assets/icon_Projects.svg';
import { DropdownContainer } from '../../containers/DropdownContainer';
import { NavContext } from '../../providers/NavProvider';
import styles from './Navigation.module.scss';

const Navigation = () => {
  const { isOpen, openSideNav, closeSideNav } = useContext(NavContext);
  const sideNav = useRef<HTMLDivElement>(null);

  return (
    <nav
      aria-label='app-sidebar-navigation'
      className={`${styles.sideNav} ${isOpen ? '' : styles.fold}`}
      data-testid='sideNav'
      ref={sideNav}
    >
      <div className='d-flex flex-column  h-100'>
        <div className={`d-lg-flex d-none ${styles.burgerHolder}`}>
          <button
            className={`${styles.sideNav__burger} ${isOpen ? 'd-none' : ''} `}
            onClick={openSideNav}
            title='Navigation menu'
            name='Navigation menu'
            aria-label='Navigation menu'
            data-testid='navigation-menu-toggle'
          >
            <FaBars size='15px' className='burgerButton' />
          </button>

          <button
            className={`${styles.sideNav__arrowButton} w-100 ${!isOpen ? 'd-none' : ''} `}
            onClick={closeSideNav}
            title='Close navigation menu'
            name='Close navigation menu'
            aria-label='Close navigation menu'
          >
            <FaArrowLeft className='closeButton' />
          </button>
        </div>

        <div className='d-flex flex-column flex-grow-1'>
          <ul className={`${styles.sideNav__menu}`}>
            <li className={`${styles.sideNav__item}`}>
              <Link to='/' className={`${styles.sideNav__link}`} title='My Projects' tabIndex={-1}>
                <img src={projectIcon} alt='Project icon' />
                My Projects
              </Link>
            </li>
          </ul>

          <div hidden={!isOpen} className={`flex-grow-1 ${styles.dropdownHolder}`}>
            <DropdownContainer />
          </div>

          <ul className={`mt-auto ${styles.sideNav__menu}`} onClick={closeSideNav}>
            {/* <li className={`${styles.sideNav__item} ${styles.settings}`} tabIndex={-1}>
              <Link to='#' className={styles.sideNav__link} title='Settings' tabIndex={-1}>
                <button name='Open Settings' aria-label='Settings'>
                  <IoIosSettings size='15px' />
                </button>
                Settings
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
