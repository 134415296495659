import { useQuery, UseQueryOptions } from 'react-query';
import { client as api } from '../../api';
import { GeoJsonFeatureCollectionDto, SwaggerResponse } from '../../api/LRPProxy';

type TResult = SwaggerResponse<GeoJsonFeatureCollectionDto>;
type Variables = { projectId: string };
type Options = UseQueryOptions<TResult, unknown>;

const getProjectAtbrQueryOptions = ({ projectId }: Variables, options: Options = {}): Options => ({
  queryKey: ['projectAtbr', { projectId }],
  queryFn: () => api.atbr(projectId),
  ...options,
});

export const useGetProjectAtbr = (variables: Variables, options?: Options) =>
  useQuery<TResult, unknown>(getProjectAtbrQueryOptions(variables, options));
