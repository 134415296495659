import {
  SearchState,
  SET_SEARCH_REQUESTED,
  SET_SEARCH_SUCCESS,
  SET_SEARCH_FAILED,
  CLEAR_SEARCH,
  SET_SEARCH_TERM,
  SearchActionTypes,
} from './types';
import RequestStatus from '../RequestStatus';

export const initialState: SearchState = {
  setSearchStatus: RequestStatus.Initial,
};

const searchReducer = (state = initialState, action: SearchActionTypes) => {
  switch (action.type) {
    case SET_SEARCH_REQUESTED:
      return { ...state, results: null, setSearchStatus: RequestStatus.Requested };
    case SET_SEARCH_SUCCESS:
      return { ...state, results: action.payload, setSearchStatus: RequestStatus.Success };
    case SET_SEARCH_FAILED:
      return { ...state, searchErrorMessage: action.payload, setSearchStatus: RequestStatus.Failed };
    case CLEAR_SEARCH:
      return { ...state, results: null, setSearchStatus: RequestStatus.Initial };
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload };

    default:
      return state;
  }
};

export default searchReducer;
