import React, { forwardRef, ForwardRefExoticComponent } from 'react';
import Box from './Box';
import { ImageProps } from './types';

const Image: ForwardRefExoticComponent<ImageProps> = forwardRef((props, ref) => (
  <Box
    ref={ref}
    as='img'
    {...props}
    sx={{
      maxWidth: '100%',
      height: 'auto',
    }}
  />
));

Image.displayName = 'Image';

export default Image;
