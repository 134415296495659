import React, { ForwardRefExoticComponent } from 'react';
import styled from 'styled-components';
import { layout, size, space } from 'styled-system';
import Box from './Box';
import { BoxProps } from './types';

type Props = BoxProps;

const StyledContainer = styled(Box)<{ $isFullWidth: boolean }>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${layout};
  ${size}
  ${space}
`;

export const Container: ForwardRefExoticComponent<Props> = React.forwardRef((props, ref) => {
  return <StyledContainer ref={ref} {...props} tx='layout' />;
});

Container.displayName = 'Container';
Container.defaultProps = {
  variant: 'container',
};
