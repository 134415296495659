import React, { ComponentProps } from 'react';
import styles from './CardButton.module.scss';

type CardButton = ComponentProps<'button'> & {
  textColor?: string;
};

const CardBtn: React.FC<CardButton> = ({ textColor, ...rest }) => (
  <button className={`${styles.cardButton} ${textColor && styles[textColor]}`} {...rest} />
);

export default CardBtn;
