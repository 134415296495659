export function getAuthPaths(): {
  authority: string;
  signInPolicyPath: string;
  impersonatePolicyPath: string;
} {
  if (!process.env.REACT_APP_B2C_AUTHORITY) {
    // in tests, probably
    return {
      authority: 'authority',
      signInPolicyPath: 'signInPolicyPath',
      impersonatePolicyPath: '',
    };
  }

  return {
    authority: process.env.REACT_APP_B2C_AUTHORITY ?? '',
    signInPolicyPath: process.env.REACT_APP_B2C_POLICY_SIGN_IN ?? '',
    impersonatePolicyPath: '',
  };
}
