import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import documentsIcon from '../../assets/documents.svg';
import hectaresIcon from '../../assets/hectares.svg';
import landParcelIcon from '../../assets/land-parcel.svg';
import ReadMore from '../../components/readmore/ReadMore';
import Box from '../../components/ui/Box';
import { Button } from '../../components/ui/Button';
import Flex from '../../components/ui/Flex';
import Heading from '../../components/ui/Heading';
import Text from '../../components/ui/Text';
import { BoxProps } from '../../components/ui/types';
import { useGetProjectOverview } from '../../hooks/queries/useGetProjectOverview';
import { convertMetresSquaredToHectares } from '../../utils/areaConverter';
import { Dialog } from 'primereact/dialog';
import {
  Card,
  CardHeader,
  CardBody,
  StatContainer,
  StatIcon,
  StatIconContainer,
  StatLabel,
  StatTextContainer,
  StatValue,
} from './ProjectOverview.styles';
import themedCss from '@styled-system/css';
import useIsMobile from '../../hooks/useIsMobile';

export const StyledModal = styled(Dialog)`
  border: 0;
  box-shadow: 0 0 1em rgb(0 0 0 / 0.3);
  border-radius: 1.2rem;
  padding: 0;

  &::backdrop {
    background-color: #5f5f5f;
    opacity: 0.8;
  }
  ${themedCss({
    maxWidth: ['unset', '50vw'],
    maxHeight: ['unset', '50vh'],
  })}
  @media (max-width:769px) {
    height: 100%;
    width: 100%;
  }
`;

export const Stat = ({
  label,
  value,
  iconBg,
  iconSrc,
  ...rest
}: { label: string; value?: string | number; iconBg: string; iconSrc: string } & BoxProps) => {
  return (
    <StatContainer {...rest}>
      <StatTextContainer>
        <StatLabel>{label}</StatLabel>
        <StatValue>{value}</StatValue>
      </StatTextContainer>
      <StatIconContainer bg={iconBg}>
        <StatIcon src={iconSrc} alt={label} />
      </StatIconContainer>
    </StatContainer>
  );
};

const ProjectInformationCard = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data } = useGetProjectOverview({ projectId });
  const isMobile = useIsMobile();

  const [showModal, setShowModal] = useState<boolean>(false);
  const myIcon = (
    <Button style={{ backgroundColor: 'transparent' }}>
      <AiOutlineClose fontSize='2rem' />
    </Button>
  );

  const handleClick = () => {
    setShowModal(true);
  };
  const closeModalHandle = () => {
    setShowModal(false);
  };
  return (
    <Card sx={{ bg: 'background.sub', gridColumn: ['span 6', 'span 3'] }}>
      <CardHeader>
        <Heading fontWeight='600' color='white'>
          Project Information
        </Heading>
      </CardHeader>
      <CardBody sx={{ pt: [3, null, null, 6], pb: [3, null, null, 4], px: [2, null, null, 5] }}>
        <Flex>
          <Stat
            mr={['.75rem', null, 2]}
            label='Hectares'
            value={convertMetresSquaredToHectares(data?.result.landParcels?.area) ?? 0}
            iconBg='#3383c8'
            iconSrc={hectaresIcon}
          />

          <Stat
            mx={['.75rem', null, 2]}
            label='Land parcels'
            value={data?.result.landParcels?.count}
            iconBg='#48842f'
            iconSrc={landParcelIcon}
          />

          <Stat
            ml={['.75rem', null, 2]}
            label='Documents'
            value={data?.result.landParcels?.documents?.count}
            iconBg='#f5ca00'
            iconSrc={documentsIcon}
          />
        </Flex>

        <Box sx={{ mt: [3, null, 6], mb: [3, null, 4], bg: '#e5edf3', height: '1px' }} />

        <Box>
          <ReadMore text={data?.result.project?.description} title='Project description' onClick={handleClick} />

          <StyledModal
            id='view-more-modal'
            aria-label='view-more-modal'
            contentStyle={{ borderRadius: isMobile ? undefined : '1.2rem' }}
            showHeader={false}
            icons={myIcon}
            visible={showModal}
            onHide={() => setShowModal(false)}
            closeOnEscape
            dismissableMask
          >
            <Flex
              justifyContent='space-between'
              alignItems='center'
              paddingTop='1rem'
              sx={{ position: 'sticky', top: '0', backgroundColor: 'white' }}
            >
              <Heading as='h3' color='#0c203d' fontSize='1.6rem' fontWeight='600' paddingY={2}>
                Project Description
              </Heading>
              <Box
                as='button'
                onClick={closeModalHandle}
                style={{ backgroundColor: 'transparent' }}
                aria-label='close modal'
                title='close modal'
                aria-labelledby='close modal'
                role='button'
                sx={{ border: '0', _hover: { border: '1px solid black' } }}
              >
                <AiOutlineClose fontSize='2rem' />
              </Box>
            </Flex>
            <Box overflowY='auto'>
              <Text fontSize='1.5rem' lineHeight='1.67' color='#454545'>
                {data?.result.project?.description}
              </Text>
            </Box>
          </StyledModal>
        </Box>
      </CardBody>
    </Card>
  );
};

export default ProjectInformationCard;
