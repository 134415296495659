import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import { IoIosArrowForward } from 'react-icons/io';
import { IProjectsDto } from '../../api/LRPProxy';
import { NavContext } from '../../providers/NavProvider';
import { isIE11 } from '../../utils/browserUtils';
import { getUserUuid } from '../../utils/gaUtils';
import { useTagManager } from '../../components/analytics';
import { getSelectedProjectPanelEvent } from '../../components/analytics/constants';
import styles from './Navigation.module.scss';

type Props = {
  projects: IProjectsDto[] | undefined;
  onClick: () => void;
};
export const Dropdown: React.FC<Props> = ({ projects, onClick }) => {
  const projectDropdownRef = React.useRef<HTMLUListElement>(null);
  const { instance } = useMsal();
  const { sendCustomEvent } = useTagManager();
  const user = instance.getActiveAccount();
  const { isOpen, closeSideNav } = useContext(NavContext);

  const handleClick = (proj: IProjectsDto) => {
    sendCustomEvent(getSelectedProjectPanelEvent(getUserUuid(user), proj));
    closeSideNav();
    onClick();
  };

  useEffect(() => {
    const scrollUp = () => {
      if (!isOpen && !isIE11) {
        projectDropdownRef.current?.scrollTo(0, 0);
      }
    };
    scrollUp();
    return;
  }, [isOpen, projectDropdownRef]);

  const project = projects?.map((project, i) => {
    return (
      <li key={i} data-testid='project' className={styles.listItem}>
        <NavLink
          to={`/project/${project.id}`}
          className={styles.projectLink}
          onClick={() => handleClick(project)}
          activeStyle={{ backgroundColor: 'rgba(112, 112, 112, 0.07)', color: '#2378d4' }}
          data-testid='project-card-side'
        >
          {project.name} <IoIosArrowForward fontSize='22px' />
        </NavLink>
      </li>
    );
  });

  return (
    <ul
      className={`${styles.sideNav__dropdown}   ${isOpen ? styles.open : ''} `}
      tabIndex={-1}
      ref={projectDropdownRef}
    >
      {project}
    </ul>
  );
};
