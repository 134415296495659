import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { client as api } from '../../api';
import { PartiesDto, SwaggerResponse } from '../../api/LRPProxy';

type TResult = SwaggerResponse<PartiesDto[]>;
type Variables = {
  projectId: string;
  landParcelId: string;
  interestTypes?: string[] | null | undefined;
  pageNumber?: number | undefined;
  pageSize?: number | undefined;
  orderBy?: string | null | undefined;
  fields?: string | null | undefined;
};
type Options = UseInfiniteQueryOptions<TResult, unknown>;

const getLandParcelPartiesQueryOptions = ({ landParcelId, projectId }: Variables, options: Options = {}): Options => ({
  queryKey: ['landParcelParties', { landParcelId, projectId }],
  queryFn: ({ pageParam = 1 }) =>
    api.getLandParcelParties(
      landParcelId,
      pageParam,
      parseInt(process.env.REACT_APP_LRP_PAGE_SIZE || ''),
      'Interests ASC',
      undefined,
      projectId
    ),
  getNextPageParam: (lastPage) => {
    const { currentPage, totalPages } = JSON.parse(lastPage.headers['x-pagination']);

    if (currentPage < totalPages) {
      return currentPage + 1;
    }

    return undefined;
  },
  ...options,
});

export const useGetLandParcelParties = (variables: Variables, options?: Options) =>
  useInfiniteQuery<TResult, unknown>(getLandParcelPartiesQueryOptions(variables, options));
